import { ReactNode } from "react";

const mapErrors = (errors?: string[]) =>
  errors &&
  errors.map((error, i) => (
    <span key={i} style={{ color: "red" }}>
      {error}
    </span>
  ));

export const FormItem: React.FC<{
  children: ReactNode;
  errors?: string[];
  label: string;
}> = (Props) => {
  const { children, errors, label } = Props;
  return (
    <div className="flex flex-col w-full lg:w-1/2">
      <label className="text-[#42526E] text-sm font-semibold mb-2">
        {label}
      </label>
      {children}
      {mapErrors(errors)}
    </div>
  );
};
