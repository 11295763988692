import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vacanciesCount: 0,
  inCampaignCount: 0,
  openCount: 0,
};

export const clientCountInfo: any = createSlice({
  name: "clientInfo",
  initialState,
  reducers: {
    setClientInfo: (state: any, action: any) => {
      return action.payload;
    },
  },
});

export const { setClientInfo } = clientCountInfo.actions;

export default clientCountInfo.reducer;
