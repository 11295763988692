// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_dashboardHeader__WGDP1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: gray;
}

.dashboard_dashbaordContainer__urkFt {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 50px;
  row-gap: 50px;
  flex-wrap: wrap;
  margin: 50px 0;
}

.dashboard_searchInput__571lI {
  width: 220px;
  padding: 12px;
  border-radius: 14px;
  border: 1.5px solid gray;
}

.dashboard_dashboardSpinner__98Tu9 {
  margin: 50px auto;
}
.dashboard_centered__cZRIZ {
  position: absolute;
  top: 155px;
  left: 50%;
  transform: translate(-50%);
}
@media screen and (max-width: 650px) {
  .dashboard_centered__cZRIZ {
    top: 250px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/dashboard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,0BAA0B;AAC5B;AACA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".dashboardHeader {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  align-items: center;\n  color: gray;\n}\n\n.dashbaordContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  column-gap: 50px;\n  row-gap: 50px;\n  flex-wrap: wrap;\n  margin: 50px 0;\n}\n\n.searchInput {\n  width: 220px;\n  padding: 12px;\n  border-radius: 14px;\n  border: 1.5px solid gray;\n}\n\n.dashboardSpinner {\n  margin: 50px auto;\n}\n.centered {\n  position: absolute;\n  top: 155px;\n  left: 50%;\n  transform: translate(-50%);\n}\n@media screen and (max-width: 650px) {\n  .centered {\n    top: 250px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardHeader": `dashboard_dashboardHeader__WGDP1`,
	"dashbaordContainer": `dashboard_dashbaordContainer__urkFt`,
	"searchInput": `dashboard_searchInput__571lI`,
	"dashboardSpinner": `dashboard_dashboardSpinner__98Tu9`,
	"centered": `dashboard_centered__cZRIZ`
};
export default ___CSS_LOADER_EXPORT___;
