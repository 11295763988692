import api from "./api";

export const feedFormatOptions = [
  {
    label: "Default RecruitNow",
    value: "DefaultRecruitNow",
  },
  {
    label: "RecruitNow Apply",
    value: "RecruitNowApply",
  },
  {
    label: "Vapro Recruitment",
    value: "VaproRecruitment",
  },
  {
    label: "Undutchables",
    value: "Undutchables",
  },
  {
    label: "BMC",
    value: "BMC",
  },
  {
    label: "Tempo Team/Randstad",
    value: "TempoTeamRandstad",
  },
];

export const mainOptions = [
  { value: "Main", label: "Main" },
  { value: "Apply", label: "Apply" },
];

export const getFeedByIdV2 = async (id: number | string, clientId: number) => {
  const response: any = await api.get(`v2/clients/${clientId}/feeds/${id}`);
  return response;
};
