import arrowdown from "./angle-down.png";
import budgetPlanningIcon from "./bugetPlanning.svg";
import campaignsIcon from "./campaigns.svg";
import dashboardbg from "./dashboardbg.png";
import dashboardLogo from "./dashboardLogo.png";
import defaultClientLogo from "./defaultClientLogo.png";
import editIcon from "./edit.png";
import feedsIcon from "./feeds.svg";
import insightsIcon from "./Insights.svg";
import jobBoardIcon from "./jobboards.svg";
import lookerIcon from "./looker.svg";
import notificationIcon from "./notifaction.png";
import offlineresultlogo from "./OfflineResults_logo_white.png";
import onlineresultlogo from "./OnlineResults_logo_white.png";
import vacanciesOpenIcon from "./open vacancies.svg";
import vacanciesIcon from "./vacancies.svg";

export const images = {
  editIcon,
  onlineresultlogo,
  offlineresultlogo,
  defaultClientLogo,
  notificationIcon,
  arrowdown,
  vacanciesIcon,
  vacanciesOpenIcon,
  campaignsIcon,
  feedsIcon,
  insightsIcon,
  budgetPlanningIcon,
  jobBoardIcon,
  dashboardLogo,
  dashboardbg,
  lookerIcon,
};
