import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  role: null,
  userName: null,
  permissionsList: null,
};

export const userSlice: any = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state: any, action: any) => {
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.userName = action.payload.userName;
      state.permissionsList = action.payload.permissions;
    },
    removeUser: (state: any) => {
      state.token = null;
      state.role = null;
      state.userName = null;
      state.permissionsList = null;
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
