type YearChangeProps = {
  handleChangeBudgetYear: (data: string) => void;
  currentYear: number;
  theme: "black" | "white";
};

const YearChange = (props: YearChangeProps) => {
  const { handleChangeBudgetYear, currentYear, theme } = props;

  return (
    <div className=" flex items-center border rounded-3xl px-3 py-2 gap-2">
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.57324 4.08047V0.984161"
          stroke={theme === "black" ? "#FFFFFF" : "#B3B9C2"}
          strokeWidth="1.048"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4266 4.08047V0.984161"
          stroke={theme === "black" ? "#FFFFFF" : "#B3B9C2"}
          strokeWidth="1.048"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0333 2.66291H3.96659C2.78659 2.66291 1.83325 3.61624 1.83325 4.79624V12.1829C1.83325 13.3629 2.78659 14.3162 3.96659 14.3162H12.0399C13.2199 14.3162 14.1733 13.3629 14.1733 12.1829V4.79624C14.1666 3.61624 13.2133 2.66291 12.0333 2.66291Z"
          stroke={theme === "black" ? "#FFFFFF" : "#B3B9C2"}
          strokeWidth="1.048"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.83325 6.29846H14.1666"
          stroke={theme === "black" ? "#FFFFFF" : "#B3B9C2"}
          strokeWidth="1.048"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {/* left arrow */}
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => handleChangeBudgetYear("prev")}
      >
        <path
          d="M6.11337 8.47321L8.94004 11.2999C9.00201 11.3624 9.07575 11.412 9.15699 11.4458C9.23823 11.4796 9.32536 11.4971 9.41337 11.4971C9.50138 11.4971 9.58852 11.4796 9.66976 11.4458C9.751 11.412 9.82473 11.3624 9.8867 11.2999C10.0109 11.175 10.0806 11.006 10.0806 10.8299C10.0806 10.6537 10.0109 10.4848 9.8867 10.3599L7.5267 7.99987L9.8867 5.63987C10.0109 5.51496 10.0806 5.34599 10.0806 5.16987C10.0806 4.99375 10.0109 4.82478 9.8867 4.69987C9.82441 4.63808 9.75053 4.5892 9.66931 4.55602C9.58808 4.52285 9.50111 4.50603 9.41337 4.50654C9.32563 4.50603 9.23866 4.52285 9.15743 4.55602C9.07621 4.5892 9.00233 4.63808 8.94004 4.69987L6.11337 7.52654C6.05088 7.58851 6.00129 7.66225 5.96744 7.74349C5.9336 7.82473 5.91617 7.91186 5.91617 7.99987C5.91617 8.08788 5.9336 8.17502 5.96744 8.25626C6.00129 8.3375 6.05088 8.41123 6.11337 8.47321Z"
          fill={theme === "black" ? "#FFFFFF" : "#B3B9C2"}
        />
      </svg>
      <span className=" text-[#909EA7] text-sm">{currentYear}</span>
      {/* Right arrow */}
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => handleChangeBudgetYear("next")}
      >
        <path
          d="M9.88663 7.5268L7.05996 4.70013C6.99799 4.63764 6.92425 4.58805 6.84301 4.5542C6.76177 4.52036 6.67464 4.50293 6.58663 4.50293C6.49862 4.50293 6.41148 4.52036 6.33024 4.5542C6.249 4.58805 6.17527 4.63764 6.1133 4.70013C5.98913 4.82504 5.91943 4.994 5.91943 5.17013C5.91943 5.34625 5.98913 5.51522 6.1133 5.64013L8.4733 8.00013L6.1133 10.3601C5.98913 10.485 5.91943 10.654 5.91943 10.8301C5.91943 11.0063 5.98913 11.1752 6.1133 11.3001C6.17559 11.3619 6.24947 11.4108 6.33069 11.444C6.41192 11.4772 6.49889 11.494 6.58663 11.4935C6.67437 11.494 6.76134 11.4772 6.84257 11.444C6.92379 11.4108 6.99767 11.3619 7.05996 11.3001L9.88663 8.47346C9.94911 8.41149 9.99871 8.33775 10.0326 8.25651C10.0664 8.17527 10.0838 8.08814 10.0838 8.00013C10.0838 7.91212 10.0664 7.82498 10.0326 7.74374C9.99871 7.6625 9.94911 7.58877 9.88663 7.5268Z"
          fill={theme === "black" ? "#FFFFFF" : "#B3B9C2"}
        />
      </svg>
    </div>
  );
};

export default YearChange;
