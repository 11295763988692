import { AxiosError } from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Icons } from "../../assets/icons";
import { AuthInput } from "../../components/FormFields/FormFields";
import Spinner from "../../components/Spinner/Spinner";
import Page from "../../layouts/Page/Page";
import { recentLogin } from "../../redux/recentlogin";
import { openSnackbar } from "../../redux/snackbar";
import { setUser } from "../../redux/user";
import { routeNames } from "../../router/routeNames";
import { login, logout } from "../../services/auth";
import { jwtDecoder } from "../../utils";

import styles from "./login.module.css";

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<any>({});
  const { mutate, isLoading, isError, data, error, reset } = useMutation(
    (data: any) => login(data),
    {
      onError: (e: AxiosError) => {
        setErrors(e.response?.data);
      },
    },
  );

  const removeValidationError = (key: string) => {
    setErrors((errors: Record<string, string[]>) => {
      delete errors[key];

      return {
        ...errors,
      };
    });
  };

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(recentLogin());
      if (!data?.data?.accessToken && data?.data?.message) {
        dispatch(
          openSnackbar({
            message: data?.data?.message,
            type: "success",
          }),
        );

        return;
      }
      const tokenObject: any = jwtDecoder(data?.data?.accessToken);
      dispatch(
        setUser({
          token: data?.data?.accessToken,
          role: tokenObject.role,
          userName: tokenObject.email,
        }),
      );
      navigate("/");
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading && isError) {
      const loginError: any = error;
      if (loginError.response.statusText === "Unauthorized")
        dispatch(
          openSnackbar({
            message: loginError?.response?.statusText + ": Invalid credentials",
            type: "error",
          }),
        );
      reset();
    }
  }, [error]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    removeValidationError(key);
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();

    mutate(formData);
  };

  useEffect(() => {
    logout();
  }, []);
  return (
    <Page>
      <div className={styles.authCardContainer}>
        <div className={styles.authCard}>
          <h2 className="text-3xl font-bold tracking-wide flex justify-center">{`${t(
            "login",
            "Login",
          )}`}</h2>

          <form
            onSubmit={(e) => e.preventDefault()}
            className=" flex flex-col gap-y-4 mt-5 max-w-md"
          >
            <AuthInput
              icon={<Icons.UserIcon />}
              name="email"
              handleChange={handleOnChange}
              placeholder="Email"
              type="email"
              value={formData?.email}
              errors={errors["email"]}
            />

            <AuthInput
              icon={<Icons.LockIcon />}
              name="password"
              handleChange={handleOnChange}
              placeholder="Password"
              type={"password"}
              value={formData?.password}
              errors={errors["password"]}
            />

            <div className="flex justify-end mb-4">
              <button
                onClick={() => navigate(routeNames.FORGOT_PASSWORD)}
                type="button"
                className="text-blue-600 cursor-pointer underline "
              >
                Forgotten Password ?
              </button>
            </div>

            <button
              className={
                "w-full bg-[#245ED2] rounded text-white py-2 flex justify-center items-center"
              }
              onClick={handleLogin}
              disabled={isLoading}
            >
              {!isLoading ? t("login", "Login") : <Spinner size={24} />}
            </button>
          </form>
        </div>
      </div>
    </Page>
  );
}

export default Login;
