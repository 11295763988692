import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { refreshAccessToken } from "../services/auth";
import { jwtDecoder } from "../utils";

import { routeNames } from "./routeNames";

const getNewAuthToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken) {
    try {
      const { accessToken, refreshToken: newRefreshToken } =
        await refreshAccessToken(refreshToken);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", newRefreshToken);
      return accessToken;
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
};

function PrivateRoute() {
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const checkAndRefreshToken = async () => {
      const currentToken = await localStorage.getItem("accessToken");

      if (!currentToken) {
        const newToken = await getNewAuthToken();
        if (newToken) {
          setToken(newToken);
        }
      } else {
        setToken(currentToken);
        const decodedToken = jwtDecoder(currentToken);

        if (decodedToken === "InvalidTokenError") {
          const newToken = await getNewAuthToken();
          if (newToken) {
            setToken(newToken);
          } else {
            setToken(null);
          }
        }
      }
      setLoading(false);
    };

    checkAndRefreshToken();
    if (
      /^\/client\/(\d+)\/apply$/.test(location.pathname) &&
      process.env.REACT_APP_ENV
    ) {
      window.location.href = "/client/dashboard";
    }

    const tokenListener = () => {
      checkAndRefreshToken();
    };

    window.addEventListener("storage", tokenListener);
    return () => {
      window.removeEventListener("storage", tokenListener);
    };
  }, [token]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return token ? <Outlet /> : <Navigate to={routeNames.LOGIN} />;
}

export default PrivateRoute;
