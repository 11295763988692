import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoTrashBin } from "react-icons/io5";

import Spinner from "../Spinner/Spinner";
import ToolTipNew from "../ToolTipNew/ToolTipNew";

import styles from "./CampaignTable.module.css";

type TableProps = {
  status?: boolean;
  columns: any[];
  data?: any[];
  editable?: boolean;
  deleteable?: boolean;
  link?: boolean;
  onRowClick?: any;
  onItemClick?: any;
  onDelete?: any;
  deleteTooltip?: string;
  viewTooltip?: string;
  onLinkClick?: any;
  linkTooltip?: string;
  clickableItems?: string[];
  onEdit?: any;
  editTooltip?: string;
  allowOrderUpdate?: boolean;
  onMoveUp?: any;
  onMoveDown?: any;
  moveUpTooltip?: string;
  moveDownTooltip?: string;
  updatingPriority?: boolean;
  sortable?: boolean;
  order?: boolean;
  sum?: any;
  handleParentPlayPause?: any;
  loadingCampaigns?: any;
};

function CampaignTable(props: Readonly<TableProps>) {
  const {
    status = true,
    columns,
    data = [],
    editable = false,
    deleteable = false,
    link = false,
    onRowClick = () => {
      // Place Holder Function
    },
    onItemClick = () => {
      // Place holder Funciton
    },
    onDelete = () => {
      // Place Holder Function
    },
    deleteTooltip = undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    viewTooltip = undefined,
    onLinkClick = () => {
      // Place Holder Function
    },
    linkTooltip = undefined,
    clickableItems = [],
    editTooltip = undefined,
    onEdit = () => {
      // Place Holder Function
    },
    allowOrderUpdate = false,
    onMoveUp = () => {
      // Place Holder Function
    },
    moveUpTooltip = undefined,
    onMoveDown = () => {
      // Place Holder Function
    },
    moveDownTooltip = undefined,
    updatingPriority = false,
    sortable = true,
    order = false,
    loadingCampaigns = false,

    handleParentPlayPause = () => {
      // Place Holder Function
    },
  } = props;

  const { t } = useTranslation();

  const [tableData, setTableData] = useState<any>([]);

  const [sortDetails, setSortDetails] = useState<any>({
    index: 0,
    order: "",
  });

  useEffect(() => {
    if (order) {
      const sorteddata = sortAsc(0, data);

      setTableData(sorteddata);
    } else {
      const tempData = data.map((item) => {
        let name = item?.name;
        if (item?.name?.length > 10) {
          name = `${name.slice(0, 10)}...`;
        }
        return {
          ...item,
          name: name,
        };
      });
      setTableData(tempData);
    }
  }, [data]);

  const sort = (index: number) => {
    let data: any = [...tableData];

    if (sortDetails?.index === index) {
      data =
        sortDetails?.order === "asc"
          ? sortDesc(index, data)
          : sortAsc(index, data);
    } else {
      data = sortAsc(index, data);
    }
    setTableData(data);
  };

  const sortAsc = (index: number, data: any) => {
    setSortDetails({
      index,
      order: "asc",
    });
    return data.sort((a: any, b: any) => {
      return columns?.[index]?.accessor(a) < columns?.[index]?.accessor(b)
        ? -1
        : 1;
    });
  };

  const sortDesc = (index: number, data: any) => {
    setSortDetails({
      index,
      order: "desc",
    });
    return data.sort((a: any, b: any) => {
      return columns?.[index]?.accessor(a) > columns?.[index]?.accessor(b)
        ? -1
        : 1;
    });
  };

  const handleOnDelete = (e: any, item: any) => {
    e.stopPropagation();
    onDelete(item);
  };

  const handleLinkClick = (e: any, item: any) => {
    e.stopPropagation();
    onLinkClick(item);
  };

  const handleEditClick = (e: any, item: any) => {
    e.stopPropagation();
    onEdit(item);
  };

  const handleItemClick = (e: any, accessKey: any, item: any) => {
    e.stopPropagation();
    onItemClick({ accessKey, item });
  };

  const handleMoveUp = (e: any, item: any) => {
    e.stopPropagation();
    onMoveUp(item);
  };

  const handleMoveDown = (e: any, item: any) => {
    e.stopPropagation();
    onMoveDown(item);
  };

  const getDateValue = (data: any) => {
    // Get date in 'yyyy-mm-dd' format
    const date = data?.split("T")?.[0];
    return date;
  };

  return (
    <div className={`${styles.tableContainer} border-2 rounded-lg`}>
      <table className={styles.table}>
        <thead className={styles.mainHeader}>
          <tr className={`${styles.tableHeader} ${styles.tableTop}`}>
            {}
            {status && (
              <td
                className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.firstColumn}`}
              />
            )}

            {columns?.map((header: any, index: any) => (
              <td
                key={`${"" + index}`}
                className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.sortHeader}`}
                onClick={() => sortable && sort(index)}
              >
                {t(`${header?.translationKey}`, `${header?.label}`)}

                {sortable &&
                  (sortDetails?.index === index &&
                  (sortDetails?.order === "asc" ||
                    sortDetails?.order === "desc") ? (
                    <>
                      {sortDetails?.order === "desc" && (
                        <svg
                          height={"16px"}
                          viewBox="0 0 320 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M311.9 335.1l-132.4 136.8C174.1 477.3 167.1 480 160 480c-7.055 0-14.12-2.702-19.47-8.109l-132.4-136.8C-9.229 317.8 3.055 288 27.66 288h264.7C316.9 288 329.2 317.8 311.9 335.1z" />
                        </svg>
                      )}
                      {sortDetails?.order === "asc" && (
                        <svg
                          height={"16px"}
                          viewBox="0 0 320 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z" />
                        </svg>
                      )}
                    </>
                  ) : (
                    <svg
                      height={"16px"}
                      fill={"grey"}
                      viewBox="0 0 320 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                    </svg>
                  ))}
              </td>
            ))}

            {allowOrderUpdate && (
              <td
                className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.orderColumn}`}
              />
            )}

            {(deleteable || link || editable) && (
              <td
                className={`${styles.tableHeaderItem} ${styles.columnItemHeader} ${styles.lastColumn}`}
              >
                Actions
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          {!!tableData.length &&
            tableData?.map((item: any, index: any) => {
              return (
                <tr
                  key={`${"" + index}`}
                  className={`${styles.tableHeader} ${styles.tableRow} 
                            ${
                              index % 2 === 0 ? styles.whiteRow : styles.greyRow
                            } relative`}
                  onClick={() => onRowClick(item)}
                >
                  {/* this  */}
                  {status && item.status ? (
                    <td
                      className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.firstColumn}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleParentPlayPause(item?.id);
                      }}
                    >
                      {item?.paused ? (
                        <svg
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                          fill={"red"}
                          width={"16px"}
                        >
                          <title />
                          <path d="M224,432H144V80h80Z" />
                          <path d="M368,432H288V80h80Z" />
                        </svg>
                      ) : (
                        <svg
                          height="16px"
                          id="Layer_1"
                          version="1.1"
                          fill={"green"}
                          viewBox="0 0 512 512"
                          width="16px"
                          xmlSpace="preserve"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <path d="M405.2,232.9L126.8,67.2c-3.4-2-6.9-3.2-10.9-3.2c-10.9,0-19.8,9-19.8,20H96v344h0.1c0,11,8.9,20,19.8,20  c4.1,0,7.5-1.4,11.2-3.4l278.1-165.5c6.6-5.5,10.8-13.8,10.8-23.1C416,246.7,411.8,238.5,405.2,232.9z" />
                        </svg>
                      )}
                    </td>
                  ) : (
                    <td
                      className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.firstColumn}  group`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ToolTipNew message="Deleted Campaign!" />
                      <IoTrashBin />
                    </td>
                  )}

                  <Columns
                    key={`${"" + index}`}
                    clickableItems={clickableItems}
                    columns={columns}
                    getDateValue={getDateValue}
                    handleItemClick={handleItemClick}
                    index={index}
                    item={item}
                  />

                  {allowOrderUpdate && (
                    <td
                      className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.orderColumn}`}
                    >
                      {updatingPriority || loadingCampaigns ? (
                        <Spinner size={"16px"} />
                      ) : (
                        <>
                          {index !== tableData.length - 1 && (
                            <div
                              className={`${styles.iconWrap} ${
                                moveDownTooltip ? "tooltip" : ""
                              }`}
                              data-type={moveDownTooltip}
                            >
                              <svg
                                onClick={(e: any) => handleMoveDown(e, item)}
                                height="18px"
                                width="18px"
                                version="1.1"
                                viewBox="0 0 512 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M383.6,322.7L278.6,423c-5.8,6-13.7,9-22.4,9c-8.7,0-16.5-3-22.4-9L128.4,322.7c-12.5-11.9-12.5-31.3,0-43.2  c12.5-11.9,32.7-11.9,45.2,0l50.4,48.2v-217c0-16.9,14.3-30.6,32-30.6c17.7,0,32,13.7,32,30.6v217l50.4-48.2  c12.5-11.9,32.7-11.9,45.2,0C396.1,291.4,396.1,310.7,383.6,322.7z" />
                              </svg>
                            </div>
                          )}
                          {index !== 0 && (
                            <div
                              className={`${styles.iconWrap} ${
                                moveUpTooltip ? "tooltip" : ""
                              }`}
                              data-type={moveUpTooltip}
                            >
                              <svg
                                onClick={(e: any) => handleMoveUp(e, item)}
                                height="18px"
                                width="18px"
                                version="1.1"
                                viewBox="0 0 512 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M128.4,189.3L233.4,89c5.8-6,13.7-9,22.4-9c8.7,0,16.5,3,22.4,9l105.4,100.3c12.5,11.9,12.5,31.3,0,43.2  c-12.5,11.9-32.7,11.9-45.2,0L288,184.4v217c0,16.9-14.3,30.6-32,30.6c-17.7,0-32-13.7-32-30.6v-217l-50.4,48.2  c-12.5,11.9-32.7,11.9-45.2,0C115.9,220.6,115.9,201.3,128.4,189.3z" />
                              </svg>
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  )}

                  {(deleteable || link || editable) && (
                    <td
                      className={`${styles.tableHeaderItem} ${styles.columnItem} ${styles.lastColumn}`}
                    >
                      {link && (
                        <div
                          className={`${styles.iconWrap} ${
                            linkTooltip ? "tooltip" : ""
                          }`}
                          data-type={linkTooltip}
                        >
                          <svg
                            onClick={(e: any) => handleLinkClick(e, item)}
                            fill="none"
                            height="18px"
                            viewBox="0 0 24 24"
                            width="18px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.4142 5.62843C17.6332 4.84738 16.3668 4.84738 15.5858 5.62843L12.7574 8.45685C12.3668 8.84738 11.7337 8.84738 11.3431 8.45685C10.9526 8.06633 10.9526 7.43317 11.3431 7.04264L14.1716 4.21421C15.7337 2.65212 18.2663 2.65212 19.8284 4.21421C21.3905 5.77631 21.3905 8.30897 19.8284 9.87107L17 12.6995C16.6095 13.09 15.9763 13.09 15.5858 12.6995C15.1953 12.309 15.1953 11.6758 15.5858 11.2853L18.4142 8.45685C19.1953 7.67581 19.1953 6.40948 18.4142 5.62843ZM14.7071 9.29289C15.0976 9.68342 15.0976 10.3166 14.7071 10.7071L10.7071 14.7071C10.3166 15.0976 9.68342 15.0976 9.29289 14.7071C8.90237 14.3166 8.90237 13.6834 9.29289 13.2929L13.2929 9.29289C13.6834 8.90237 14.3166 8.90237 14.7071 9.29289ZM8.41421 11.3005C8.80474 11.691 8.80474 12.3242 8.41421 12.7147L5.58579 15.5431C4.80474 16.3242 4.80474 17.5905 5.58579 18.3716C6.36683 19.1526 7.63316 19.1526 8.41421 18.3716L11.2426 15.5431C11.6332 15.1526 12.2663 15.1526 12.6569 15.5431C13.0474 15.9337 13.0474 16.5668 12.6569 16.9574L9.82843 19.7858C8.26633 21.3479 5.73367 21.3479 4.17157 19.7858C2.60948 18.2237 2.60948 15.691 4.17157 14.1289L7 11.3005C7.39052 10.91 8.02369 10.91 8.41421 11.3005Z"
                              fill="black"
                            />
                          </svg>
                          1ss
                        </div>
                      )}
                      {editable && (
                        <div
                          className={`${styles.iconWrap} ${
                            editTooltip ? "tooltip" : ""
                          }`}
                          data-type={editTooltip}
                        >
                          <svg
                            onClick={(e: any) => handleEditClick(e, item)}
                            height="18px"
                            width="18px"
                            viewBox="0 0 500 500"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M31.949,403.452c0,35.164,28.435,63.599,63.599,63.599  c17.529,0,33.891-6.637,45.426-18.172l115.93-116.018l31.072,31.071c-4.182,15.265-0.181,32.345,11.905,44.518l45.063,45.063  c17.985,17.901,46.782,18.08,64.504,0.362c17.719-17.721,17.539-46.511-0.366-64.504l-45.063-45.063  c-12.174-12.085-29.254-16.078-44.514-11.898l-31.072-30.991l19.983-19.983c42.516,10.722,89.397-0.459,122.563-32.888  c22.803-22.35,35.352-50.698,37.07-80.04v-1.543c0-9.91-8.181-17.905-18.26-17.905c-5.001,0-9.544,2.006-12.907,5.181l-5.353,5.271  l-18.356,17.985c-11.543,11.268-27.984,17.896-45.701,17.896c-17.622,0-33.165-7.718-44.701-19.076  c-11.355-11.533-19.166-27.167-19.166-44.788c0-17.717,6.63-34.159,17.897-45.702l17.985-18.358l5.269-5.351  c3.18-3.363,5.181-7.906,5.181-12.91c0-10.079-7.992-18.26-17.904-18.26h-1.544c-29.34,1.721-57.687,14.268-80.038,37.071  c-32.43,33.165-43.52,80.047-32.802,122.477l-20.069,20.069l-83.863-83.943v-22.988L59.205,76.376l-27.256,27.256l27.256,54.513  h22.891l83.951,83.863L50.119,358.026C38.586,369.561,31.949,385.923,31.949,403.452z M77.375,398.909  c0-12.536,10.177-22.711,22.714-22.711c12.538,0,22.713,10.175,22.713,22.711s-10.175,22.716-22.713,22.716  C87.553,421.625,77.375,411.445,77.375,398.909z"
                              fill="#010101"
                            />
                          </svg>
                        </div>
                      )}
                      {deleteable && (
                        <div
                          className={`${styles.iconWrap} ${
                            deleteTooltip ? "tooltip" : ""
                          }`}
                          data-type={deleteTooltip}
                        >
                          <svg
                            onClick={(e: any) => handleOnDelete(e, item)}
                            height="18px"
                            width="18px"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M298.656,187.5c-10.096,0-18.281,8.185-18.281,18.281v129.188c0,10.096,8.185,18.281,18.281,18.281   s18.281-8.185,18.281-18.281V205.781C316.938,195.685,308.753,187.5,298.656,187.5z" />
                            <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M365.688,163.125v202.312   c0,20.193-16.37,36.562-36.562,36.562h-146.25c-20.193,0-36.562-16.37-36.562-36.562V163.125   c-13.462,0-24.375-10.913-24.375-24.375s10.913-24.375,24.375-24.375h49.796c3.232,0,6.332-1.284,8.618-3.57l17.236-17.236   c2.286-2.285,5.385-3.57,8.618-3.57h50.841c3.232,0,6.332,1.284,8.618,3.57l17.236,17.236c2.286,2.286,5.385,3.57,8.618,3.57   h49.795c13.462,0,24.375,10.913,24.375,24.375S379.149,163.125,365.688,163.125z" />
                            <path d="M213.344,187.5c-10.096,0-18.281,8.185-18.281,18.281v129.188c0,10.096,8.185,18.281,18.281,18.281   s18.281-8.185,18.281-18.281V205.781C231.625,195.685,223.44,187.5,213.344,187.5z" />
                          </svg>
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default CampaignTable;

type ColumnsProps = {
  columns: any;
  index: any;
  item: any;
  getDateValue: any;
  clickableItems: any;
  handleItemClick: any;
};

const Columns = (props: Readonly<ColumnsProps>) => {
  const {
    columns,
    index,
    item,
    getDateValue,
    clickableItems,
    handleItemClick,
  } = props;
  return columns?.map(({ accessor, type, accessKey }: any) => {
    let data;

    if (!accessor) {
      return (
        <td key={`${"" + index}`} className={`${styles.columnItem}`}>
          --
        </td>
      );
    }
    if (accessKey === "budgetCount") {
      data = <ColumnData index={index} item={item} key={`${"" + index}`} />;
    } else {
      data =
        accessor(item) !== null &&
        accessor(item) !== "" &&
        accessor(item) !== undefined
          ? accessor(item)
          : "--";
      data = type === "DateTime" ? getDateValue(data) : data;
    }

    return (
      <td
        key={accessor}
        className={`${styles.columnItem} ${
          clickableItems?.includes(accessKey) ? styles.clickable : ""
        } font-medium text-sm font-plus-jakarta text-[#737373] `}
        onClick={(e: any) => {
          if (clickableItems?.includes(accessKey)) {
            handleItemClick(e, accessKey, item);
          }
        }}
      >
        {data}
      </td>
    );
  });
};

type ColumnDataProps = {
  index: any;
  item: any;
};
const ColumnData = (props: Readonly<ColumnDataProps>) => {
  const { index, item } = props;
  return (
    <div className="w-full  rounded-full h-4 bg-[#71707033]">
      <div
        className={`${
          index % 2 === 0 ? "bg-[#34A853]" : "bg-red-500"
        }  h-4 rounded-full flex justify-center items-center text-white font-bold`}
      >
        {item?.budget}
      </div>
    </div>
  );
};
