import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { Icons } from "../../../assets/icons";
import { openSnackbar } from "../../../redux/snackbar";
import { routeNames } from "../../../router/routeNames";
import { invalidInputMessage, validateEmail } from "../../../services/common";
import { createJobBoard } from "../../../services/jobBoard";
import { Button } from "../../Button/Button";

import styles from "./CreateJobBoard.module.css";

const contactPersonsInitialState = {
  name: "",
  phone: "",
  email: "",
  isPrimary: true,
};

const jobBoardInitialState = {
  name: "",
  feedSort: "Per Client",
  status: true,
};

type ContactPerson = {
  name: string;
  phone: string;
  email: string;
  isPrimary: boolean;
};

type JobBoardForm = {
  name: string;
  feedSort: string;
  status: boolean;
};

function CreateJobBoard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [jobBoardData, setJobBoardData] = useState<JobBoardForm>({
    name: "",
    feedSort: "Per Client",
    status: true,
  });

  const [contactPersons, setContactPersons] = useState<ContactPerson[]>([
    {
      name: "",
      phone: "",
      email: "",
      isPrimary: true,
    },
  ]);

  const { mutate, data, error, isLoading, isError } = useMutation((data: any) =>
    createJobBoard(data),
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setJobBoardData({
      ...jobBoardData,
      [e.target.name]: e.target.value,
    });
  };

  const handleContactPersonChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    e.preventDefault();

    const prevContacts: ContactPerson[] = [...contactPersons];
    type StringContactPersonFields = "name" | "phone" | "email";
    const name: StringContactPersonFields = e.target
      .name as StringContactPersonFields;
    prevContacts[index][name] = e.target.value;
    setContactPersons(prevContacts);
  };

  //1
  const handleCreateJobBoard = async () => {
    const { valid, message } = validateClientForm();
    if (!valid) {
      dispatch(
        openSnackbar({
          message,
          type: "error",
        }),
      );
      return;
    }
    mutate({
      ...jobBoardData,
      contactPersons,
    });
  };

  const validateClientForm = () => {
    if (!jobBoardData?.name.trim()) {
      return invalidInputMessage("Name is required");
    }

    if (
      !contactPersons[0]?.name.trim() ||
      !contactPersons[0]?.email.trim() ||
      !contactPersons[0]?.phone.trim()
    ) {
      return invalidInputMessage(
        "Name, phone and email of at least one contact person are required",
      );
    }

    for (const cp of contactPersons) {
      if (!validateEmail(cp.email)) {
        return invalidInputMessage("Invalid email address");
      }
    }

    return { valid: true, message: null };
  };

  //2
  useEffect(() => {
    if (error) {
      dispatch(
        openSnackbar({
          message: "Error while creating job board",
          type: "error",
        }),
      );
    }
  }, [error]);

  //3
  useEffect(() => {
    if (!isError && data) {
      dispatch(
        openSnackbar({
          message: data?.data?.message,
        }),
      );
      setJobBoardData(jobBoardInitialState);
      setContactPersons([contactPersonsInitialState]);
      navigate(routeNames.JOBBOARDS);
    }
  }, [data]);

  const options = [
    { value: "Per Client", label: t("perClient") },
    { value: "Per JobBoard", label: t("perJobBoard") },
  ];

  return (
    <>
      <div className=" flex justify-between mx-12 mt-20">
        <span className=" text-black text-xl font-semibold">
          {t("createNewJobboard")}
        </span>
        <button onClick={() => navigate(-1)}>
          <Icons.CloseIcon />
        </button>
      </div>
      <div className=" max-w-4xl mx-7 lg:m-auto">
        <div className="  justify-center sm:justify-start flex gap-4 gap-y-5 flex-wrap">
          <div className={`flex flex-col  gap-2 ${styles.createJobBoardInput}`}>
            <label className=" text-[#42526E] text-sm font-semibold">
              {t("name")}*
            </label>
            <input
              type="text"
              required
              placeholder="Enter name here.."
              name="name"
              value={jobBoardData?.name}
              onChange={handleInputChange}
              className={` border border-[#E5EAF0] outline-none bg-white rounded  p-2 text-[#2A263F] text-sm `}
            />
          </div>
          <div className={`flex flex-col  gap-2 ${styles.createJobBoardInput}`}>
            <label className=" text-[#42526E] text-sm font-semibold">
              {t("feedSort")}
            </label>
            <Select
              value={{
                value: jobBoardData?.feedSort,
                label: jobBoardData?.feedSort,
              }}
              options={options}
              styles={{
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "#E5EAF0",
                  borderRadius: "5px",
                  outline: "none",
                  fontSize: "16px",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#CDCDCD",
                  primary: "#CDCDCD",
                },
              })}
              onChange={(value: any) =>
                setJobBoardData({
                  ...jobBoardData,
                  feedSort: value.value,
                })
              }
            />
          </div>
        </div>

        <div className=" justify-center sm:justify-start flex flex-col gap-4 mt-9">
          {contactPersons?.map((person: any, index: any) => (
            <div
              className=" bg-[#F8F7FC] border border-[#F8F7FC] rounded p-5 shadow-sm"
              key={`${"" + index}`}
            >
              <div className=" flex justify-between  mb-3">
                <label className=" text-black font-semibold text-sm uppercase">
                  Contact #{index + 1}
                </label>

                {!person?.isPrimary && (
                  <Button
                    color="secondary"
                    variant="text"
                    onClick={() => {
                      const prevContacts = [...contactPersons];
                      prevContacts.splice(index, 1);
                      setContactPersons(prevContacts);
                    }}
                  >
                    Delete
                  </Button>
                )}
              </div>
              <div className=" flex flex-wrap gap-5">
                <div
                  className={` flex flex-col  gap-2 ${styles.createJobBoardInput}`}
                >
                  <label className=" text-[#42526E] text-sm font-semibold">
                    {t("name")} {person?.isPrimary && "*"}
                  </label>
                  <input
                    type="text"
                    placeholder="Enter name here.."
                    name="name"
                    value={person?.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleContactPersonChange(e, index)
                    }
                    className={` border border-[#E5EAF0] outline-none bg-white rounded  p-2 text-[#2A263F] text-sm `}
                  />
                </div>
                <div
                  className={` flex flex-col  gap-2 ${styles.createJobBoardInput}`}
                >
                  <label className=" text-[#42526E] text-sm font-semibold">
                    {t("Phone")} {person?.isPrimary && "*"}
                  </label>
                  <input
                    type="text"
                    placeholder="Enter phone here.."
                    name="phone"
                    value={person?.phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleContactPersonChange(e, index)
                    }
                    className={` border border-[#E5EAF0] outline-none bg-white rounded  p-2 text-[#2A263F] text-sm `}
                  />
                </div>
                <div className=" flex flex-col  gap-2 w-full">
                  <label className=" text-[#42526E] text-sm font-semibold">
                    {t("Email")} {person?.isPrimary && "*"}
                  </label>
                  <input
                    type="email"
                    placeholder="Enter email here.."
                    name="email"
                    value={person?.email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleContactPersonChange(e, index)
                    }
                    className={` border border-[#E5EAF0] outline-none bg-white rounded  p-2 text-[#2A263F] text-sm `}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className=" my-3">
          <Button
            color="secondary"
            variant="text"
            size="small"
            onClick={() =>
              setContactPersons([
                ...contactPersons,
                {
                  name: "",
                  phone: "",
                  email: "",
                  isPrimary: false,
                },
              ])
            }
          >
            + Add Contact
          </Button>
        </div>
        <Button
          color="primary"
          size="large"
          fullWidth
          onClick={handleCreateJobBoard}
          loading={isLoading}
        >
          Create Job Board
        </Button>
      </div>
    </>
  );
}

export default CreateJobBoard;
