// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-select__menu {
  background-color: white;
  border: 1px solid rgb(212, 212, 212) !important;
  border-radius: 5px;
  width: 100% !important;
}
.react-select__menu-list {
  padding-top: 5px;
}
.react-select__option {
  width: 50px;
  color: rgb(55, 55, 55) !important;
  text-align: left;
  padding: 10px;
}
.react-select__option:hover {
  background-color: #e0e0e0;
}
.react-select-container {
  margin: 1px;
}
.react-select__indicators {
  padding-right: 15px;
}

.react-select__value-container {
  text-align: left;
  padding-left: 15px;
  min-width: 150px !important;
}

.react-select__control {
  min-width: 150px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/BudgetCard/BudgetCard.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,+CAA+C;EAC/C,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,iCAAiC;EACjC,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,WAAW;AACb;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".react-select__menu {\n  background-color: white;\n  border: 1px solid rgb(212, 212, 212) !important;\n  border-radius: 5px;\n  width: 100% !important;\n}\n.react-select__menu-list {\n  padding-top: 5px;\n}\n.react-select__option {\n  width: 50px;\n  color: rgb(55, 55, 55) !important;\n  text-align: left;\n  padding: 10px;\n}\n.react-select__option:hover {\n  background-color: #e0e0e0;\n}\n.react-select-container {\n  margin: 1px;\n}\n.react-select__indicators {\n  padding-right: 15px;\n}\n\n.react-select__value-container {\n  text-align: left;\n  padding-left: 15px;\n  min-width: 150px !important;\n}\n\n.react-select__control {\n  min-width: 150px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
