import { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";

import Breadcrumb from "../../components/Breadcrumb";
import ViewCampaignNew from "../../components/Campaigns/ViewCampaignModal/ViewCampaignModal";
import CampaignTable from "../../components/CampaignTable/CampaignTable";
import Charts from "../../components/Charts";
import Spinner from "../../components/Spinner/Spinner";
import WarningModalDesign from "../../components/WarningModalDesign/WarningModalDesign";
import { openSnackbar } from "../../redux/snackbar";
import { routeNames } from "../../router/routeNames";
import {
  NEW_CAMPAIGN_COLUMNS,
  deleteCampaign,
  getClientCampaigns,
  switchCampaignsPriority,
  updatePlayPause,
} from "../../services/campaign";
type CampaignPausedType = "true" | "false" | "all";

const ClientDashboard = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isCampaignPaused, setIsCampaignPaused] =
    useState<CampaignPausedType>("false");
  const [budgetSum, setBudgetSum] = useState();

  const clientId = params.id;

  const [filter, setFilter] = useState<any>({
    from: "",
    to: "",
    branch: "",
  });

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<any>({
    id: "",
  });
  const [openViewCampaignModal, setOpenViewCampaignModal] =
    useState<boolean>(false);
  const [campaignId, setCampaignId] = useState<any>();

  const {
    data: CampaignsData,
    isLoading: loadingCampaigns,
    refetch: fetchClientCampaigns,
  } = useQuery(
    ["clientCampaigns", clientId, filter],
    () => getClientCampaigns(clientId, filter),
    {
      onSuccess(data) {
        const calculatedSum = data?.data[0]?.campaignJobBoard?.reduce(
          (accumulator: any, item: any) => {
            const budgetAmount = parseInt(
              item?.jobboardBudgetAmount?.amount,
              10,
            ); // Convert to integer
            return accumulator + (isNaN(budgetAmount) ? 0 : budgetAmount); // Check for NaN
          },
          0,
        );

        setBudgetSum(calculatedSum || 0);
      },
    },
  );

  const {
    mutate: DeleteCampaign,
    isLoading: deletingCampaign,
    data: DeleteCampaignRes,
    reset: ResetDeleteCampaign,
  } = useMutation((id: string) => deleteCampaign(id), {
    onSuccess() {
      setOpenModal(false);
      setDeleteItem({ id: "" });
    },
  });

  const { isLoading: updatingPriority, mutate: updatePriority } = useMutation(
    (switchPriorityData: any) =>
      switchCampaignsPriority(
        switchPriorityData?.fromCampaignId,
        switchPriorityData?.toCampaignId,
      ),
  );

  const { mutate, isError, isLoading, data, reset } = useMutation(
    (id: string) => updatePlayPause(id),
  );

  useEffect(() => {
    if (!isError && data) {
      const fetchData = async () => {
        await fetchClientCampaigns();
        dispatch(
          openSnackbar({
            message: "Status Updated",
          }),
        );
        reset();
      };

      fetchData();
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading && isError) {
      dispatch(
        openSnackbar({
          message: "Error while updating status",
          type: "error",
        }),
      );
      reset();
    }
  }, [isError]);

  useEffect(() => {
    setFilter({
      from: "",
      to: "",
      branch: "",
    });
  }, []);

  useEffect(() => {
    fetchClientCampaigns();
  }, [updatingPriority]);

  useEffect(() => {
    if (DeleteCampaignRes?.data) {
      dispatch(
        openSnackbar({
          message: "Campaign deleted",
          type: "success",
        }),
      );
      ResetDeleteCampaign();
      queryClient.refetchQueries("client");
      fetchClientCampaigns();
    }
  }, [DeleteCampaignRes]);

  useEffect(() => {
    fetchClientCampaigns();
  }, [filter]);

  const handlePriorityChange = (item: any, action: string) => {
    let currentIndex: number | null = null;
    CampaignsData?.data?.forEach((campaign: any, index: number) => {
      if (campaign?.id === item.id) {
        currentIndex = index;
      }
    });

    if (currentIndex === null) return;

    const priorityData = {
      fromCampaignId: item?.id,
      toCampaignId:
        action === "raise"
          ? CampaignsData?.data?.[currentIndex - 1]?.id
          : CampaignsData?.data?.[currentIndex + 1]?.id,
    };

    if (priorityData?.fromCampaignId && priorityData?.toCampaignId) {
      if (!updatingPriority) {
        updatePriority(priorityData);
      }
    }
  };

  const handleParentPlayPause = (id: any) => {
    mutate(id);
  };
  return (
    <section className="w-full">
      <div className="px-16 pb-4 pt-6 w-full bg-black">
        <div>
          <Breadcrumb
            currentPage={{
              text: "Client Dashboard",
              url: `/client/dashboard/${params.id}`,
            }}
            navigation={[]}
          />
        </div>
        <Charts />
      </div>
      <div className=" flex gap-5 sm:mx-14 mt-5 justify-center">
        <button
          className={` w-28 h-8 bg-[#34A853] ${isCampaignPaused === "false" && "border-4 border-[#DA4F66]"}  rounded-2xl text-white`}
          onClick={() => setIsCampaignPaused("false")}
        >
          Online
        </button>
        <button
          className={`w-28 h-8 bg-[#DA4F66] text-white ${isCampaignPaused === "true" && "border-4 border-[#34A853]"}  rounded-2xl`}
          onClick={() => setIsCampaignPaused("true")}
        >
          Offline
        </button>
        <button
          className={` w-28 h-8 bg-[#808080]   rounded-2xl text-white
              ${isCampaignPaused === "all" ? " border-[#DA4F66] border-4" : ""}
            `}
          onClick={() => setIsCampaignPaused("all")}
        >
          All
        </button>
      </div>
      <div className="sm:mx-14 my-6">
        {loadingCampaigns ? (
          <Spinner size={"24px"} />
        ) : (
          !!CampaignsData?.data && (
            <CampaignTable
              columns={NEW_CAMPAIGN_COLUMNS}
              data={
                CampaignsData?.data?.filter((item: any) => {
                  if (isCampaignPaused === "all") {
                    return true;
                  }
                  if (
                    isCampaignPaused === "false" &&
                    item.status === true &&
                    item.paused === false
                  ) {
                    return true;
                  }
                  if (
                    isCampaignPaused === "true" &&
                    (item.status === false || item.paused === true)
                  ) {
                    return true;
                  }
                }) || []
              }
              deleteable={true}
              sortable={false}
              clickableItems={["vacanciesCount"]}
              onDelete={(campaign: any) => {
                setOpenModal(true);
                setDeleteItem(campaign);
              }}
              onRowClick={(campaign: any) => {
                setOpenViewCampaignModal(true);
                setCampaignId(campaign?.id);
              }}
              onItemClick={({ accessKey, item }: any) => {
                if (accessKey !== "vacanciesCount") return;

                navigate(
                  `${routeNames.CLIENT}/${clientId}/vacancies?campaignId=${item?.id}`,
                );
              }}
              allowOrderUpdate={true}
              onMoveUp={(item: any) => handlePriorityChange(item, "raise")}
              moveUpTooltip={"Raise priority"}
              onMoveDown={(item: any) => handlePriorityChange(item, "lower")}
              updatingPriority={updatingPriority}
              order={false}
              sum={budgetSum}
              handleParentPlayPause={handleParentPlayPause}
            />
          )
        )}
      </div>

      {openViewCampaignModal && (
        <ViewCampaignNew
          isOpen={openViewCampaignModal}
          onClose={() => setOpenViewCampaignModal(false)}
          campaignID={campaignId}
          clientId={clientId}
        />
      )}

      {openModal && (
        <WarningModalDesign
          heading={"Delete Campaign"}
          title={"Are you sure you want to delete this campaign?"}
          isOpen={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          deleteItem={() => {
            if (deleteItem) {
              DeleteCampaign(deleteItem?.id);
            }
          }}
          isLoading={deletingCampaign}
        />
      )}
    </section>
  );
};

export default ClientDashboard;
