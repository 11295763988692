import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";

import { openSnackbar } from "../../../redux/snackbar";
import { getVacanciesCountForSegment } from "../../../services/campaign";
import { displayErrorSnackbar, isValidInput } from "../../../services/common";
import {
  createSegment,
  getSegmentFilters,
  getSegmentOptionsByFilter,
} from "../../../services/vacancySegment";
import Spinner from "../../Spinner/Spinner";
import SegmentConditions from "../SegmentConditions";

import styles from "./CreateSegment.module.css";

function CreateSegment(props: any) {
  const {
    onCreateSegment = () => {
      // Place Holder Comment
    },
    onDataFilledChange,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [openSegmentBuilder, setOpenSegmentBuilder] = useState<boolean>(false);

  const [vacanciesCount, setVacanciesCount] = useState<number>(0);

  const [segment, setSegment] = useState<any>({
    name: "",
    status: true,
    segmentGroup: "Any",
  });

  const [segmentConditions, setSegmentConditions] = useState<any>([
    {
      conditionName: "",
      conditionList: [],
      condition: "",
      conditionContentList: [],
      conditionContent: "",
    },
  ]);
  const [completeConditions, setCompleteConditions] = useState<any>([]);

  const [availableVariables, setAvailableVariables] = useState<any>([]);

  const {
    mutate: createNewSegment,
    data: CreateSegmentRes,
    error: CreateSegmentError,
    isLoading: CreatingSegment,
    reset: ResetCreateSegment,
  } = useMutation((data: any) => createSegment(data), {
    onSuccess(data) {
      onCreateSegment(data?.data?.data);
      onDataFilledChange(false);
      setSegment({
        name: "",
        status: true,
        segmentGroup: "Any",
      });
      setSegmentConditions([]);
      setVacanciesCount(0);
    },
  });
  //this
  const {
    isError: segmentVacanciesCountError,
    isLoading: segmentVacanciesCountSpinner,
    isFetching,
    refetch: getSegmentVacanciesCount,
  } = useQuery(
    ["segmentVacanciesCount", props?.feedId, segment?.segmentGroup],
    () =>
      getVacanciesCountForSegment(
        props?.feedId,
        segment?.segmentGroup,
        completeConditions,
        props.feedDays === null || props.feedDays === "" ? "" : props.feedDays,
      ),
    {
      enabled: false,
      onSuccess(data: any) {
        setVacanciesCount(data?.data?.vacancyCount);
      },
    },
  );
  const params = useParams();
  const clientId = params.id;

  useQuery("segmentFilters", () => getSegmentFilters(clientId), {
    onSuccess(data) {
      setAvailableVariables(data?.data);
    },
  });

  useEffect(() => {
    const filteredConditions: any = [];
    segmentConditions?.forEach((condition: any) => {
      if (
        isValidInput(condition?.conditionName) &&
        isValidInput(condition?.condition) &&
        isValidInput(condition?.conditionContent)
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { conditionList, conditionContentList, ...rest } = condition;
        filteredConditions?.push(rest);
      }
    });
    setCompleteConditions(filteredConditions);
  }, [segmentConditions]);

  useEffect(() => {
    if (completeConditions?.length && props?.feedId) {
      getSegmentVacanciesCount();
    }
  }, [completeConditions, props?.feedId, segment?.segmentGroup]);

  useEffect(() => {
    if (CreateSegmentRes) {
      dispatch(
        openSnackbar({
          message: "Segment created successfully",
          type: "success",
        }),
      );
      setOpenSegmentBuilder(false);
      ResetCreateSegment();
      queryClient.refetchQueries("segments");
    }
  }, [CreateSegmentRes]);

  useEffect(() => {
    if (CreateSegmentError) {
      dispatch(
        openSnackbar({
          message: "Error while creating the segment",
          type: "error",
        }),
      );
    }
  }, [CreateSegmentError]);

  useEffect(() => {
    onDataFilledChange(false);
  }, [openSegmentBuilder]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSegment({
      ...segment,
      [e.target.name]: e.target.value,
    });
    onDataFilledChange(true);
  };

  const handleConditionChange = (key: string, value: any, index: number) => {
    const conditions = [...segmentConditions];
    conditions[index][key] = value;
    setSegmentConditions(conditions);
  };

  const handleCreateSegment = async () => {
    const { valid, message } = validateSegmentData();

    if (!valid) {
      displayErrorSnackbar(message);
      return;
    }

    createNewSegment({
      ...segment,
      segmentConditions: completeConditions,
    });
  };

  const validateSegmentData = () => {
    if (!segment?.name?.trim()) {
      return {
        valid: false,
        message: "Segment name is required",
      };
    }

    for (const segmentCondition of segmentConditions) {
      const { conditionName, condition, conditionContent } = segmentCondition;
      if (
        !conditionName?.trim() ||
        !condition?.trim() ||
        !conditionContent?.trim()
      ) {
        return { valid: false, message: "Missing condition variables" };
      }
    }

    return { valid: true, message: "" };
  };

  const handleRefresh = () => {
    if (completeConditions?.length && props?.feedId) {
      getSegmentVacanciesCount();
    }
  };

  return (
    <>
      {!openSegmentBuilder ? (
        <button
          className={styles.buildSegmentBtn}
          onClick={() => setOpenSegmentBuilder(true)}
        >
          {" "}
          <svg
            width={"20px"}
            height={"20px"}
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16,29A13,13,0,1,1,29,16,13,13,0,0,1,16,29ZM16,5A11,11,0,1,0,27,16,11,11,0,0,0,16,5Z" />
            <path d="M16,23a1,1,0,0,1-1-1V10a1,1,0,0,1,2,0V22A1,1,0,0,1,16,23Z" />
            <path d="M22,17H10a1,1,0,0,1,0-2H22a1,1,0,0,1,0,2Z" />
          </svg>
          <span>{t("buildNewSegment")}</span>
        </button>
      ) : (
        <div className="parent border-solid border-2 mb-5  w-full">
          <div className={`${styles.editorWrap} border-solid border-b-2`}>
            <span className={styles.label}>{t("segmentName")} *</span>
            <input
              name={"name"}
              type={"text"}
              className="w-[282px] border-2 rounded-[5px] h-10 "
              value={segment?.name}
              onChange={handleInputChange}
            />
          </div>

          <div className={`${styles.inputRow} ${styles.conditionGroupWrap}`}>
            <div className="flex items-center gap-2">
              <span className={styles.conditionGroupText}>
                {t("segmentWillFollow")}
              </span>
              <Select
                styles={{
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "grey",
                    borderRadius: "14px",
                    minWidth: "100px",
                    padding: "2.5px",
                  }),
                }}
                defaultValue={{ value: "Any", label: "Any" }}
                value={{
                  value: segment?.segmentGroup,
                  label: segment?.segmentGroup,
                }}
                options={[
                  { value: "Any", label: "Any" },
                  { value: "All", label: "All" },
                ]}
                onChange={(value: any) => {
                  setSegment({
                    ...segment,
                    segmentGroup: value?.value,
                  });
                }}
              />
              <span>{t("followingConditions")}</span>
            </div>
            {/* {segmentVacanciesCountSpinner || isFetching ? ( */}
            {/* <Spinner size={"24px"} /> */}
            {/* ) : ( */}
            <div className={styles.createSegmentVacancies}>
              <b>{(!segmentVacanciesCountError && vacanciesCount) || 0}</b>{" "}
              Vacancies in selection
              <svg
                viewBox="0 0 30.50039 30.5"
                width={"15px"}
                height={"15px"}
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleRefresh}
                className={`${segmentVacanciesCountSpinner || (isFetching && " animate-spin cursor-not-allowed")} `}
              >
                <path d="M3.2312,10.5H9.25a1.25,1.25,0,0,0,0-2.5H5.36743A13.20678,13.20678,0,0,1,16.415,2.5a12.75654,12.75654,0,0,1,11.68945,7.65039,1.25027,1.25027,0,0,0,2.291-1.00195A15.25661,15.25661,0,0,0,16.415,0,15.47292,15.47292,0,0,0,2.5,7.93555V1.25a1.25,1.25,0,0,0-2.5,0v8A1.25085,1.25085,0,0,0,1.25,10.5Z" />
                <path d="M29.25,20h-8a1.25,1.25,0,0,0,0,2.5h3.94965A11.00927,11.00927,0,0,1,15.25,28,12.75654,12.75654,0,0,1,3.56055,20.34961a1.25027,1.25027,0,0,0-2.291,1.002A15.25661,15.25661,0,0,0,15.25,30.5,13.625,13.625,0,0,0,28,22.58423V29.25a1.25,1.25,0,0,0,2.5,0v-8A1.25022,1.25022,0,0,0,29.25,20Z" />
              </svg>
            </div>
            {/* )} */}
          </div>

          <SegmentConditions
            availableVariables={availableVariables}
            getSegmentOptionsByFilter={getSegmentOptionsByFilter}
            handleConditionChange={handleConditionChange}
            segmentConditions={segmentConditions}
            setSegmentConditions={setSegmentConditions}
            styles={styles}
            propsData={props}
          />
          <button
            onClick={() => {
              const conditions = [...segmentConditions];
              setSegmentConditions([
                ...conditions,
                {
                  conditionName: "",
                  condition: "",
                  conditionContent: "",
                },
              ]);
            }}
            className={styles.addBtnWrap}
          >
            <span className="text-[#245ED2] font-plus-jakarta text-xs font-semibold ">
              +
            </span>
            <span className="text-[#245ED2] font-plus-jakarta text-xs font-bold">
              {t("addNewVariable")}
            </span>
          </button>

          <div className={styles.stepFooterWrap}>
            <div className={styles.checkboxWrap}>
              <input
                className={styles.reusableInput}
                type={"checkbox"}
                name={"status"}
                checked={segment.status}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSegment({ ...segment, status: e.target.checked })
                }
              />
              <label>Create reusable segment</label>
            </div>
            <div className={styles.stepFooter}>
              <button
                className="rounded-sm border bg-[#245ed247]  text-[#245ED2] font-medium text-sm shadow-sm py-2 px-8"
                onClick={handleCreateSegment}
              >
                {CreatingSegment ? <Spinner size={"24px"} /> : t("save")}
              </button>
              <button
                onClick={() => {
                  setOpenSegmentBuilder(false);
                  onDataFilledChange(false);
                }}
                className="rounded-sm border border-[#172029] px-8 py-2 text-[#172029] font-medium text-sm shadow-sm"
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CreateSegment;
