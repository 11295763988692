import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";

import { Icons } from "../../assets/icons";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../services/common";
import {
  getAllClientJobBoard,
  JOBBOARD_COLUMNS,
} from "../../services/jobBoard";
import { syncIndeedVacancies } from "../../services/vacancies";
import Breadcrumb from "../Breadcrumb";
import { Button } from "../Button/Button";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Spinner from "../Spinner/Spinner";
import TableNew from "../Table/Table";
import usePagination from "../UsePagination/UsePagination";

const ClientJobBoards = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  if (id === undefined) throw Error("Invalid contact");
  const clientId = +id;
  const [isOpen, setIsOpen] = useState<null | "indeed">();
  const { data, isLoading, isError } = useQuery(
    ["getClientJobBoard", clientId],
    () => getAllClientJobBoard(clientId),
    {},
  );

  useEffect(() => {
    if (isError) {
      displayErrorSnackbar("Error loading jobboard data");
    }
  }, [isError]);

  const recordsPerPage = 20;
  const {
    records,
    totPages,
    numbers,
    currentPage,
    prePage,
    nextPage,
    pageChange,
    lastIndex,
    firstIndex,
  } = usePagination(data?.data, recordsPerPage);

  const { mutate: syncIndeedVacanciesMutate, isLoading: syncIndeedLoader } =
    useMutation(() => syncIndeedVacancies(clientId), {
      onError: (error: any) => {
        displayErrorSnackbar(error.response?.data.errors);
      },
      onSuccess: (data) => {
        displaySuccessSnackbar(`Success: ${data.data}`);
      },
    });

  return (
    <section className="w-full">
      <div className=" px-16 pb-4 pt-6 w-full">
        <Breadcrumb
          navigation={[
            { text: "Client Dashboard", url: `/client/dashboard/${clientId}` },
          ]}
          currentPage={{
            text: "Client JobBoards",
            url: `/client/${clientId}/client-jobboards`,
          }}
        />
        <div className=" flex justify-between items-center">
          <h3 className="my-[30px] text-2xl font-bold text-black">
            {t("Client JobBoards")}
          </h3>
          <Button
            onClick={() => setIsOpen("indeed")}
            disabled={syncIndeedLoader}
            loading={syncIndeedLoader}
            color="tertiary"
            prefixIcon={<Icons.IndeedIcon size={22} />}
          >
            Sync Indeed
          </Button>
        </div>

        {isLoading ? (
          <Spinner size={"50px"} />
        ) : (
          data?.data && (
            <TableNew
              columns={JOBBOARD_COLUMNS}
              data={records}
              link={true}
              onLinkClick={(item: any) => {
                window.open(item?.feedUrl, "_blank", "noopener,noreferrer");
              }}
              linkTooltip={"Outgoing Feed"}
              order={true}
            />
          )
        )}

        {!isLoading && (
          <div className=" flex flex-col-reverse sm:flex-row justify-between mb-3 items-center w-full mt-4">
            <span className=" text-[#404040] text-sm font-medium">
              Showing {firstIndex === 0 ? firstIndex + 1 : firstIndex} to{" "}
              {lastIndex > data?.data?.length ? data?.data?.length : lastIndex}{" "}
              of {data?.data?.length} results
            </span>
            <nav className=" bg-white">
              <ul className="flex">
                <li
                  className={
                    "py-2 px-4 border border-[#D4D4D4] rounded-l-md cursor-pointer flex justify-center items-center"
                  }
                >
                  <button onClick={prePage}>
                    <IoIosArrowBack />
                  </button>
                </li>
                {numbers
                  .map((item, index) => {
                    if (
                      (item >= currentPage - 2 && item <= currentPage + 2) ||
                      item === 1 ||
                      item === totPages
                    ) {
                      return (
                        <li key={item}>
                          <button
                            className={`${
                              item === currentPage
                                ? "py-2 px-4 border border-[#CD335B] text-[#CD335B] bg-[#ff335b14] cursor-pointer"
                                : "py-2 px-4 border border-[#D4D4D4] cursor-pointer "
                            }`}
                            onClick={() => {
                              pageChange(item);
                            }}
                          >
                            {item}
                          </button>
                        </li>
                      );
                    } else if (
                      (index === 0 && item > 2) ||
                      (index === numbers.length - 1 && item < totPages - 1)
                    ) {
                      return (
                        <li
                          key={item}
                          className="py-2 px-4 border border-[#D4D4D4]"
                        >
                          ...
                        </li>
                      );
                    }
                    return null;
                  })
                  .slice(1)}

                <li className="py-1 border px-3 border-[#D4D4D4] rounded-r-md cursor-pointer flex justify-center items-center">
                  <button onClick={nextPage}>
                    <IoIosArrowForward />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>

      <ConfirmationModal
        isOpen={isOpen === "indeed"}
        heading={"Sync Indeed Vacancies"}
        onClose={() => setIsOpen(null)}
        handleClick={() => {
          syncIndeedVacanciesMutate();
          setIsOpen(null);
        }}
        title="Are you sure you want to continue?"
        modalType="info"
      />
    </section>
  );
};

export default ClientJobBoards;
