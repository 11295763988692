import React, { ReactNode } from "react";

import Sidebar from "./Sidebar/Sidebar";

type SideBarLayoutProps = {
  children: ReactNode;
};

const SideBarLayout = (props: SideBarLayoutProps) => {
  return (
    <div className="  h-full flex">
      <Sidebar />
      <div className=" w-full sm:pl-60 mt-20">{props.children}</div>
    </div>
  );
};

export default SideBarLayout;
