// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CurrencyInput_currencyInput__BSYFF {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 14px;
  padding: 8px 12px;
  min-height: 42px;
  width: 100px;
}

.CurrencyInput_currencyInput__BSYFF:focus-within {
  border: 1.5px solid #245ed23d;
  box-shadow: inset 0 0 0 0.5px #245ed23d;
}

.CurrencyInput_inputField__VPbmn {
  background: none;
  border: none;
  outline: none;
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
`, "",{"version":3,"sources":["webpack://./src/components/CurrencyInput/CurrencyInput.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,uCAAuC;AACzC;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,WAAW;AACb;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B","sourcesContent":[".currencyInput {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  border-radius: 14px;\n  padding: 8px 12px;\n  min-height: 42px;\n  width: 100px;\n}\n\n.currencyInput:focus-within {\n  border: 1.5px solid #245ed23d;\n  box-shadow: inset 0 0 0 0.5px #245ed23d;\n}\n\n.inputField {\n  background: none;\n  border: none;\n  outline: none;\n  width: 100%;\n}\n\ninput[type=\"number\"]::-webkit-inner-spin-button,\ninput[type=\"number\"]::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type=\"number\"] {\n  -moz-appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"currencyInput": `CurrencyInput_currencyInput__BSYFF`,
	"inputField": `CurrencyInput_inputField__VPbmn`
};
export default ___CSS_LOADER_EXPORT___;
