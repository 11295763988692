import api from "./api";

export const CAMPAIGN_COLUMNS = [
  {
    label: "Campaign",
    translationKey: "campaign",
    accessor: (row: any) => row?.name,
  },
  { label: "Type", translationKey: "type", accessor: (row: any) => row?.type },

  {
    label: "Start-date",
    translationKey: "startDate",
    accessor: (row: any) => row?.date,
    type: "DateTime",
  },
  {
    label: "End-date",
    translationKey: "endDate",
    accessor: (row: any) => row?.endDate,
  },
  {
    label: "Set KPI",
    translationKey: "setKpi",
    accessor: (row: any) => row?.setKpi,
  },
  {
    label: "Actual KPI",
    translationKey: "actualKpi",
    accessor: (row: any) => row?.actualKpi,
  },
  {
    label: "Vacancies Included",
    translationKey: "vacanciesIncluded",
    accessor: (row: any) => row?.vacanciesCount,
    accessKey: "vacanciesCount",
  },
];

export const NEW_CAMPAIGN_COLUMNS = [
  {
    label: "Campaign",
    translationKey: "campaign",
    accessor: (row: any) => row?.name,
  },
  { label: "Type", translationKey: "type", accessor: (row: any) => row?.type },
  {
    label: "Start-date",
    translationKey: "startDate",
    accessor: (row: any) => row?.date,
    type: "DateTime",
  },
  {
    label: "End-date",
    translationKey: "endDate",
    accessor: (row: any) => row?.endDate,
  },

  {
    label: "Vacancies Included",
    translationKey: "vacanciesIncluded",
    accessor: (row: any) => row?.vacanciesCount,
    accessKey: "vacanciesCount",
  },
];

export const getClientCampaigns = async (id: any, filter: any) => {
  const from = filter?.from ? `&startDate=${filter?.from}` : "";
  const to = filter?.to ? `&endDate=${filter?.to}` : "";
  const response: any = await api.get(
    `Campaigns/GetByClientId?ClientId=${id}${from}${to}`,
  );
  return response;
};

export const getCampaign = async (id: string) => {
  const response: any = api.get(`Campaigns/${id}`);
  return response;
};

export const createCampaign = async (data: any) => {
  const response: any = await api.post("Campaigns", data);
  return response;
};

export const deleteCampaign = async (id: any) => {
  const response: any = await api.delete(`Campaigns/${id}`, {});
  return response;
};

export const getVacanciesCountByClientAndFeedDays = async (
  feedId: string | number,
  feedDays: number,
  campaignID?: number | string,
) => {
  const response = await api.get(
    `Campaigns/GetCountByClientFeedId?clientFeedId=${feedId}&Days=${feedDays}&campaignId=${campaignID}`,
  );
  return response;
};

export const getVacanciesCountForSegment = async (
  feedId: string | number,
  segmentGroup: string,
  data: any,
  feedDays: number,
) => {
  const response = await api.post(
    `Campaigns/GetVacanciesCountOnBuildSegment?FeedId=${feedId}&SegmentGroup=${segmentGroup}&Days=${feedDays}`,
    data,
  );
  return response;
};

export const updateCampaign = async (data: any, id: string) => {
  const response = await api.put(`Campaigns?id=${id}`, data);
  return response;
};

export const switchCampaignsPriority = async (
  FromCampaignId: number,
  ToCampaignId: number,
) => {
  const response = await api.put(
    `Campaigns/SwitchPriority?FromCampaignId=${FromCampaignId}&ToCampaignId=${ToCampaignId}`,
    {},
  );
  return response;
};

export const addCampaignJobBoards = async (data: any) => {
  const response = await api.post("Campaigns/AddCampaignJobboard", data);
  return response;
};

export const getVacanciesListForSegment = async (
  pageNumber: any,
  pageSize: any,
  feedId: string | number,
  segmentGroup: string,
  feedDays: number,
  data: any,
) => {
  const response = await api.post(
    `Campaigns/GetVacanciesListByBuildSegment?PageNumber=${pageNumber}&PageSize=${pageSize}&FeedId=${feedId}&SegmentGroup=${segmentGroup}&Days=${feedDays}`,
    data,
  );
  return response;
};

export const updatePlayPause = async (id: any) => {
  const response = await api.put(`Campaigns/PausePlayCampaign?id=${id}`, {});
  return response;
};
