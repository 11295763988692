import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import Select from "react-select";

import Page from "../../layouts/Page/Page";
import { openSnackbar } from "../../redux/snackbar";
import { createUser, getRoles } from "../../services/auth";
import {
  invalidInputMessage,
  isValidEmail,
  isValidInput,
  validatePassword,
} from "../../services/common";

import styles from "./createUser.module.css";

function CreateUser() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const iconSize = 30;
  const formInitialState = {
    email: "",
    password: "",
    userRole: "",
  };
  const [userRolesOptions, setUserRolesOptions] = useState([]);
  const [formData, setFormData] = useState<any>(formInitialState);

  const [type, setType] = useState<"password" | "text">("password");

  const handleTogglePassword = () => {
    if (type === "password") setType("text");
    else setType("password");
  };

  const handleOnChange = (e: any) => {
    const key = e.target.name;
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const { mutate, isError, isLoading, data, error, reset } = useMutation(
    (data: any) => createUser(data),
  );

  useQuery(["roles"], () => getRoles(), {
    onSuccess(data) {
      setUserRolesOptions(data?.data);
    },
  });

  useEffect(() => {
    if (!isLoading && isError) {
      const registerError: any = error;
      dispatch(
        openSnackbar({
          message: registerError?.response?.data,
          type: "error",
        }),
      );
      reset();
    }
  }, [error]);

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(
        openSnackbar({
          message: "User Created Successfully!",
        }),
      );
      reset();
      setFormData({
        email: "",
        password: "",
        userRole: "User",
      });
    }
  }, [data]);

  const validateForm = () => {
    const { email, password, userRole } = formData;
    if (
      !isValidInput(email) ||
      !isValidInput(password) ||
      !isValidInput(userRole)
    ) {
      return invalidInputMessage("Please enter data in all fields to proceed");
    }

    if (!isValidEmail(email)) {
      return invalidInputMessage("Please provide a valid email");
    }

    if (email.length > 50) {
      return invalidInputMessage(
        "Email length must not be greater then 50 characters",
      );
    }
    if (!validatePassword(password)) {
      return invalidInputMessage(
        "Password must have at least 6 characters, at least one non-alphanumeric character and at least one digit",
      );
    }

    return { valid: true, message: null };
  };

  const onRegisterUser = (e: any) => {
    e.preventDefault();
    const { valid, message } = validateForm();

    if (!valid) {
      dispatch(
        openSnackbar({
          message,
          type: "error",
        }),
      );
      return;
    }

    mutate(formData);
  };
  const handleUserRoleChange = (selectedOption: any) => {
    setFormData({
      ...formData,
      userRole: selectedOption?.label,
    });
  };

  return (
    <Page>
      <div className={styles.authCardContainer}>
        <div className={styles.authCard}>
          <h2 className="text-3xl font-bold tracking-wide flex justify-center">
            {t("registerUser", "Register User")}{" "}
          </h2>

          <div
            className={`${styles.inputContainer}  rounded border-2 bg-white my-4`}
          >
            <div className={styles.inputIcon}>
              <svg
                width={iconSize}
                height={iconSize}
                fill={"grey"}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M28,13a1,1,0,0,0-1,1v8a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V14a1,1,0,0,0-2,0v8a3,3,0,0,0,.88,2.12A3,3,0,0,0,6,25H26a3,3,0,0,0,2.12-.88A3,3,0,0,0,29,22V14A1,1,0,0,0,28,13Z" />
                <path d="M15.4,18.8a1,1,0,0,0,1.2,0L28.41,9.94a1,1,0,0,0,.3-1.23,3.06,3.06,0,0,0-.59-.83A3,3,0,0,0,26,7H6a3,3,0,0,0-2.12.88,3.06,3.06,0,0,0-.59.83,1,1,0,0,0,.3,1.23ZM6,9H26a.9.9,0,0,1,.28,0L16,16.75,5.72,9A.9.9,0,0,1,6,9Z" />
              </svg>
            </div>
            <input
              className={styles.input}
              type="email"
              name="email"
              placeholder={`${t("email", "Email")}`}
              value={formData?.email}
              onChange={handleOnChange}
            />
          </div>
          <div
            className={`${styles.inputContainer}  rounded border-2 bg-white my-4`}
          >
            <div className={styles.inputIcon}>
              <svg
                width={iconSize}
                height={iconSize}
                fill={"grey"}
                viewBox="0 0 1262 1710.258"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1196.495,713.258H1090V459.592c0-253.285-205.802-459.35-459.001-459.35C377.799,0.242,172,206.442,172,459.892v253.366    H66.686C30.195,713.258,0,742.241,0,778.731v766.42c0,91.079,74.712,165.106,165.792,165.106h931.597    c91.08,0,164.611-74.027,164.611-165.106v-766.42C1262,742.241,1232.985,713.258,1196.495,713.258z M304,459.892    c0-180.588,146.664-327.508,326.999-327.508C811.335,132.384,958,279.168,958,459.592v253.666H304V459.892z M1130,1545.151    c0,18.218-14.395,33.106-32.611,33.106H165.792c-18.216,0-33.792-14.889-33.792-33.106V845.258h998V1545.151z" />
                <path d="M631,1409.707c36.491,0,66-29.58,66-66.071v-237.854c0-36.49-29.51-66.07-66-66.07c-36.49,0-66,29.58-66,66.07v237.854    C565,1380.127,594.509,1409.707,631,1409.707z" />
              </svg>
            </div>

            <input
              className={styles.input}
              type={type}
              name="password"
              placeholder={`${t("password", "Password")}`}
              value={formData?.password}
              onChange={handleOnChange}
            />
            <div className={styles.passwordIcon}>
              <button
                style={{ border: "none" }}
                className="p-2"
                onClick={handleTogglePassword}
                type="button"
              >
                {type === "password" ? (
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-eye-slash-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-eye-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                  </svg>
                )}
              </button>
            </div>
          </div>

          <div className={styles.dropdownContainer}>
            <Select
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: "5px",
                  minWidth: "282px",
                  padding: "2.5px",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#CDCDCD",
                  primary: "#CDCDCD",
                },
              })}
              options={userRolesOptions?.map((item: any) => {
                return {
                  value: item?.id,
                  label: item?.name,
                };
              })}
              onChange={handleUserRoleChange}
            />
          </div>

          <button
            className={"w-full bg-[#245ED2] rounded text-white py-2"}
            onClick={onRegisterUser}
            disabled={isLoading}
          >
            {t("registerUser", "Register User")}
          </button>
        </div>
      </div>
    </Page>
  );
}

export default CreateUser;
