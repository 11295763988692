import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { openSnackbar } from "../../../redux/snackbar";
import { routeNames } from "../../../router/routeNames";
import {
  deleteJobBoard,
  getAllJobBoards,
  JOBBOARD_COLUMNS,
} from "../../../services/jobBoard";
import Spinner from "../../Spinner/Spinner";
import Table from "../../Table/Table";
import usePagination from "../../UsePagination/UsePagination";
import WarningModalDesign from "../../WarningModalDesign/WarningModalDesign";

import styles from "./JobBoardsTable.module.css";

function JobBoardsTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<any>({
    id: "",
  });

  const { data, refetch, isLoading } = useQuery(["jobBoards"], () =>
    getAllJobBoards(),
  );

  const {
    mutate: DeleteJobBoard,
    data: DeleteJobBoardResp,
    reset: ResetDeleteBoardData,
    isLoading: LoadingJobBoards,
  } = useMutation((id) => deleteJobBoard(id), {
    onSuccess() {
      setOpenModal(false);
      setDeleteItem({ id: "" });
    },
  });

  useEffect(() => {
    if (DeleteJobBoardResp?.data) {
      dispatch(
        openSnackbar({
          message: "JobBoard Deleted",
          type: "success",
        }),
      );
      refetch();
      ResetDeleteBoardData();
    }
  }, [DeleteJobBoardResp]);

  const recordsPerPage = 20;
  const {
    records,
    totPages,
    numbers,
    currentPage,
    prePage,
    nextPage,
    pageChange,
    lastIndex,
    firstIndex,
  } = usePagination(data?.data, recordsPerPage);

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between sm:mt-16">
        <h2 className="font-bold text-2xl text-black">{t("jobBoards")}</h2>
        <div className=" flex sm:justify-center">
          <button
            className="bg-[#DA4F66] rounded-sm text-white w-52 py-2 text-sm
            flex justify-center gap-2"
            onClick={() => navigate(routeNames.CREATE_JOBBOARDS)}
          >
            <svg
              width={21}
              height={20}
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0341 4.16669L10.1325 16.6667"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.83331 10.3676L16.3333 10.4659"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Create JobBoards
          </button>
        </div>
      </div>
      <div className={styles.tableWrapper}>
        {isLoading ? (
          <div className=" flex w-full justify-center items-center min-h-[50vh]">
            <Spinner size={"50px"} />
          </div>
        ) : (
          data?.data && (
            <Table
              columns={JOBBOARD_COLUMNS}
              data={records}
              link={false}
              deleteable={false}
              onDelete={(item: any) => {
                setOpenModal(true);
                setDeleteItem(item);
              }}
              deleteTooltip={"Delete JobBoard"}
              onLinkClick={(item: any) => {
                window.open(item?.feedUrl, "_blank", "noopener,noreferrer");
              }}
              linkTooltip={"Outgoing Feed"}
              onRowClick={(item: any) =>
                navigate(
                  `${routeNames.JOBBOARDS}/${item.id}/jobboards-campaigns`,
                )
              }
              RowisClickable={true}
              order={true}
            />
          )
        )}

        {openModal && (
          <WarningModalDesign
            heading={`Delete ${deleteItem?.name}`}
            title={"Are you sure you want to delete this jobboard?"}
            isOpen={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
            deleteItem={() => {
              if (deleteItem) {
                DeleteJobBoard(deleteItem?.id);
              }
            }}
            isLoading={LoadingJobBoards}
          />
        )}
      </div>
      {!isLoading && (
        <div className=" flex flex-col-reverse sm:flex-row justify-between  mb-3 items-center mt-4">
          <span className=" text-[#404040] text-sm font-medium">
            Showing {firstIndex === 0 ? firstIndex + 1 : firstIndex} to{" "}
            {lastIndex > data.data?.length ? data.data?.length : lastIndex} of{" "}
            {data.data?.length} results
          </span>
          <nav className=" bg-white">
            <ul className="flex">
              <li
                className={
                  "py-2 px-4 border border-[#D4D4D4] rounded-l-md cursor-pointer flex justify-center items-center"
                }
              >
                <button onClick={prePage}>
                  <IoIosArrowBack />
                </button>
              </li>
              {numbers
                .map((item, index) => {
                  if (
                    (item >= currentPage - 2 && item <= currentPage + 2) ||
                    item === 1 ||
                    item === totPages
                  ) {
                    return (
                      <li key={`${"" + item}`}>
                        <button
                          className={`${
                            item === currentPage
                              ? "py-2 px-4 border border-[#CD335B] text-[#CD335B] bg-[#ff335b14] cursor-pointer"
                              : "py-2 px-4 border border-[#D4D4D4] cursor-pointer "
                          }`}
                          onClick={() => {
                            pageChange(item);
                          }}
                        >
                          {item}
                        </button>
                      </li>
                    );
                  } else if (
                    (index === 0 && item > 2) ||
                    (index === numbers.length - 1 && item < totPages - 1)
                  ) {
                    return (
                      <li
                        key={item}
                        className="py-2 px-4 border border-[#D4D4D4]"
                      >
                        ...
                      </li>
                    );
                  }
                  return null;
                })
                .slice(1)}

              <li className="py-1 border px-3 border-[#D4D4D4] rounded-r-md cursor-pointer flex justify-center items-center">
                <button onClick={nextPage}>
                  <IoIosArrowForward />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
}

export default JobBoardsTable;
