import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeSnackbar } from "../../redux/snackbar";

import styles from "./Snackbar.module.css";

function Snackbar() {
  const snackbar = useSelector((state: any) => state.snackbar);
  const dispatch = useDispatch();

  useEffect(() => {
    if (snackbar?.isOpen) {
      onCloseSnackbar();
    }
  }, [snackbar]);

  const onCloseSnackbar = () => {
    const messageLength = snackbar?.message?.length;
    const duration = messageLength > 200 ? messageLength * 25 : 3000;
    setTimeout(() => {
      dispatch(closeSnackbar());
    }, duration);
  };

  return snackbar?.isOpen ? (
    <div className={`${styles.snackbar} ${styles[snackbar?.type]}`}>
      <span>{snackbar?.message}</span>
    </div>
  ) : (
    <></>
  );
}

export default Snackbar;
