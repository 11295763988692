import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCircleInfo } from "react-icons/fa6";

import { getPointValue } from "../../services/common";
import ClientJobBoardsModal from "../ClientJobBoardsModal/ClientJobBoardsModal";
import CreateCampaign from "../CreateCampaign/CreateCampaign";
import CurrencyDisplay from "../CurrencyDisplay/CurrencyDisplay";
import CurrrencyInput from "../CurrencyInput/CurrrencyInput";
import Spinner from "../Spinner/Spinner";
import Tooltip from "../Tooltip/Tooltip";

import styles from "./ActiveCampaign.module.css";

type ActiveCampaignsBudgetProps = {
  year: number;
  month: number;
  clientId: any;
  budgetPlanningId: number;
  totalFc: number;
  setParentData?: any;
  setParentFCError?: any;
  sendDataToParent?: any;
  setParentSPError: any;
  sendPlState?: any;
  sendSpState?: any;
  handleUpdateClientBudget?: any;
  isParentTyped?: any;
  setIsParentTyped?: any;
  setSpWarning?: any;
  setSpWarningModalCondition?: any;
  callPlannedJobBoard?: boolean;
  activeCampaigns: any;
  setActiveCampaigns: any;
  isLoading: boolean;
  activeButton: boolean | string;
};

function ActiveCampaign(props: Readonly<ActiveCampaignsBudgetProps>) {
  const { t } = useTranslation();
  const {
    year,
    month,
    clientId,
    budgetPlanningId,
    totalFc,
    setParentFCError,
    setParentSPError,
    sendPlState,
    sendSpState,
    handleUpdateClientBudget,
    isParentTyped,
    setIsParentTyped,
    setSpWarning,
    setSpWarningModalCondition,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callPlannedJobBoard = false,
    setActiveCampaigns,
    activeCampaigns,
    isLoading,
  } = props;

  const [openJobBoardsModal, setOpenJobBoardsModal] = useState<boolean>(false);
  const [fcLimitError, setFcLimitError] = useState<boolean>(false);
  const [maxFc, setMaxFc] = useState<number>(0);
  const [isTyped, setIsTyped] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [sumRow, setSumRow] = useState<number[]>([]);
  const [campaignsBudgetSum, setCampaignsBudgetSum] = useState<number>(0);
  const CampaignFCWarningMessage =
    "Sum of this row should be Equal to and less than the FC Budget for this month.";

  const getSumOfArray = () => {
    const sumArray: number[] = [];
    for (const board of activeCampaigns) {
      sumArray[0] = sumArray[0]
        ? sumArray[0] + parseFloat(board?.fc || 0)
        : parseFloat(board?.fc || 0);
    }

    return sumArray;
  };

  useEffect(() => {
    let sum = 0;
    if (activeCampaigns) {
      activeCampaigns?.map((item: any) => {
        item?.campaigns?.forEach(
          (element: any) => (sum += Number(element?.budget)),
        );
      });
    }
    setCampaignsBudgetSum(sum);
  }, [activeCampaigns]);

  useEffect(() => {
    let sum = 0;
    if (sumRow) {
      sumRow.forEach((item: number) => (sum += item));
    }
  }, [sumRow]);

  useEffect(() => {
    if (activeCampaigns?.length === 0) {
      return;
    }

    // Interate over all the jobBoards to get FC sum
    const sumArray: number[] = getSumOfArray();

    // The number of campaigns in each jobBoard would be same as the first one
    const campaignsLength = activeCampaigns?.[0].campaigns?.length || 0;

    for (let index = 0; index < campaignsLength; index++) {
      let campaignBudgetSum: number | null = 0;
      let campaignBudgetSPSum: number | null = 0;

      // From each jobBoard, get the campaign budgets from current index to sum up row values
      activeCampaigns?.forEach((board: any) => {
        const currentBudget = parseFloat(
          board?.campaigns?.[index]?.budget || 0,
        );
        const currentBudgetSP = parseFloat(
          board?.campaigns?.[index]?.budgetSP || 0,
        );
        campaignBudgetSum = campaignBudgetSum
          ? campaignBudgetSum + currentBudget
          : currentBudget;
        campaignBudgetSPSum = campaignBudgetSPSum
          ? campaignBudgetSPSum + currentBudgetSP
          : currentBudgetSP;
      });

      sumArray.push(campaignBudgetSum, campaignBudgetSPSum);
    }

    setSumRow(sumArray);
  }, [activeCampaigns]);

  useEffect(() => {
    setParentSPError(isTyped);
  }, [isTyped]);

  useEffect(() => {
    setParentFCError(fcLimitError);
  }, [fcLimitError]);

  useEffect(() => {
    const sum = activeCampaigns?.reduce((accumulator: number, obj: any) => {
      const current = obj?.["fc"] ? parseFloat(obj?.["fc"]) : 0;

      if (accumulator + current) return accumulator + current;
      return 0;
    }, 0);
    setFcLimitError(sum > totalFc);
    setMaxFc(totalFc - sum);
  }, [activeCampaigns, totalFc]);

  useEffect(() => {
    const SpSumRow = activeCampaigns?.reduce((sum: number, data: any) => {
      return (
        sum +
        data?.campaigns?.reduce((campaignSum: number, campaign: any) => {
          const current = parseFloat(campaign?.["budgetSP"]) || 0;
          if (campaignSum + current) return campaignSum + current;
          return 0;
        }, 0)
      );
    }, 0);
    sendSpState(SpSumRow);
  }, [activeCampaigns, sendSpState]);

  useEffect(() => {
    const PlSumRow = activeCampaigns?.reduce((sum: number, data: any) => {
      return (
        sum +
        data?.campaigns?.reduce((campaignSum: number, campaign: any) => {
          const current = parseFloat(campaign?.["budget"]) || 0;
          if (campaignSum + current) return campaignSum + current;
          return 0;
        }, 0)
      );
    }, 0);
    sendPlState(PlSumRow);
  }, [activeCampaigns, sendPlState]);

  const handleActiveCampaingsBoardValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    boardIndex: number,
  ) => {
    const data = [...activeCampaigns];
    data[boardIndex][`${e.target.name}`] = e.target?.value || 0;
    setActiveCampaigns(data);
    setIsParentTyped(true);
  };

  const handelCampaignBudgetChange = (
    value: string,
    boardIndex: number,
    campaignIndex: number,
  ) => {
    let valueToUpdate = value;
    if (valueToUpdate === undefined) {
      valueToUpdate = "";
    }
    const data = [...activeCampaigns];
    data[boardIndex]["campaigns"][campaignIndex]["budget"] = valueToUpdate;
    setActiveCampaigns(data);
    setIsParentTyped(true);
  };

  const handelCampaignBudgetChangeSP = (
    value: string,
    boardIndex: number,
    campaignIndex: number,
    jobboardName: string,
    campaignName: any,
    campaignBudget: any,
  ) => {
    const data = [...activeCampaigns];
    data[boardIndex]["campaigns"][campaignIndex]["budgetSP"] = value;
    setSpWarning({
      info: "",
      channel: jobboardName,
      campaign: campaignName,
    });
    setSpWarningModalCondition({
      budget: campaignBudget,
      spbudget: value,
    });
    setActiveCampaigns(data);
    setIsParentTyped(true);
  };

  if (isLoading) {
    return (
      <div className={styles.spinnerWrap}>
        <Spinner size={"50px"} />
      </div>
    );
  }

  return (
    <>
      <div className={`${styles.boardsBudgetContainer} `}>
        {activeCampaigns?.length ? (
          <>
            <div className=" flex border border-[#DEDEDE] rounded-sm w-fit">
              <div className=" flex flex-col">
                <div className=" text-[#636D73] bg-[#909ea729] py-3 px-6 text-xs font-semibold border border-[#EBEBEB] h-[50px] w-40 ">
                  Campaigns
                </div>
                <div className=" bg-white border border-[#EBEBEB] py-3 px-6 w-40 text-sm h-[50px] ">
                  FC
                </div>

                {activeCampaigns?.[0].campaigns?.map(
                  (data: any, index: number) => {
                    return (
                      <>
                        <div
                          key={`${"" + index}`}
                          className={` flex items-center border ${data.online ? "bg-white border-[#EBEBEB] " : " bg-gray-300"}  py-3 px-6 whitespace-nowrap w-40 text-sm h-[50px]  relative`}
                        >
                          {!data.online && (
                            <div className=" absolute left-1 group">
                              <FaCircleInfo />
                              <div className=" hidden group-hover:block bg-gray-600 absolute z-50 text-white px-3 py-2 rounded-md text-sm">
                                This Campaign is Delated/Archived
                              </div>
                            </div>
                          )}{" "}
                          {data?.campaignName}
                        </div>
                        <div
                          key={`${"" + index + 1}`}
                          className={`border ${data.online ? "bg-white border-[#EBEBEB] " : " bg-gray-300"}  py-3 px-6 w-40 text-sm h-[50px]`}
                        >
                          SP
                        </div>
                      </>
                    );
                  },
                )}
              </div>
              {activeCampaigns?.map((data: any, dataKey: number) => {
                const campaignsSum = data?.campaigns?.reduce(
                  (sum: number, campaign: any) => {
                    const current = parseFloat(campaign?.["budget"]);
                    if (sum + current) return sum + current;

                    return 0;
                  },
                  0,
                );
                return (
                  <div className=" flex flex-col" key={`${"" + dataKey}`}>
                    <div className="text-[#636D73] bg-[#909ea729] py-3 px-6 text-xs font-semibold border border-[#EBEBEB] h-[50px]                      ">
                      {data?.jobboardName}
                    </div>
                    <div className="  bg-white border border-[#EBEBEB] py-[3px] px-3 w-40">
                      <Tooltip
                        tooltipText={CampaignFCWarningMessage}
                        isToolTipEnabled={fcLimitError}
                      >
                        <CurrrencyInput
                          value={data?.fc}
                          name={"fc"}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleActiveCampaingsBoardValueChange(e, dataKey)
                          }
                          propStyles={{
                            border: fcLimitError ? "1.5px solid red" : "",
                            borderRadius: "5px",
                          }}
                          onBlur={isParentTyped && handleUpdateClientBudget}
                        />
                      </Tooltip>
                    </div>

                    {data?.campaigns?.map((campaign: any, index: number) => {
                      return (
                        <>
                          <div
                            className={` ${campaign.online ? "bg-white border-[#EBEBEB] " : " bg-gray-300 "}  border py-[3px] px-3 h-[50px] w-40`}
                          >
                            <Tooltip
                              tooltipText={"InActive campaign!"}
                              isToolTipEnabled={!campaign.online}
                            >
                              <CurrrencyInput
                                value={campaign?.budget}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  handelCampaignBudgetChange(
                                    e.target.value,
                                    dataKey,
                                    index,
                                  );
                                }}
                                propStyles={{
                                  border:
                                    getPointValue(campaignsSum) >
                                    getPointValue(data?.fc)
                                      ? "1.5px solid red"
                                      : "",
                                  borderRadius: "5px",
                                }}
                                onBlur={
                                  isParentTyped && handleUpdateClientBudget
                                }
                                disabled={!campaign.online}
                              />
                            </Tooltip>
                          </div>
                          <Tooltip
                            tooltipText={"InActive campaign!"}
                            isToolTipEnabled={!campaign.online}
                          >
                            <div
                              className={` border ${campaign.online ? "bg-white border-[#EBEBEB] " : " bg-gray-300"} py-[3px] px-3 h-[50px] w-40`}
                            >
                              <CurrrencyInput
                                value={campaign?.budgetSP}
                                name={"budgetSP"}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  handelCampaignBudgetChangeSP(
                                    e.target.value,
                                    dataKey,
                                    index,
                                    data?.jobboardName,
                                    campaign?.campaignName,
                                    campaign?.budget || 0,
                                  );
                                  setIsTyped(true);
                                }}
                                propStyles={{
                                  borderRadius: "5px",
                                }}
                                onBlur={
                                  isParentTyped && handleUpdateClientBudget
                                }
                                disabled={!campaign.online}
                              />
                            </div>
                          </Tooltip>
                        </>
                      );
                    })}
                  </div>
                );
              })}
              <button
                className=" bg-white border border-[#EBEBEB] border-r-0 py-3 px-6"
                hidden={maxFc <= 0}
                onClick={() => {
                  if (maxFc <= 0) return;
                  setOpenJobBoardsModal(true);
                }}
              >
                <svg
                  width={26}
                  height={25}
                  viewBox="0 0 26 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.979858"
                    y="0.5"
                    width="24.217"
                    height={24}
                    rx={12}
                    fill="#245ED2"
                    fillOpacity="0.12"
                  />
                  <path
                    d="M12.394 16.4998V13.1878H9.05206V11.7958H12.394V8.49976H13.7986V11.7958H17.1244V13.1878H13.7986V16.4998H12.394Z"
                    fill="#245ED2"
                  />
                </svg>
              </button>
              <div className=" flex flex-col">
                <div className=" text-[#636D73] bg-[#909ea729] py-3 px-6 text-xs font-semibold border border-[#EBEBEB] h-[50px] ">
                  Total
                </div>
                {sumRow?.map((item, index) => (
                  <div
                    key={`${"" + index}`}
                    className="bg-white border border-[#EBEBEB] py-3 px-3 w-40 h-[50px]"
                  >
                    <CurrencyDisplay value={item} />
                  </div>
                ))}
                <div className=" bg-white border border-[#EBEBEB] py-[3px] px-3 h-[50px] w-40">
                  <div className="flex flex-col-reverse items-center">
                    <CurrencyDisplay value={campaignsBudgetSum} />
                    <h2 className="text-xs">Campaigns Budget Sum</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex justify-center ">
              <button
                className=" bg-[#E4EBF9] flex gap-3 justify-center items-center px-5 py-2 rounded-xl my-3"
                onClick={() => setOpenModal(true)}
              >
                <svg
                  width={9}
                  height={9}
                  viewBox="0 0 9 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.05059 8.5V5.188H0.738594V3.796H4.05059V0.5H5.44259V3.796H8.7386V5.188H5.44259V8.5H4.05059Z"
                    fill="#245ED2"
                  />
                </svg>
                <span className=" text-[#245ED2] text-xs"> Add Campaign</span>
              </button>
            </div>
            <div className=" flex border border-[#DEDEDE] rounded-sm bg-[#909ea714] w-fit">
              <div className=" flex flex-col">
                <div className=" text-[#636D73]       text-xs font-semibold border border-[#EBEBEB]    w-40  h-[50px] px-6 py-3">
                  {t("PL")}
                </div>
                <div className=" text-[#636D73]       text-xs font-semibold border border-[#EBEBEB]    w-40  h-[50px] px-6 py-3">
                  {t("SP")}
                </div>
                <div className=" text-[#636D73]       text-xs font-semibold border border-[#EBEBEB]    w-40  h-[50px] px-6 py-3">
                  {t("Discount")}
                </div>
              </div>
              {activeCampaigns?.map((data: any, dataKey: number) => {
                const campaignsSum = data?.campaigns?.reduce(
                  (sum: number, campaign: any) => {
                    const current = parseFloat(campaign?.["budget"]) || 0;
                    return sum + current;
                  },
                  0,
                );

                const budgetSpSum = data?.campaigns?.reduce(
                  (sum: number, campaign: any) => {
                    const current = parseFloat(campaign?.["budgetSP"]) || 0;
                    return sum + current;
                  },
                  0,
                );

                return (
                  <div key={`${"" + dataKey}`} className=" flex flex-col">
                    <div className="border border-[#EBEBEB]    h-[50px] w-40 flex justify-center items-center">
                      <CurrencyDisplay
                        propStyles={{
                          fontSize: "12px",
                        }}
                        value={campaignsSum}
                      />
                    </div>
                    <div className="border border-[#EBEBEB]    h-[50px] w-40 flex justify-center items-center">
                      <CurrencyDisplay
                        propStyles={{
                          fontSize: "12px",
                        }}
                        value={budgetSpSum}
                      />
                    </div>
                    <div className="border border-[#EBEBEB]    h-[50px] w-40 flex justify-center items-center">
                      <CurrrencyInput
                        value={data?.discount}
                        name="discount"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleActiveCampaingsBoardValueChange(e, dataKey)
                        }
                        propStyles={{
                          fontSize: "12px",
                        }}
                        onBlur={isParentTyped && handleUpdateClientBudget}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className={styles.noDataContainer}>
            <label>No jobBoards budget found for active campaigns</label>
            <button
              className={`${styles.addNewJobBoardBtn} tooltip`}
              data-type={maxFc <= 0 ? "Insufficient budget" : "New JobBoard"}
              onClick={() => {
                if (maxFc <= 0) return;
                setOpenJobBoardsModal(true);
              }}
              // hidden={maxFc <= 0 || !canCreateJobboard
            >
              <svg
                className={`${maxFc <= 0 ? "disabled" : ""}`}
                width={"35px"}
                height={"35px"}
                id="Layer_1"
                version="1.1"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M384,265H264v119h-17V265   H128v-17h119V128h17v120h120V265z" />
                </g>
              </svg>
              Add New JobBoard
            </button>
          </div>
        )}
      </div>

      {openJobBoardsModal && (
        <ClientJobBoardsModal
          isOpen={openJobBoardsModal}
          clientId={clientId}
          selectedClientYear={year}
          selectedClientMonth={month}
          budgetPlanningId={budgetPlanningId}
          maxFcAllowed={maxFc}
          onClose={() => {
            setOpenJobBoardsModal(false);
          }}
          activeCampaigns={activeCampaigns || []}
          onAddNewJobBoard={(newBoards: any) => {
            setActiveCampaigns([...activeCampaigns, ...newBoards]);
            setOpenJobBoardsModal(false);
          }}
        />
      )}

      {openModal && (
        <CreateCampaign
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
}

export default ActiveCampaign;
