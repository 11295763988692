import React from "react";

type Props = {
  message: string;
  isFullWidth?: boolean;
  isAddClientBtn?: boolean;
};

const ToolTipNew = (props: Props) => {
  if (props.isAddClientBtn) {
    return (
      <div
        className={` hidden group-hover:block bg-gray-600 absolute z-50 text-white p-2 rounded-md text-sm w-1/2 text-center
      -bottom-16 -right-16
      `}
      >
        {props.message}
      </div>
    );
  }
  return (
    <div
      className={`hidden group-hover:block bg-gray-600 absolute z-50 text-white p-2 rounded-md text-sm max-w-[230px] text-center -bottom-7 left-0
    ${props?.isFullWidth && "left-[40%] -bottom-16"}

    `}
    >
      {props.message}
    </div>
  );
};

export default ToolTipNew;
