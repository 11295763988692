import { openSnackbar } from "../redux/snackbar";
import store from "../redux/store";

export const displayErrorSnackbar = (message: string) => {
  store.dispatch(
    openSnackbar({
      message,
      type: "error",
    }),
  );
};

export const displaySuccessSnackbar = (message: string) => {
  store.dispatch(
    openSnackbar({
      message,
      type: "success",
    }),
  );
};

export const validateEmail = (email: string) => {
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailRegex.test(email);
};

export const validatePassword = (password: string) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+=[\]{}|\\';:/?.>,<])(?=.*\d).{6,}$/;

  return regex.test(password);
};

export function isValidEmail(email: string) {
  // Basic regular expression for email validation
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailRegex.test(email);
}

export const isValidInput = (text: any) => {
  return !!`${text}`.trim() && text?.length <= 255;
};

export const invalidInputMessage = (message: string) => {
  return {
    message,
    valid: false,
  };
};

export const formatDateForDateInput = (date: Date) => {
  const dateToConvert = new Date(date);
  const year = dateToConvert?.getFullYear();
  const month = String(dateToConvert?.getMonth() + 1)?.padStart(2, "0");
  const day = String(dateToConvert?.getDate())?.padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getDecimalValue = (value: any) => {
  return parseFloat(value || 0).toFixed(3);
};

export const getPointValue = (value: any) => {
  const pointsValue = `${value}`?.replace(",", ".");
  return parseFloat(pointsValue);
};

export const getProperClicentName = (name: string) => {
  if (name === null || name === undefined || name === "") return name;

  const words = name?.split(" ");
  const result = [];

  for (const word of words) {
    if (word.length > 15) {
      let splittedWord = "";
      for (let i = 0; i < word.length; i += 15) {
        splittedWord += word.substring(i, i + 15) + " ";
      }
      result.push(splittedWord.trim());
    } else {
      result.push(word);
    }
  }

  return result.join(" ");
};

export const isContainsLongWord = (name: string) => {
  if (name === null || name === undefined || name === "") return name;
  const words = name?.split(" ");

  for (const word of words) {
    if (word.length > 15) {
      return true; // Found a word longer than 15 characters
    }
  }

  return false;
};

export const removeHtml = (html: any) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent ?? "";
};

export const userrole = () => {
  const role = localStorage.getItem("role");
  return role;
};

export const getCampaignValue = (paused: boolean, status: boolean) => {
  if (!paused && status) return { label: "Online", value: "Online" };
  if (paused && status) return { label: "Paused", value: "Paused" };
  if (!status) return { label: "Deleted/Archived", value: "Deleted" };
};

export const unauthorizedMessage =
  "You don't have permission to edit this field.";
