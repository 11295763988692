import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { getClientFeedsV2 } from "../../services/clients";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../services/common";
import { FeedType } from "../../types/client/ClientTypes";
import Spinner from "../Spinner/Spinner";

import styles from "./editClient.module.css";
import CreateClientFeed from "./Feed/Create";
import EditClientFeed from "./Feed/Edit";

const ClientFeed: React.FC = () => {
  const [addNewFeed, setAddNewFeed] = useState<boolean>(false);
  const { id } = useParams();
  if (id === undefined) throw Error("Invalid contact");

  const clientId = +id;

  const {
    data,
    isLoading: isLoadingFeeds,
    refetch,
  } = useQuery(["clientFeeds", clientId], () => getClientFeedsV2(clientId), {
    refetchOnWindowFocus: false,
    enabled: !!clientId,
    onError() {
      displayErrorSnackbar("Error loading client feeds");
    },
  });

  const mapDataToClientFeeds = ({
    feedName,
    id,
    feedUrl,
    urlType,
    feedFormat,
  }: FeedType) => ({
    feedName,
    id,
    feedUrl,
    urlType,
    feedFormat,
  });

  const [clientFeeds, setClientFeeds] = useState<FeedType[]>(
    data?.data.data.map(mapDataToClientFeeds) ?? [],
  );

  useEffect(() => {
    if (data) {
      setClientFeeds(data.data.data.map(mapDataToClientFeeds));
    }
  }, [data]);

  const handleCancel = () => {
    setAddNewFeed(false);
  };

  const handleFeedDeleted = (id: number) => {
    displaySuccessSnackbar("Feed deleted successfully");
    setClientFeeds((clientFeeds) => {
      const index = clientFeeds.map(({ id }) => id).indexOf(id);
      const copy = [...clientFeeds];
      copy.splice(index, 1);
      return copy;
    });
    refetch();
  };

  const handleFeedUpdated = (feed: FeedType) => {
    displaySuccessSnackbar("Feed updated successfully");
    setClientFeeds((feeds) => {
      const index = clientFeeds.map(({ id }) => id).indexOf(feed.id);
      const copy = [...feeds];
      copy[index] = feed;
      return copy;
    });
    refetch();
  };

  const handleFeedAdded = (feed: FeedType) => {
    displaySuccessSnackbar("Feed added successfully");
    setClientFeeds((feeds) => [...feeds, feed]);
    setAddNewFeed(false);
    refetch();
  };

  return (
    <div className={`relative  flex flex-col  ${styles.mainContainer}`}>
      {isLoadingFeeds && <Spinner />}
      {data && (
        <>
          <div className="flex flex-col gap-5 ">
            {clientFeeds?.map((feed: FeedType) => {
              return (
                <EditClientFeed
                  key={feed.id}
                  clientId={clientId}
                  feed={feed}
                  onFeedDeleted={handleFeedDeleted}
                  onFeedUpdated={handleFeedUpdated}
                />
              );
            })}
            {addNewFeed && (
              <CreateClientFeed
                clientId={clientId}
                cancel={handleCancel}
                onFeedAdded={handleFeedAdded}
              />
            )}
          </div>
          <div className=" flex gap-4">
            <button
              className=" text-[#D95A5A] flex items-center gap-4 cursor-pointer"
              onClick={() => setAddNewFeed(true)}
            >
              <svg
                width={16}
                height={17}
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.9999 2.76331C8.44173 2.76331 8.7999 3.12148 8.7999 3.56331V7.56331H12.7999C13.2417 7.56331 13.5999 7.92148 13.5999 8.36331C13.5999 8.80513 13.2417 9.16331 12.7999 9.16331H8.7999V13.1633C8.7999 13.6051 8.44173 13.9633 7.9999 13.9633C7.55807 13.9633 7.1999 13.6051 7.1999 13.1633V9.16331H3.1999C2.75807 9.16331 2.3999 8.80513 2.3999 8.36331C2.3999 7.92148 2.75807 7.56331 3.1999 7.56331H7.1999V3.56331C7.1999 3.12148 7.55807 2.76331 7.9999 2.76331Z"
                  fill="#D95A5A"
                />
              </svg>
              Add Feed
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ClientFeed;
