import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { NavLink } from "react-router-dom";

import { Icons } from "../../assets/icons";
import { Button } from "../../components/Button/Button";
import { AuthInput } from "../../components/FormFields/FormFields";
import { routeNames } from "../../router/routeNames";
import { forgotPassword } from "../../services/auth";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../services/common";

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [errors, setErrors] = useState<any>({});
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const { mutate, isLoading, data } = useMutation(
    (data: any) => forgotPassword(data),
    {
      onError(error: any) {
        if (error.response.status === 404) {
          displayErrorSnackbar(error?.response?.data);
        } else setErrors(error.response?.data);
      },
    },
  );

  const removeValidationError = (key: string) => {
    setErrors((errors: Record<string, string[]>) => {
      delete errors[key];

      return {
        ...errors,
      };
    });
  };

  useEffect(() => {
    if (!isLoading && data) {
      setIsEmailSent(true);
      displaySuccessSnackbar(
        "Password reset email has been sent successfully.",
      );
    }
  }, [data]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate({ email });
  };

  return (
    <div className=" flex justify-center items-center h-[70vh]">
      {isEmailSent ? (
        <div className="my-[10rem] text-[1.2rem] font-bold text-gray-900">
          <span className="">
            Password reset email has been sent successfully. Please check your
            email!
          </span>
        </div>
      ) : (
        <form
          className=" flex flex-col gap-y-4 mt-5 max-w-md"
          onSubmit={handleSubmit}
        >
          <h2 className="text-3xl font-bold tracking-wide flex justify-center">
            Reset Password
          </h2>
          <AuthInput
            icon={<Icons.UserIcon />}
            name="email"
            handleChange={(e: React.FormEvent<HTMLInputElement>) => {
              removeValidationError("email");
              setEmail(e.currentTarget.value);
            }}
            placeholder="Email"
            type="email"
            value={email}
            errors={errors["email"]}
          />
          <div className="flex justify-end">
            <NavLink to={routeNames.LOGIN} className="text-blue-600 underline ">
              Forgotten Password?
            </NavLink>
          </div>

          <Button
            type="submit"
            color="primary"
            fullWidth
            size="large"
            disabled={isLoading}
            loading={isLoading}
          >
            Reset Password
          </Button>
        </form>
      )}
    </div>
  );
};

export default ForgotPassword;
