import Dropdown from "../Dropdown/Dropdown";

import styles from "./Paginator.module.css";

function Paginator(props: any) {
  const {
    pageNumber = 1,
    pageSize = 25,
    totalPages = 1,
    totalRecords = 1,
    onChange = () => {
      // Place Holder Comment
    },
  } = props;

  return (
    <div className={styles.paginator}>
      <div className={styles.perPage}>
        <span>Rows per page</span>
        <Dropdown
          value={pageSize}
          options={[
            { value: 25, label: 25 },
            { value: 50, label: 50 },
            { value: 100, label: 100 },
          ]}
          handleOnChange={(value: any) => {
            return onChange({
              pageNumber,
              pageSize: value,
            });
          }}
        />
      </div>

      <div className={styles.pageNumbers}>
        <button
          className={`${styles.pageBtn}`}
          disabled={pageNumber <= 1}
          onClick={() =>
            onChange({
              pageSize,
              pageNumber: pageNumber - 1,
            })
          }
        >
          <svg
            width={"18px"}
            height={"18px"}
            fill={"grey"}
            viewBox="0 0 96 96"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title />
            <path d="M39.3756,48.0022l30.47-25.39a6.0035,6.0035,0,0,0-7.6878-9.223L26.1563,43.3906a6.0092,6.0092,0,0,0,0,9.2231L62.1578,82.615a6.0035,6.0035,0,0,0,7.6878-9.2231Z" />
          </svg>
        </button>

        <div className={styles.pageBtn}>{pageNumber}</div>

        <button
          className={`${styles.pageBtn}`}
          disabled={pageNumber >= totalPages}
          onClick={() =>
            onChange({
              pageSize,
              pageNumber: pageNumber + 1,
            })
          }
        >
          <svg
            width={"18px"}
            height={"18px"}
            fill={"grey"}
            viewBox="0 0 96 96"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title />
            <path d="M69.8437,43.3876,33.8422,13.3863a6.0035,6.0035,0,0,0-7.6878,9.223l30.47,25.39-30.47,25.39a6.0035,6.0035,0,0,0,7.6878,9.2231L69.8437,52.6106a6.0091,6.0091,0,0,0,0-9.223Z" />
          </svg>
        </button>
      </div>

      <div className={styles.perPage}>
        <span>Total pages : </span>
        <div className={styles.pageBtn}>{totalPages}</div>
      </div>

      <div className={styles.perPage}>
        <span>Total records : </span>
        <div className={styles.pageBtn}>{totalRecords}</div>
      </div>
    </div>
  );
}

export default Paginator;
