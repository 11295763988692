import { ReactNode, useMemo } from "react";

import Spinner from "../Spinner/Spinner";

type ButtonProps = {
  children: ReactNode;
  type?: "submit" | "reset" | "button";
  variant?: "solid" | "outline" | "text";
  color: "primary" | "primaryLight" | "secondary" | "tertiary";
  size?: "small" | "medium" | "large";
  prefixIcon?: ReactNode;
  postfixIcon?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
};

export const Button = ({
  disabled = false,
  onClick,
  type = "button",
  color = "primary",
  variant = "solid",
  prefixIcon,
  children,
  postfixIcon,
  size = "medium",
  loading = false,
  fullWidth = false,
}: ButtonProps) => {
  const baseStyles =
    "transition-all rounded-[4px]  whitespace-nowrap text-sm flex items-center gap-x-2 justify-center";

  // Define color styles
  const colorStyles: Record<string, Record<string, string>> = {
    primary: {
      solid: "bg-[#245ed2] text-white hover:bg-[#c2d2f2]",
      outline:
        "border border-[#245ed2] text-[#245ed2] hover:bg-[#245ed2] hover:text-white",
      text: "text-[#245ed2]",
    },
    primaryLight: {
      solid: "bg-[#C2D2F2] text-[#245ED2] hover:bg-[#245ED2] hover:text-white",
      outline:
        "border border-[#C2D2F2] text-[#C2D2F2] hover:bg-[#C2D2F2] hover:text-[#245ED2]",
      text: "text-[#C2D2F2]",
    },

    secondary: {
      solid: "bg-[#cd335b] text-white hover:bg-[#7c3642]",
      outline:
        "border border-[#cd335b] text-[#cd335b] hover:bg-[#cd335b] hover:text-white",
      text: "text-[#cd335b]",
    },
    tertiary: {
      solid: "bg-[#000000] text-white hover:bg-[#505150]",
      outline:
        "border border-[#000000] text-[#000000] hover:bg-[#000000] hover:text-white",
      text: "text-black",
    },
  };

  const sizeClasses = useMemo(() => {
    let sizeClass = "";

    switch (size) {
      case "small":
        sizeClass = "text-[14px] py-1 px-3";
        break;
      case "large":
        sizeClass = "text-[16px] py-[11px] px-8";
        break;
      default:
        sizeClass = "text-[15px] py-2 px-8";
    }

    return fullWidth ? `${sizeClass} w-full` : sizeClass;
  }, [size, fullWidth]);

  const colorClass = colorStyles[color] || colorStyles.primary;
  const variantClass = colorClass[variant] || colorClass.solid;
  const classes = `${baseStyles} ${variantClass}  ${sizeClasses}`;

  return (
    <button
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      type={type}
      className={`${classes}`}
    >
      {loading ? (
        <Spinner size={25} />
      ) : (
        <>
          {prefixIcon && <span>{prefixIcon}</span>}
          {children}
          {postfixIcon && <span>{postfixIcon}</span>}
        </>
      )}
    </button>
  );
};
