import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Select from "react-select";

import Close from "../../../assets/img/Close.png";
import { getBudgetPlanningType } from "../../../services/budget";
import {
  getCampaign,
  getVacanciesCountByClientAndFeedDays,
  updateCampaign,
} from "../../../services/campaign";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
  getCampaignValue,
  invalidInputMessage,
  isValidInput,
} from "../../../services/common";
import { getFeedByIdV2 } from "../../../services/feeds";
import { getAllJobBoards } from "../../../services/jobBoard";
import {
  getVacanciesbySegment,
  getVacanciesbySegments,
} from "../../../services/segments";
import {
  getAllSegments,
  getSegmentById,
} from "../../../services/vacancySegment";
import BudgetCard from "../../BudgetCard/BudgetCard";
import BudgetJobBoardsModal from "../../BudgetJobBoardsModal/BudgetJobBoardsModal";
import { Button } from "../../Button/Button";
import KPISettings from "../../KPISettings/KPISettings";
import CreateSegment from "../../Segment/CreateSegment/CreateSegment";
import EditSegment from "../../Segment/EditSegment/EditSegment";
import Spinner from "../../Spinner/Spinner";

import styles from "./ViewCampaignModal.module.css";

export default function ViewCampaignModal(props: any) {
  const { isOpen, onClose, campaignID, clientId } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [campaignData, setCampaignData] = useState<any>({
    clientID: clientId,
    segmentID: "",
    clientFeedID: "",
    name: "",
    type: "",
    budget: "",
    endDate: "",
    status: true,
    paused: true,
    feedDays: null,
    publish: true,
    campaignJobBoard: [],
    vacancyIds: [],
    campaignSegment: [],
  });

  const [openNewJobBoardModal, setOpenNewJobBoardModal] =
    useState<boolean>(false);

  const [selectedFeed, setSelectedFeed] = useState<any>(null);
  const [selectedSegments, setSelectedSegments] = useState<any>([]);
  const [segments, setSegments] = useState<any>([]);
  const [newSegments, setNewSegments] = useState<any[]>([]);

  const [pageDetails, setPageDetails] = useState<any>({
    pageNumber: 1,
    pageSize: 25,
  });
  const [inputValue, setInputValue] = useState<any>("");

  const [vacancies, setVacancies] = useState<any>({
    vacancyCount: 0,
    jobVacancy: [],
  });

  const [jobBoards, setJobBoards] = useState<any>([]);
  const [openStep, setOpenStep] = useState<number | null>(0);
  const [check, setCheck] = useState<any>(false);
  const [dataFilled, setDataFilled] = useState<boolean>(false);
  const [vacanciesIds, setVacanciesIds] = useState<Array<number>>([]);

  const dummyFunction = () => {
    return 0;
  };

  useEffect(() => {
    dummyFunction();
  }, [inputValue, jobBoards]);

  const { data: PlanningType, isLoading: fetchingPlanningType } = useQuery(
    ["budgetPlanningTypes"],
    getBudgetPlanningType,
  );

  const { data, isLoading, refetch } = useQuery(
    ["campaign", campaignID],
    () => getCampaign(campaignID),
    {
      enabled: false,
      onSuccess(data) {
        setCampaignData({
          ...data?.data,
          vacancyIds: [],
        });

        const selectedSegmentIds = data?.data?.campaignSegment.map(
          (segment: any) => {
            return {
              label: segment.segment.name,
              value: segment.segment.id,
            };
          },
        );

        setSelectedSegments(selectedSegmentIds);
      },
    },
  );

  // Fetching all feeds for select box
  const { refetch: GetFeedData } = useQuery(
    ["feed", campaignData?.clientFeedID],
    () => getFeedByIdV2(data?.data?.clientFeedID, clientId),
    {
      enabled: false,
      onSuccess(data) {
        setSelectedFeed({ value: data?.data?.id, label: data?.data?.feedName });
      },
    },
  );

  // Fetching segment data by segment id
  const { refetch: GetSegmentData } = useQuery(
    ["segment", campaignData?.segmentID],
    () => getSegmentById(data?.data?.segmentID),
    {
      enabled: false,
    },
  );

  const { data: SegmentsData } = useQuery(
    ["segments", clientId, pageDetails?.pageNumber, pageDetails?.pageSize],
    () => getAllSegments(pageDetails?.pageNumber, pageDetails?.pageSize),
    {
      onSuccess(data) {
        const newArray = [];
        for (const segment of data.data.data) {
          const existingElement = segments.find(
            (element: any) => element?.value === segment?.id,
          );
          if (!existingElement) {
            newArray.push({
              value: segment?.id,
              label: segment?.name,
            });
          }
        }
        setSegments([...segments, ...newArray]);
      },
    },
  );

  useQuery(["jobBoards"], () => getAllJobBoards());

  useEffect(() => {
    const selectedSegmentArray = selectedSegments?.map(
      (item: any) => item.value,
    );
    if (selectedFeed && selectedSegmentArray.length > 0) {
      getVacanciesIdBySegmentsId(selectedSegmentArray);
    }
  }, [selectedFeed, selectedSegments.length]);

  const { mutate: getVacanciesIdBySegmentsId } = useMutation(
    [
      "segmentVacanciesByIDS",
      selectedFeed?.value,
      selectedSegments?.map((item: any) => item.value),
    ],
    (selectedSegmentArray: any) =>
      selectedFeed?.value &&
      getVacanciesbySegments(
        selectedFeed?.value,
        selectedSegmentArray,
        campaignData.feedDays === null || campaignData.feedDays === ""
          ? ""
          : campaignData.feedDays,
      ),
    {
      onSuccess: (data: any) => {
        setVacanciesIds(data?.data?.allVacancyIds);
      },
    },
  );

  const { data: vacanciesData, refetch: fetchVacancies } = useQuery(
    ["segmentVacancies", selectedFeed?.value, selectedSegments?.value],
    () =>
      selectedFeed?.value &&
      selectedSegments?.length > 0 &&
      getVacanciesbySegment(
        selectedFeed?.value,
        selectedSegments[0].value,
        campaignData.feedDays === null || campaignData.feedDays === ""
          ? ""
          : campaignData.feedDays,
      ),
  );

  const { refetch: fetchVacanciesCountByclientFeedDays } = useQuery(
    ["totNewVacancies", selectedFeed?.value, campaignData.feedDays],
    () =>
      getVacanciesCountByClientAndFeedDays(
        selectedFeed?.value,
        campaignData.feedDays === null || campaignData.feedDays === ""
          ? ""
          : campaignData.feedDays,
        campaignID,
      ),
    {
      enabled: false,
      onSuccess: (data) => {
        setTotNewVacancies({
          ...totNewVacancies,
          vacancyCount: data.data.vacancyCount,
        });
      },
    },
  );

  useEffect(() => {
    if (selectedFeed?.value) {
      fetchVacanciesCountByclientFeedDays();
    }
  }, [selectedFeed?.value, campaignData.feedDays]);

  const {
    mutate,
    isLoading: loadingCampaign,
    reset,
  } = useMutation((data: any) => updateCampaign(data, campaignID), {
    onSuccess(data) {
      displaySuccessSnackbar(data.data.data.message);
      onClose();
      refetch();
      queryClient.refetchQueries("clientCampaigns");
      reset();
    },
    onError(error: any) {
      displayErrorSnackbar(error.response.data.message);
    },
  });

  useEffect(() => {
    setDataFilled(false);
  }, [selectedSegments?.value]);

  useEffect(() => {
    if (selectedFeed?.value && selectedSegments?.value) {
      fetchVacancies();
    }
  }, [selectedFeed, selectedSegments]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (campaignData?.id) {
      campaignData?.clientFeedID && GetFeedData(campaignData?.clientFeedID);
      campaignData?.segmentID && GetSegmentData(campaignData?.segmentID);
    }
  }, [campaignData?.id]);

  useEffect(() => {
    if (vacanciesData) {
      setVacancies(vacanciesData?.data);
    }
  }, [vacanciesData]);

  useEffect(() => {
    if (vacancies?.jobVacancy) {
      const vacancyIds = vacancies?.jobVacancy?.map(
        (vacancy: any) => vacancy?.id,
      );
      setCampaignData({
        ...campaignData,
        vacancyIds,
      });
    }
  }, [vacancies]);

  useEffect(() => {
    if (data) {
      setJobBoards(data?.data);
    }
  }, [data]);

  const [totNewVacancies, setTotNewVacancies] = useState<any>({
    vacancyCount: 0,
    jobVacancy: [],
  });

  const onOpenStep = (index: number) => {
    const step = openStep === index ? null : index;
    setOpenStep(step);
  };
  const handleChange = (newValue: any) => {
    setInputValue(newValue);
  };
  const handleBottom = () => {
    if (SegmentsData?.data?.pageNumber <= SegmentsData?.data?.totalPages) {
      setPageDetails({
        pageNumber: SegmentsData?.data?.pageNumber + 1,
        pageSize: SegmentsData?.data?.pageSize,
      });
    }
  };
  const onInputChange = (e: any) => {
    e.preventDefault();
    setCampaignData({
      ...campaignData,
      [e.target.name]: e.target.value,
    });
  };

  const validateCampaignForm = () => {
    setCheck(true);
    const { name, campaignJobBoard } = campaignData;

    for (const board of campaignJobBoard) {
      const budget = parseFloat(
        board?.jobboardBudgetAmount?.plannedAmountPerDay || 0,
      );
      const add = parseFloat(board?.jobboardBudgetAmount?.amountPerDay || 0);
      if (add > budget) {
        return invalidInputMessage("Invalid Boards budget");
      }
    }

    if (
      !selectedFeed?.value ||
      !isValidInput(name) ||
      !campaignJobBoard?.length
    ) {
      return invalidInputMessage("All fields with * are required");
    }

    return { valid: true, message: "" };
  };

  const handleUpdateCampaign = () => {
    if (loadingCampaign) {
      return;
    }
    const { valid, message } = validateCampaignForm();
    if (!valid) {
      displayErrorSnackbar(message);
      return;
    }
    //check that the segment data is saved or not
    if (dataFilled) {
      displayErrorSnackbar("Save the segment changes to proceed");
      return;
    }

    const boardsData = campaignData?.campaignJobBoard?.map((board: any) => {
      return {
        ...board,
        jobboardBudgetAmount: {
          ...board?.jobboardBudgetAmount,
          id: 0,
        },
      };
    });

    const getCampaignSegments = () => {
      return selectedSegments.map((segment: any) => {
        return {
          segmentID: segment?.value,
          campaignID: campaignData.id,
          segment: {
            segmentConditions: [],
          },
        };
      });
    };

    mutate({
      ...campaignData,
      clientFeedID: selectedFeed?.value,
      campaignJobBoard: boardsData,
      campaignSegment: getCampaignSegments(),
      vacancyIds: vacanciesIds,
    });
  };

  const addNewSegment = (segment: any) => {
    const existingElement = segments.find(
      (element: any) => element?.value === segment?.id,
    );
    const newSegment = {
      value: segment?.id,
      label: segment?.name,
      ...segment,
    };

    if (!existingElement) {
      setNewSegments([newSegment, ...newSegments]);
    }
  };

  const updateNewSegment = (segment: any) => {
    const existingSegments = newSegments?.filter(
      (element: any) => element?.value !== segment?.id,
    );
    const updatedSegment = {
      value: segment?.id,
      label: segment?.name,
      ...segment,
    };

    setNewSegments([updatedSegment, ...existingSegments]);
  };

  const errorColor = "#f00";
  const neutralColor = "#rgb(233 233 233)";

  const handleCardAmountChange = (e: any, index: any) => {
    const data = { ...campaignData };
    data["campaignJobBoard"][index]["jobboardBudgetAmount"][
      `${e.target.name}`
    ] = e.target.value || 0;
    setCampaignData(data);
  };

  const handleCheckDataSaved = (value: any) => {
    setDataFilled(value);
  };

  const handlePublish = (selected: any) => {
    setCampaignData({
      ...campaignData,
      publish: selected.value,
    });
  };

  const getDate = (date: any) => {
    if (typeof date === "string") {
      const dashedDate = date?.split("T")[0];
      const dateArray = dashedDate.split("-");
      return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
    }

    return "";
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogPanel
        className={`w-full max-w-4xl transform overflow-y-auto bg-white p-6 text-left align-middle shadow-xl transition-all fixed top-0 right-0 h-full
       ${!openNewJobBoardModal && "z-[12]"}
      `}
      >
        {isLoading ? (
          <div className={styles.spinnerContainer}>
            <Spinner size={"50px"} />
          </div>
        ) : (
          <>
            <DialogTitle
              as="h3"
              className="font-semibold text-2xl flex justify-between items-center"
            >
              <span>View Campaign</span>
              <button onClick={onClose}>
                <img src={Close} alt="Close" className="w-[20px] h-[20px]" />
              </button>
            </DialogTitle>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-8">
              <div className="flex  flex-col">
                <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
                  {t("name")} *
                </span>
                <input
                  name={"name"}
                  type={"text"}
                  className=" border-2 rounded-[5px] h-10 bg-transparent focus: outline-none pl-2"
                  value={campaignData?.name}
                  onChange={onInputChange}
                  style={{
                    borderColor:
                      check && !campaignData.name ? errorColor : neutralColor,
                  }}
                />
              </div>
              <div className="flex flex-col ">
                <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
                  Publish Campaign
                </span>
                <Select
                  styles={{
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "5px",
                      // minWidth: "282px",
                      padding: "2.5px",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#CDCDCD",
                      primary: "#CDCDCD",
                    },
                  })}
                  options={[
                    { value: true, label: "yes" },
                    { value: false, label: "No" },
                  ]}
                  value={{
                    value: campaignData?.publish,
                    label: campaignData?.publish ? "Yes" : "No",
                  }}
                  onChange={handlePublish}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-4">
              <div className="flex flex-col ">
                <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
                  {t("startDate")} (Read Only)
                </span>
                <input
                  className="border-2 rounded-[5px] h-10 pl-3"
                  type={"text"}
                  name={"startDate"}
                  value={getDate(campaignData?.date)}
                  onChange={onInputChange}
                  disabled
                  placeholder=""
                  required
                />
              </div>

              <div className="flex flex-col ">
                <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
                  {t("endDate")}
                </span>
                <input
                  className=" border-2 rounded-[5px] h-10 pl-3"
                  type={"date"}
                  name={"endDate"}
                  min={campaignData?.createdDate?.split("T")[0]}
                  value={campaignData?.endDate}
                  onChange={onInputChange}
                  placeholder=""
                  required
                />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-4">
              <div className="flex flex-col">
                <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
                  Type
                </span>
                <Select
                  styles={{
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "5px",
                      padding: "2.5px",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#CDCDCD",
                      primary: "#CDCDCD",
                    },
                  })}
                  value={{
                    value: campaignData?.type,
                    label: campaignData?.type,
                  }}
                  options={[
                    { value: "CPC", label: "CPC" },
                    { value: "CPA", label: "CPA" },
                    { value: "Organic", label: "Organic" },
                    { value: "CPM", label: "CPM" },
                  ]}
                  onChange={(selected: any) =>
                    setCampaignData({
                      ...campaignData,
                      type: selected?.value,
                    })
                  }
                  placeholder={"Campaign type"}
                />
              </div>

              <div className="flex flex-col">
                <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
                  LMH
                </span>
                <Select
                  styles={{
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "5px",
                      padding: "2.5px",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#CDCDCD",
                      primary: "#CDCDCD",
                    },
                  })}
                  isLoading={fetchingPlanningType}
                  value={{
                    value: campaignData?.lmh,
                    label: campaignData?.lmh,
                  }}
                  options={PlanningType?.data?.map((type: any) => {
                    return {
                      value: type?.typeName,
                      label: type?.typeName,
                    };
                  })}
                  onChange={(selected: any) =>
                    setCampaignData({
                      ...campaignData,
                      lmh: selected?.value,
                    })
                  }
                  placeholder={"Budget type"}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-4">
              <div className="flex flex-col">
                <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
                  Status
                </span>
                <Select
                  styles={{
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "5px",
                      padding: "2.5px",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#CDCDCD",
                      primary: "#CDCDCD",
                    },
                  })}
                  isLoading={fetchingPlanningType}
                  value={getCampaignValue(
                    campaignData.paused,
                    campaignData.status,
                  )}
                  options={[
                    { label: "Online", value: "Online" },
                    { label: "Paused", value: "Paused" },
                    { label: "Deleted/Archieved", value: "Deleted" },
                  ]}
                  onChange={(selected: any) => {
                    if (selected.value === "Online") {
                      setCampaignData({
                        ...campaignData,
                        paused: false,
                        status: true,
                      });
                    }
                    if (selected.value === "Paused") {
                      setCampaignData({
                        ...campaignData,
                        paused: true,
                        status: true,
                      });
                    }

                    if (selected.value === "Deleted") {
                      setCampaignData({
                        ...campaignData,
                        status: false,
                      });
                    }
                  }}
                  placeholder={"Budget type"}
                />
              </div>

              <div className="flex flex-col">
                <span className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
                  Feed Days
                </span>
                <input
                  className="w-full border-2 rounded-[5px] h-10 bg-transparent focus:outline-none pl-2"
                  name={"feedDays"}
                  type={"number"}
                  value={campaignData?.feedDays}
                  onChange={onInputChange}
                />
              </div>
            </div>

            {/* section Tracking */}
            <div className="camapignStep mt-8">
              <div className="border-solid border-2 h-11 opacity-50">
                <div className="stepTitleWrap flex justify-start items-center gap-[30px] pt-2 pl-2">
                  <svg
                    height="20px"
                    width="20px"
                    fill={"gray"}
                    version="1.1"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C14.4,2 18,5.6 18,10 C18,14.4 14.4,18 10,18 L10,18 Z"
                      id="Shape"
                    />
                  </svg>

                  <span>Tracking</span>
                </div>
              </div>
            </div>

            {/* section Budget */}
            <div className="camapignStep mt-8">
              <div
                className="stepHeader border-solid border-2 cursor-pointer"
                style={{ minHeight: "45px" }}
              >
                <button onClick={() => onOpenStep(1)}>
                  <div className="stepTitleWrap flex justify-start items-center gap-[30px] pt-2 pl-2">
                    <svg
                      height="20px"
                      width="20px"
                      fill={"gray"}
                      version="1.1"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C14.4,2 18,5.6 18,10 C18,14.4 14.4,18 10,18 L10,18 Z"
                        id="Shape"
                      />
                    </svg>
                    <span>Budget</span>
                  </div>
                </button>

                {openStep === 1 && (
                  <div className={styles.stepBody}>
                    <div
                      className={`${styles.inputRow} ${styles.inputRowBudget}`}
                    >
                      {campaignData?.campaignJobBoard?.map(
                        (item: any, index: number) => (
                          <BudgetCard
                            key={`${"" + index}`}
                            campaignBoardBudget={item}
                            onAmountChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => handleCardAmountChange(e, index)}
                            allowDelete={!item?.jobboardBudgetAmount?.amount}
                            onDelete={() => {
                              const campaignBoards = [
                                ...(campaignData?.campaignJobBoard || []),
                              ];
                              campaignBoards?.splice(index, 1);
                              setCampaignData({
                                ...campaignData,
                                campaignJobBoard: campaignBoards,
                              });
                            }}
                          />
                        ),
                      )}

                      <button onClick={() => setOpenNewJobBoardModal(true)}>
                        <svg
                          width={"50px"}
                          height={"50px"}
                          id="Layer_1"
                          version="1.1"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M384,265H264v119h-17V265   H128v-17h119V128h17v120h120V265z" />
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* section feed */}
            <div className="campaignStep mt-8">
              <div
                className="stepHeader border-solid border-2 cursor-pointer "
                style={{ minHeight: "45px" }}
              >
                <button
                  className="flex items-center w-full flex-wrap justify-between pt-2 pl-2 "
                  onClick={() => onOpenStep(2)}
                >
                  <div className="flex items-center gap-7">
                    <svg
                      height="20px"
                      width="20px"
                      fill={"gray"}
                      version="1.1"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C14.4,2 18,5.6 18,10 C18,14.4 14.4,18 10,18 L10,18 Z"
                        id="Shape"
                      />
                    </svg>
                    <h2>Vacancies Feeds</h2>
                  </div>
                  <div className="mr-4">
                    <b>{totNewVacancies?.vacancyCount || 0}</b> Vacancies In
                    Selection{" "}
                  </div>
                </button>
                {openStep === 2 && (
                  <div className={styles.stepBody}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 my-8">
                      <div className="flex flex-col">
                        <label className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
                          Feed *
                        </label>
                        <Select
                          styles={{
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor:
                                check && !selectedFeed
                                  ? errorColor
                                  : neutralColor,
                              borderRadius: "5px",
                              padding: "2.5px",
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "#CDCDCD",
                              primary: "#CDCDCD",
                            },
                          })}
                          isDisabled={true}
                          value={selectedFeed}
                          onChange={(selected: any) =>
                            setSelectedFeed(selected)
                          }
                          placeholder={"Select feed"}
                        />
                      </div>

                      <div className="flex flex-col">
                        <label className="font-plus-jakarta text-sm font-semibold text-[#42526E]">
                          Segment
                        </label>
                        <div className={styles.menulist}>
                          <Select
                            styles={{
                              // eslint-disable-next-line @typescript-eslint/no-unused-vars
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderRadius: "5px",
                                padding: "2.5px",
                              }),
                            }}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary25: "#CDCDCD",
                                primary: "#CDCDCD",
                              },
                            })}
                            isClearable
                            onMenuScrollToBottom={handleBottom}
                            value={selectedSegments}
                            options={[...newSegments, ...segments]}
                            onChange={(selected: any) =>
                              setSelectedSegments(selected)
                            }
                            isMulti
                            onInputChange={handleChange}
                            placeholder={"Select segment"}
                          />
                        </div>
                      </div>
                    </div>

                    {selectedFeed?.value && (
                      <>
                        <EditSegment
                          feedDays={campaignData.feedDays}
                          feedId={selectedFeed?.value}
                          selectedSegments={selectedSegments}
                          onEditSegment={(newSegment: any) => {
                            updateNewSegment(newSegment);
                          }}
                          vacanciesIds={vacanciesIds}
                        />
                        <CreateSegment
                          feedDays={campaignData.feedDays}
                          feedId={selectedFeed?.value}
                          onCreateSegment={(newSegment: any) => {
                            const oldSegmentIds = selectedSegments;
                            oldSegmentIds.push({
                              value: newSegment.id,
                              label: newSegment.name,
                            });
                            setSelectedSegments(oldSegmentIds);
                            addNewSegment(newSegment);
                          }}
                          onDataFilledChange={handleCheckDataSaved}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="campaignStep mt-8">
              <div
                className="stepHeader border-solid border-2 cursor-pointer"
                style={{ minHeight: "45px" }}
              >
                <button
                  className="stepTitleWrap flex justify-start items-center gap-[30px] pt-2 pl-2"
                  onClick={() => onOpenStep(4)}
                >
                  <svg
                    height="20px"
                    width="20px"
                    fill={"gray"}
                    version="1.1"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C14.4,2 18,5.6 18,10 C18,14.4 14.4,18 10,18 L10,18 Z"
                      id="Shape"
                    />
                  </svg>

                  <span>KPI</span>
                </button>

                {openStep === 4 && (
                  <div className={styles.stepBody}>
                    <div className={styles.inputRow}>
                      <KPISettings />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {openNewJobBoardModal && (
              <div className="parent relative">
                <BudgetJobBoardsModal
                  isOpen={openNewJobBoardModal}
                  onClose={() => {
                    setOpenNewJobBoardModal(false);
                  }}
                  campaignId={campaignID}
                  clientId={clientId}
                  onJobBoardAdded={() => {
                    refetch();
                    setOpenStep(1);
                    setOpenNewJobBoardModal(false);
                  }}
                />
              </div>
            )}

            <div className="mt-8 w-full">
              <Button
                color="primary"
                fullWidth
                loading={loadingCampaign}
                onClick={handleUpdateCampaign}
                size="large"
              >
                Update
              </Button>
            </div>
          </>
        )}
      </DialogPanel>
    </Dialog>
  );
}
