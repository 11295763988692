import { openSnackbar } from "../redux/snackbar";
import store from "../redux/store";
import { removeUser } from "../redux/user";
import { storeUserSessionData } from "../utils";
const BASE_URL = process.env.REACT_APP_SERVER_URL;

import api from "./api";

export const login = async (data: any) => {
  const response = await api.post("v2/Auth/login", data);
  const accessToken: string = response?.data?.accessToken;
  if (accessToken) storeUserSessionData(accessToken, response.data);
  return response;
};

export const logout = () => {
  store.dispatch(removeUser());
  localStorage.clear();
};

export const createUser = async (data: any) => {
  const response: any = await api.post("v2/Auth/Register", data);
  return response;
};

export const refreshAccessToken = async (refreshToken: string) => {
  const response = await fetch(`${BASE_URL}v2/Auth/refresh`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify({ refreshToken: refreshToken }),
  });

  if (response.status === 401) {
    store.dispatch(
      openSnackbar({
        message: "User login session expired! Please log in again.",
        type: "info",
      }),
    );
    logout();
    window.location.pathname = "/login";
  }

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const resendOTP = async (data: any) => {
  const response: any = await api.post("Authenticate/ResendOTP", data);
  return response;
};

export const verifyUser = async (data: any) => {
  const response: any = await api.post("Authenticate/verifycode", data);
  const accessToken: string = response?.data?.token;
  if (accessToken) storeUserSessionData(accessToken, response.data);

  return response;
};

export const forgotPassword = async (data: any) => {
  const response = await api.post("v2/Auth/forgot-password", data);
  return response;
};

export const resetPassword = async (data: any) => {
  const response = await api.post("v2/Auth/reset-password", data);
  return response;
};

export const getRoles = async () => {
  const response = await api.get("User/GetRoles");
  return response;
};
