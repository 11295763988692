import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Select from "react-select";

import {
  getClientsV2,
  getClientV2,
  updateClientV2,
} from "../../services/clients";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../services/common";
import { ClientType } from "../../types/client/ClientTypes";
import {
  Button,
  Checkbox,
  NumberInput,
  SelectInput,
  TextInput,
  UrlInput,
} from "../FormFields/FormFields";
import Spinner from "../Spinner/Spinner";

import styles from "./editClient.module.css";

const ClientInfo: React.FC = () => {
  const [errors, setErrors] = useState<any>({});
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [allParents, setAllParents] = useState<Array<ClientType>>([]);

  const logoRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const { id: clientId } = useParams();

  const {
    data: parents,
    isLoading: isLoadingParents,
    refetch: refetchParents,
  } = useQuery(
    ["getClients", pageNumber],
    () => getClientsV2(pageNumber, 100),
    {
      enabled: !!clientId,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setAllParents([...allParents, ...response.data.data]);
      },
      onError() {
        displayErrorSnackbar("Error loading parents");
      },
    },
  );

  const { data, isLoading, refetch } = useQuery(
    ["getClient", clientId],
    () => getClientV2(clientId),
    {
      enabled: !!clientId,
      refetchOnWindowFocus: false,
      onError() {
        displayErrorSnackbar("Error loading client data");
      },
    },
  );

  const { mutate: updateClientMutate, isLoading: isSaving } = useMutation(
    () => {
      return updateClientV2(formData, clientId);
    },
    {
      onError(e: AxiosError) {
        setErrors(e.response?.data);
      },
      onSuccess() {
        refetch();
        displaySuccessSnackbar("Client updated successfully!");
      },
    },
  );

  const mapDataToClient = (data: AxiosResponse<any, any>) => ({
    directoryPath: data.data.directoryPath,
    for: data.data.for,
    id: data?.data.id,
    imagePath: data.data.imagePath,
    imageType: data.data.imageType,
    invoiceOnlineResult: data.data.invoiceOnlineResult,
    marginIncluded: data.data.marginIncluded,
    name: data?.data.name,
    overspendAllowed: data.data.overspendAllowed,
    parentID: data.data.parentID,
    percentage: data.data.percentage,
    recruitNowApiKey: data.data.recruitNowApiKey,
    recruitNowUrl: data.data?.recruitNowUrl,
    status: data.data.status,
    webhookEnabled: data.data.webhookEnabled,
  });

  const [formData, setFormData] = useState<ClientType>(
    data ? mapDataToClient(data) : {},
  );

  useEffect(() => {
    if (data) {
      setFormData(mapDataToClient(data));
    }
  }, [data]);

  const removeValidationError = (key: string) => {
    setErrors((errors: Record<string, string[]>) => {
      delete errors[key];

      return {
        ...errors,
      };
    });
  };

  const handleInputChange = (key: string, value: any) => {
    removeValidationError(key);
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  return (
    <div className="w-full">
      {isLoading && <Spinner />}
      {data && (
        <div className={styles.mainContainer}>
          <div className=" flex items-center gap-6 ">
            <div className=" w-36 h-36 rounded-full border border-[rgba(144,158,167,0.16)]  flex justify-center items-center ">
              {formData.imagePath && (
                <img
                  src={formData.imagePath}
                  className="  w-24 h-24 rounded-md"
                />
              )}
            </div>
            <div>
              <label className=" text-[#171717] text-2xl font-bold">
                {formData.name}
              </label>

              <div className=" flex mt-5 gap-4">
                <button
                  className=" border border-[#CD335B] rounded-sm flex gap-2 px-4 py-2 items-center text-[#CD335B] text-sm"
                  onClick={() => {
                    logoRef?.current?.focus();
                  }}
                >
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.66667 14.1667C4.36548 14.1667 2.5 12.3012 2.5 10C2.5 7.95961 3.9666 6.26177 5.90313 5.90314C6.26177 3.9666 7.95961 2.5 10 2.5C12.0404 2.5 13.7382 3.9666 14.0969 5.90314C16.0334 6.26177 17.5 7.95961 17.5 10C17.5 12.3012 15.6345 14.1667 13.3333 14.1667M7.5 10L10 7.5M10 7.5L12.5 10M10 7.5V17.5"
                      stroke="#CD335B"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Change
                </button>
                <button
                  className=" border border-[#172029] rounded-sm flex gap-2 px-[19px] py-2 items-center text-[#172029] text-sm"
                  onClick={() => {
                    // if (formData) {
                    //   setClientData({
                    //     ...formData ?? {},
                    //     imagePath: "",
                    //   });
                    // }
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>

          <div className="mt-10 grid grid-cols-1 gap-5">
            <div className=" grid sm:grid-cols-2 gap-8">
              <TextInput
                label="Name"
                name="name"
                value={formData.name}
                handleChange={(e) => handleInputChange("name", e.target.value)}
                errors={errors["name"]}
              />
              <SelectInput
                label="For"
                name="for"
                value={
                  formData.for
                    ? {
                        value: formData.for,
                        label: formData.for,
                      }
                    : undefined
                }
                options={[
                  { value: "Prepaid", label: "Prepaid" },
                  { value: "Postpaid", label: "Postpaid" },
                ]}
                handleChange={(selectedOption: any) =>
                  handleInputChange("for", selectedOption?.value)
                }
              />
            </div>
            <div className="grid sm:grid-cols-2 gap-8">
              <TextInput
                label={t("Logo URL")}
                name="imagePath"
                value={formData.imagePath}
                handleChange={(e) =>
                  handleInputChange("imagePath", e.target.value)
                }
              />

              <div className=" grid grid-cols-1 gap-2">
                <label className=" text-[#42526E] text-sm font-semibold ">
                  {t("Parent")}
                </label>
                <Select
                  isClearable={true}
                  isLoading={isLoadingParents}
                  isDisabled={isLoadingParents}
                  name="parentID"
                  placeholder={"Select parent client"}
                  value={
                    formData.parentID && {
                      label: (() => {
                        const client = parents?.data.data.filter(
                          ({ id }: any) => id === formData.parentID,
                        )[0];

                        return client?.name ?? client?.id;
                      })(),
                      value: formData.parentID,
                    }
                  }
                  onChange={(e) =>
                    handleInputChange("parentID", e ? e.value : null)
                  }
                  options={
                    parents &&
                    parents.data.data
                      .filter((client: any) => client?.id !== Number(clientId))
                      .map((client: any) => ({
                        value: client?.id,
                        label: client?.name ?? client?.id,
                      }))
                  }
                  onMenuScrollToBottom={() => {
                    if (parents?.data.totalRecords > allParents.length) {
                      setPageNumber((prev) => prev + 1);
                      refetchParents();
                    }
                  }}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: "#E5EAF0",
                      borderRadius: "5px",
                      outline: "none",
                      fontSize: "16px",
                      width: "100%",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#CDCDCD",
                      primary: "#CDCDCD",
                    },
                  })}
                />
              </div>
            </div>
            <div className="grid sm:grid-cols-2 gap-8">
              <UrlInput
                label="RecruitNow URL"
                sublabel="(https://example.recruitnowcockpit.nl)"
                name="recruitNowUrl"
                value={formData.recruitNowUrl}
                handleChange={(e) =>
                  handleInputChange("recruitNowUrl", e.target.value)
                }
                errors={errors["recruitNowUrl"]}
              />
              <TextInput
                label="RecruitNow API Key"
                name="recruitNowApiKey"
                value={formData.recruitNowApiKey}
                handleChange={(e) =>
                  handleInputChange("recruitNowApiKey", e.target.value)
                }
                errors={errors["recruitNowApiKey"]}
              />
            </div>
            <div className="grid sm:grid-cols-2 gap-8">
              <Checkbox
                checked={formData.marginIncluded}
                handleChange={(e) =>
                  handleInputChange("marginIncluded", e.target.checked)
                }
                label="Include Margin"
                name="marginIncluded"
              />
              <Checkbox
                checked={formData.invoiceOnlineResult}
                handleChange={(e) =>
                  handleInputChange("invoiceOnlineResult", e.target.checked)
                }
                label="Invoice OnlineResults"
                name="invoiceOnlineResult"
              />
            </div>
            <div className="grid sm:grid-cols-2 gap-8">
              <Checkbox
                checked={formData.webhookEnabled}
                handleChange={(e) =>
                  handleInputChange("webhookEnabled", e.target.checked)
                }
                label="RecruitNow Webhooks Enabled"
                name="webhookEnabled"
              />
              <Checkbox
                handleChange={(e) =>
                  handleInputChange("overspendAllowed", e.target.checked)
                }
                label="Overspend Allowed"
                checked={formData.overspendAllowed}
                name="overspendAllowed"
              />
            </div>
            {formData.overspendAllowed && (
              <div className="grid sm:grid-cols-2 gap-8">
                <NumberInput
                  handleChange={(e) =>
                    handleInputChange("percentage", +e.target.value)
                  }
                  label="Percentage"
                  name="percentage"
                  value={formData.percentage}
                />
              </div>
            )}
          </div>
          <div className=" flex justify-end my-3">
            <Button
              label="Save changes"
              className=" bg-[#CD335B] py-2 px-5 text-white rounded-sm"
              type="submit"
              isLoading={isSaving}
              onSubmit={updateClientMutate}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientInfo;
