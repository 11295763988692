import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { closeSidebar } from "../../redux/drawer";

const Drawer = () => {
  const isOpen = useSelector((state: any) => state.drawer.isOpen);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goTo = (endpoint: string) => {
    dispatch(closeSidebar());
    navigate(endpoint);
  };

  if (isOpen)
    return (
      <div className="w-screen h-screen absolute z-50 flex flex-col   bg-[#0A192F] gap-24 items-center text-gray-50 font-semibold text-24 p-24">
        <div className="flex justify-end w-full text-gray-300">
          <button
            onClick={() => dispatch(closeSidebar())}
            className="hover:text-gray-100 bg-[#0d1f3a] py-2 px-4  rounded hover:bg-[#111f35] border border-gray-600 hover:shadow-sm hover:shadow-gray-400"
          >
            X
          </button>
        </div>
        <div className="w-full flex flex-col gap-6">
          <button
            onClick={() => goTo("/job-boards")}
            className="w-full bg-[#0d1f3a] py-2 rounded-md shadow-[10px] hover:bg-[#111f35]  hover:text-gray-200 text-gray-400"
          >
            Job Boards
          </button>
          <button
            onClick={() => goTo("/field-mapping")}
            className="w-full bg-[#0d1f3a] py-2 rounded-md shadow-[10px] hover:bg-[#111f35]  hover:text-gray-200 text-gray-400"
          >
            Mappings
          </button>
        </div>
      </div>
    );

  return <></>;
};

export default Drawer;
