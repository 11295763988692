import api from "./api";

export const getFieldPositionMapping = async (
  pageNumber?: any,
  pageSize?: any,
) => {
  const PageNumber = pageNumber ? `&PageNumber=${pageNumber}` : "&PageNumber=1";
  const PageSize = pageNumber ? `&PageSize=${pageSize}` : "&PageSize=25";
  const response: any = await api.get(
    `FieldPositionMapping?${PageNumber}${PageSize}`,
  );
  return response;
};

export const updateFieldPositionMapping = async (data: any) => {
  const response: any = await api.put("FieldPositionMapping", data);
  return response;
};

export const getFieldTypeWithMapping = async () => {
  const response: any = await api.get(
    "FieldPositionMapping/GetFieldTypeWithMapping",
  );
  return response;
};

export const getMappingVacancyData = async (
  id: string | number,
  pageNumber?: any,
  pageSize?: any,
) => {
  const PageNumber = pageNumber ? `&PageNumber=${pageNumber}` : "&PageNumber=1";
  const PageSize = pageNumber ? `&PageSize=${pageSize}` : "&PageSize=25";
  const response: any = await api.get(
    `FieldPositionMapping/GetVacancies?id=${id}${PageNumber}${PageSize}`,
  );
  return response;
};
