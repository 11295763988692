// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Snackbar_snackbar__StvjI {
  position: fixed;
  bottom: 32px;
  left: 50%;
  right: 0;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  z-index: 100;
  width: max-content;
  min-width: 200px;
  max-width: 70vw;
  padding: 12px 16px;
  background-color: #a3ebb1;
  border: 2px solid #116530;
  color: #116530;
}

.Snackbar_success__wt\\+9B {
  background-color: #a3ebb1;
  border: 2px solid #116530;
  color: #116530;
}

.Snackbar_error__L\\+7Ko {
  color: #d8000c;
  border: 2px solid #d8000c;
  background-color: #ffbaba;
}
`, "",{"version":3,"sources":["webpack://./src/components/Snackbar/Snackbar.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,SAAS;EACT,QAAQ;EACR,6BAA6B;EAC7B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,yBAAyB;AAC3B","sourcesContent":[".snackbar {\n  position: fixed;\n  bottom: 32px;\n  left: 50%;\n  right: 0;\n  transform: translate(-50%, 0);\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  border-radius: 12px;\n  z-index: 100;\n  width: max-content;\n  min-width: 200px;\n  max-width: 70vw;\n  padding: 12px 16px;\n  background-color: #a3ebb1;\n  border: 2px solid #116530;\n  color: #116530;\n}\n\n.success {\n  background-color: #a3ebb1;\n  border: 2px solid #116530;\n  color: #116530;\n}\n\n.error {\n  color: #d8000c;\n  border: 2px solid #d8000c;\n  background-color: #ffbaba;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"snackbar": `Snackbar_snackbar__StvjI`,
	"success": `Snackbar_success__wt+9B`,
	"error": `Snackbar_error__L+7Ko`
};
export default ___CSS_LOADER_EXPORT___;
