import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment } from "react";

import Spinner from "../Spinner/Spinner";

import styles from "./WarningModalDesign.module.css";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type WarningModalProps = {
  deleteItem?: any;
  onClose: any;
  heading: string;
  title: string;
  isLoading: boolean;
  isOpen: boolean;
};

export default function WarningModalDesign(props: any) {
  const {
    deleteItem = () => {
      // Place Holder Comment
    },
    onClose = () => {
      // Place Holder Comment
    },
    heading = undefined,
    title = undefined,
    isLoading = false,
    isOpen = false,
  } = props;

  const handleDelete = () => {
    deleteItem();
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                {isLoading ? (
                  <div className={styles.spinner}>
                    <Spinner size={"50px"} />
                  </div>
                ) : (
                  <>
                    <button
                      className="flex justify-end absolute right-5 top-4"
                      onClick={onClose}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={25}
                        height={24}
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M6.5 18L18.5 6M6.5 6L18.5 18"
                          stroke="#A3A3A3"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>

                    <DialogTitle
                      as="h3"
                      className=" font-medium text-lg leading-6 text-gray-900 flex items-center gap-4"
                    >
                      <div className="bg-[#FEE2E2] p-2 rounded-3xl ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25px"
                          height="24px"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M12.5 9V11M12.5 15H12.51M5.57183 19H19.4282C20.9678 19 21.9301 17.3333 21.1603 16L14.2321 4C13.4623 2.66667 11.5378 2.66667 10.768 4L3.83978 16C3.06998 17.3333 4.03223 19 5.57183 19Z"
                            stroke="#DC2626"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      {heading}
                    </DialogTitle>

                    <div className="mt-2 ml-[60px]">
                      <p className="text-sm font-normal text-gray-500">
                        {title}
                      </p>
                    </div>

                    <div className="flex justify-end gap-4 mt-4">
                      <button
                        className="rounded-sm border border-[#172029] px-4 py-2 text-[#172029] font-medium text-sm shadow-sm"
                        onClick={onClose}
                      >
                        Cancel
                      </button>
                      <button
                        className="rounded-sm border bg-[#CD335B] px-4 py-2 text-white font-medium text-sm shadow-sm"
                        onClick={handleDelete}
                      >
                        Delete
                      </button>
                    </div>
                  </>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
