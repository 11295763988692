import React from "react";

type Props = {
  noOFcard: number;
};

const ClientCardSkeleton = (props: Props) => {
  const { noOFcard } = props;

  return (
    <>
      {new Array(noOFcard).fill(null).map((_, index) => (
        <div
          key={index}
          className="bg-white rounded-lg flex flex-col h-28 w-72 justify-center px-6"
          style={{
            boxShadow:
              "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div className="flex max-w-sm animate-pulse">
            <div className="flex items-center gap-4">
              <div className="flex-shrink-0">
                <span className="flex justify-center items-center bg-gray-300 rounded-full w-12 h-12" />
              </div>
              <span className="h-2 bg-gray-300 rounded-full w-20"></span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ClientCardSkeleton;
