import { useEffect } from "react";

import styles from "./Page.module.css";

function Page({ children }: Readonly<{ children: React.ReactNode }>) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <section className={styles.page}>{children}</section>;
}

export default Page;
