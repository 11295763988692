import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
};

export const paginationSlice: any = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setCurrentPage: (state: any, action: any) => {
      state.currentPage = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetCurrentPage: (state: any, action: any) => {
      state.currentPage = 1;
    },
  },
});

export const { setCurrentPage, resetCurrentPage } = paginationSlice.actions;

export default paginationSlice.reducer;
