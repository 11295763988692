import axios from "axios";

import { refreshAccessToken } from "./auth";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

// Queue to hold pending requests
let pendingRequests: Array<(token: string) => void> = [];
let isRefreshing = false;
let refreshTokenPromise: Promise<{
  accessToken: string;
  refreshToken: string;
}> | null = null;

const createConfig = () => {
  const token = localStorage.getItem("accessToken");
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

const api = {
  get: async (url: string) => {
    return await axios.get(`${BASE_URL}${url}`, createConfig());
  },
  post: async (url: string, data: any) => {
    return await axios.post(`${BASE_URL}${url}`, data, createConfig());
  },
  put: async (url: string, data: any) => {
    return await axios.put(`${BASE_URL}${url}`, data, createConfig());
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  delete: async (url: string, data: any) => {
    return await axios.delete(`${BASE_URL}${url}`, createConfig());
  },
};

const processQueue = (token: string) => {
  pendingRequests.forEach((callback) => callback(token));
  pendingRequests = [];
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Exclude paths like /login and /register
    const excludedPaths = ["/login", "/register"];
    if (excludedPaths.some((path) => originalRequest.url?.includes(path))) {
      return Promise.reject(error);
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (isRefreshing) {
        // Return a promise that resolves when the token is refreshed
        return new Promise((resolve) => {
          pendingRequests.push((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axios(originalRequest));
          });
        });
      }

      isRefreshing = true;
      refreshTokenPromise =
        refreshTokenPromise ||
        refreshAccessToken(localStorage.getItem("refreshToken") ?? "");

      try {
        const { accessToken, refreshToken: newRefreshToken } =
          await refreshTokenPromise;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", newRefreshToken);
        processQueue(accessToken);
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        isRefreshing = false;
        refreshTokenPromise = null;
        return axios(originalRequest);
      } catch (refreshError: any) {
        isRefreshing = false;
        refreshTokenPromise = null;
        processQueue("");
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  },
);

export default api;
