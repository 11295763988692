// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KPISetting_kpiMapping__frpqJ {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.KPISetting_kpiInputWrap__FWzjn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.KPISetting_kpiInput__QKXF- {
  width: 100px;
}

.KPISetting_addKpiWrap__ZfFB4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin: 10px 0;
  padding: 8px 16px;
  cursor: pointer;
  gap: 8px;
}

.KPISetting_kpiType__oMlz9 {
  max-width: max-content;
}

.KPISetting_kpiActionsContainer__2YXi6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.KPISetting_optionsWrap__EZW71 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.KPISetting_option__R6Z6F {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/KPISettings/KPISetting.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,eAAe;EACf,QAAQ;AACV;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,QAAQ;AACV","sourcesContent":[".kpiMapping {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.kpiInputWrap {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 10px;\n}\n\n.kpiInput {\n  width: 100px;\n}\n\n.addKpiWrap {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: max-content;\n  margin: 10px 0;\n  padding: 8px 16px;\n  cursor: pointer;\n  gap: 8px;\n}\n\n.kpiType {\n  max-width: max-content;\n}\n\n.kpiActionsContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.optionsWrap {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 10px;\n}\n\n.option {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"kpiMapping": `KPISetting_kpiMapping__frpqJ`,
	"kpiInputWrap": `KPISetting_kpiInputWrap__FWzjn`,
	"kpiInput": `KPISetting_kpiInput__QKXF-`,
	"addKpiWrap": `KPISetting_addKpiWrap__ZfFB4`,
	"kpiType": `KPISetting_kpiType__oMlz9`,
	"kpiActionsContainer": `KPISetting_kpiActionsContainer__2YXi6`,
	"optionsWrap": `KPISetting_optionsWrap__EZW71`,
	"option": `KPISetting_option__R6Z6F`
};
export default ___CSS_LOADER_EXPORT___;
