import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { getClientContactsV2 } from "../../services/clients";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../services/common";
import { ContactType } from "../../types/client/ClientTypes";
import Spinner from "../Spinner/Spinner";

import CreateClientContact from "./Contact/Create";
import EditClientContact from "./Contact/Edit";

const ClientContacts: React.FC = () => {
  const [addNewContactPerson, setAddNewContactPerson] =
    useState<boolean>(false);

  const { id } = useParams();
  if (id === undefined) throw Error("Invalid contact");

  const clientId = +id;

  const {
    data,
    isLoading: isLoadingContacts,
    refetch,
  } = useQuery(["contacts", clientId], () => getClientContactsV2(clientId), {
    enabled: !!clientId,
    refetchOnWindowFocus: false,
    onError() {
      displayErrorSnackbar("Error loading client contacts");
    },
  });

  useEffect(() => {
    if (data) {
      setContactPersons(data.data.data.map(mapDataToContacts));
    }
  }, [data]);

  const mapDataToContacts = ({
    email,
    id,
    isPrimary,
    name,
    phone,
    role,
  }: ContactType) => ({
    email,
    id,
    isPrimary,
    name,
    phone,
    role,
  });

  const [contactPersons, setContactPersons] = useState<ContactType[]>(
    data?.data.data.map(mapDataToContacts) ?? [],
  );

  const handleCancel = () => {
    setAddNewContactPerson(false);
  };

  const handleContactDeleted = (id: number) => {
    displaySuccessSnackbar("Contact deleted successfully");
    setContactPersons((contactPersons) => {
      const index = contactPersons.map(({ id }) => id).indexOf(id);
      const copy = [...contactPersons];
      copy.splice(index, 1);
      return copy;
    });
    refetch();
  };

  const handleContactUpdated = (contact: ContactType) => {
    displaySuccessSnackbar("Contact updated successfully");
    setContactPersons((contactPersons) => {
      const index = contactPersons.map(({ id }) => id).indexOf(contact.id);
      const copy = [...contactPersons];
      copy[index] = contact;
      return copy;
    });
    refetch();
  };

  const handleContactAdded = (contact: ContactType) => {
    displaySuccessSnackbar("Contact added successfully");
    setContactPersons((contacts) => [...contacts, contact]);
    setAddNewContactPerson(false);
    refetch();
  };

  return (
    <div className="w-full">
      {isLoadingContacts && <Spinner />}
      {data && (
        <div className="flex flex-col gap-5 max-h-[65vh] overflow-y-auto">
          {contactPersons?.map((person: ContactType) => {
            return (
              <EditClientContact
                key={person.id}
                clientId={clientId}
                person={person}
                onContactDeleted={handleContactDeleted}
                onContactUpdated={handleContactUpdated}
              />
            );
          })}
          {addNewContactPerson && (
            <CreateClientContact
              clientId={clientId}
              cancel={handleCancel}
              onContactAdded={handleContactAdded}
            />
          )}
        </div>
      )}
      {!addNewContactPerson && data && (
        <div className="flex gap-4">
          <button
            className="text-[#D95A5A] flex items-center gap-4 cursor-pointer"
            onClick={() => setAddNewContactPerson(true)}
          >
            <svg
              width={16}
              height={17}
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.9999 2.76331C8.44173 2.76331 8.7999 3.12148 8.7999 3.56331V7.56331H12.7999C13.2417 7.56331 13.5999 7.92148 13.5999 8.36331C13.5999 8.80513 13.2417 9.16331 12.7999 9.16331H8.7999V13.1633C8.7999 13.6051 8.44173 13.9633 7.9999 13.9633C7.55807 13.9633 7.1999 13.6051 7.1999 13.1633V9.16331H3.1999C2.75807 9.16331 2.3999 8.80513 2.3999 8.36331C2.3999 7.92148 2.75807 7.56331 3.1999 7.56331H7.1999V3.56331C7.1999 3.12148 7.55807 2.76331 7.9999 2.76331Z"
                fill="#D95A5A"
              />
            </svg>
            Add Contact
          </button>
        </div>
      )}
    </div>
  );
};

export default ClientContacts;
