import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
};

export const drawer: any = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    openSideBar: (state: any, action: any) => {
      state.isOpen = true;
    },
    closeSidebar: (state: any) => {
      state.isOpen = false;
    },
  },
});

export const { openSideBar, closeSidebar } = drawer.actions;

export default drawer.reducer;
