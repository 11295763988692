import React, { useEffect, useState } from "react";

import CurrrencyInput from "../CurrencyInput/CurrrencyInput";

import styles from "./BudgetCard.module.css";

import "./BudgetCard.css";

const BudgetCard = (props: any) => {
  const {
    campaignBoardBudget,
    onAmountChange,
    onDelete,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    allowDelete = true,
  } = props;
  const currency = "€";
  const [dailyLimitError, setDailyLimitError] = useState<boolean>(false);

  useEffect(() => {
    const budget = parseFloat(
      campaignBoardBudget?.jobboardBudgetAmount?.plannedAmountPerDay || 0,
    );
    const add = parseFloat(
      campaignBoardBudget?.jobboardBudgetAmount?.amountPerDay || 0,
    );

    setDailyLimitError(add > budget);
  }, [campaignBoardBudget?.jobboardBudgetAmount?.amountPerDay]);

  return (
    <div className={styles.parentCard}>
      <div className={styles.cardArea}>
        <div className={styles.head}>
          <div className={styles.headList}>
            <span className={styles.heading}>
              {campaignBoardBudget?.jobBoardName}
            </span>
            {parseFloat(
              campaignBoardBudget?.jobboardBudgetAmount?.amount || 0,
            ) <= 0 && (
              <button onClick={() => onDelete()}>
                <svg
                  className={styles.deleteIcon}
                  width={"20px"}
                  height={"20px"}
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M432 80h-82.38l-34-56.75C306.1 8.827 291.4 0 274.6 0H173.4C156.6 0 141 8.827 132.4 23.25L98.38 80H16C7.125 80 0 87.13 0 96v16C0 120.9 7.125 128 16 128H32v320c0 35.35 28.65 64 64 64h256c35.35 0 64-28.65 64-64V128h16C440.9 128 448 120.9 448 112V96C448 87.13 440.9 80 432 80zM171.9 50.88C172.9 49.13 174.9 48 177 48h94c2.125 0 4.125 1.125 5.125 2.875L293.6 80H154.4L171.9 50.88zM352 464H96c-8.837 0-16-7.163-16-16V128h288v320C368 456.8 360.8 464 352 464zM224 416c8.844 0 16-7.156 16-16V192c0-8.844-7.156-16-16-16S208 183.2 208 192v208C208 408.8 215.2 416 224 416zM144 416C152.8 416 160 408.8 160 400V192c0-8.844-7.156-16-16-16S128 183.2 128 192v208C128 408.8 135.2 416 144 416zM304 416c8.844 0 16-7.156 16-16V192c0-8.844-7.156-16-16-16S288 183.2 288 192v208C288 408.8 295.2 416 304 416z" />
                </svg>
              </button>
            )}
          </div>
        </div>

        <div className={styles.cardContent}>
          <div className={styles.rowContainer}>
            <label>Budget</label>
            <span>
              {currency}{" "}
              {isNaN(
                parseFloat(
                  campaignBoardBudget?.jobboardBudgetAmount
                    ?.plannedAmountPerDay,
                ),
              )
                ? 0
                : parseFloat(
                    campaignBoardBudget?.jobboardBudgetAmount
                      ?.plannedAmountPerDay,
                  ).toFixed(2)}{" "}
              p/d
            </span>
          </div>
          <div className={styles.rowContainer}>
            <label>Add</label>
            <CurrrencyInput
              name="amountPerDay"
              disabled={
                !campaignBoardBudget?.jobboardBudgetAmount?.plannedAmountPerDay
              }
              value={campaignBoardBudget?.jobboardBudgetAmount?.amountPerDay}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onAmountChange(e)
              }
              propStyles={{
                border: dailyLimitError ? "1.5px solid red" : "",
              }}
            />
          </div>
          <span className={styles.limitError}>
            {dailyLimitError &&
              "Added value should not be greater than planned budget"}
          </span>
        </div>

        <div className={styles.month}>
          <span>
            Month {currency}{" "}
            {campaignBoardBudget?.jobboardBudgetAmount?.amount || 0} p/m
          </span>
        </div>
      </div>
    </div>
  );
};

export default BudgetCard;
