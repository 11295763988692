import React from "react";

interface TooltipProps {
  tooltipText: string;
  children: React.ReactNode; // Specify children prop
  isToolTipEnabled: boolean;
  isFullWidth?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  tooltipText,
  children,
  isToolTipEnabled,
  isFullWidth = false,
}) => {
  return (
    <div
      style={{ position: "relative", display: "inline-block" }}
      className={`group ${isFullWidth && "w-full"}`}
    >
      {children}
      {isToolTipEnabled && (
        <div className="hidden  group-hover:flex absolute w-[250px]  text-center justify-center top-full left-[50%] transform -translate-x-1/2 bg-gray-600 z-10 text-white px-3 py-2 rounded-md text-sm mt-2 ">
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
