import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { BiLogInCircle } from "react-icons/bi";
import { FiUserPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { Icons } from "../../assets/icons";
import { images } from "../../assets/img";
import Drawer from "../../components/Drawer";
import Snackbar from "../../components/Snackbar/Snackbar";
import { openSideBar } from "../../redux/drawer";
import { resetCurrentPage } from "../../redux/pagination";
import { setUser } from "../../redux/user";
import { routeNames } from "../../router/routeNames";
import { logout } from "../../services/auth";
import { getUserName } from "../../utils";

function Navbar() {
  const { t, i18n } = useTranslation();
  const user = useSelector((state: any) => state.user);
  const drawerOpen = useSelector((state: any) => state.drawer.isOpen);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const REACT_APP_ENVIRONMENT: string =
    process.env.REACT_APP_ENV || "development";

  const navItems = [
    {
      label: "JobBoards",
      translationKey: "jobBoards",
      to: routeNames.JOBBOARDS,
    },
    {
      label: "Mappings",
      translationKey: "mappings",
      to: routeNames.VACANCY_DATA__MAPPING,
    },
  ];

  const avatarDropdown = [
    {
      label: "Register User",
      translationKey: "registerUser",
      to: routeNames.CREATE_USER,
    },
  ];

  const languages = [
    { name: "English (en)", value: "en", tKey: "enLang" },
    { name: "Dutch (nl)", value: "nl", tKey: "nlLang" },
  ];

  const switchLang = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const handleLogout = () => {
    logout();
    dispatch(
      setUser({
        token: null,
        role: null,
        permissionsList: null,
      }),
    );
    navigate(routeNames.LOGIN);
  };

  const handleGoToHome = () => {
    dispatch(resetCurrentPage());
    navigate(routeNames.DASHBOARD);
  };

  return (
    <>
      <div className={`${drawerOpen ? "block  top-0 left-0" : "hidden"}`}>
        <Drawer />
      </div>
      <nav
        className={`bg-[#1F1F1F] h-20 fixed top-0 z-10  py-4  w-full ${
          drawerOpen ? "hidden" : "block"
        }`}
        style={{
          filter:
            "drop-shadow(0px 1px 3px rgba(255, 255, 255, 0.05)) drop-shadow(0px 0px 1px rgba(255, 255, 255, 0.3))",
        }}
      >
        <div className="mx-auto max-w-8xl px-4 ">
          <div className="relative flex h-16 items-center justify-between ">
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center cursor-pointer">
                <button onClick={handleGoToHome} className="hidden lg:block">
                  <img
                    src={
                      REACT_APP_ENVIRONMENT === "production"
                        ? images.onlineresultlogo
                        : images.offlineresultlogo
                    }
                    alt="onlineResultsLogo"
                  />
                </button>
              </div>
              <div className="hidden sm:ml-12 lg:block">
                <div className="flex space-x-4">
                  {navItems?.map((item, index) => (
                    <NavLink
                      to={item.to}
                      key={`${"" + index}`}
                      className={`text-sm font-light  px-3 py-2
                        ${
                          location.pathname === item.to
                            ? "text-white font-semibold"
                            : "text-[#E1DFEC]"
                        }
                      `}
                    >
                      {t(`${item?.translationKey}`, `${item?.label}`)}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 mr-6">
              <div className="relative ml-3">
                {user?.token && (
                  <Menu>
                    <MenuButton className="flex items-center gap-3 text-gray-300  ">
                      <div className="flex flex-col text-sm group relative">
                        <div className="mx-2 text-gray-300">
                          <span className="hidden lg:block text-gray-300">
                            {getUserName(user, 20)}
                          </span>
                          <span className="lg:hidden block text-gray-300">
                            {getUserName(user, 5)}
                          </span>
                        </div>
                        <div className=" hidden group-hover:flex absolute top-7 -mx-[10rem] sm:-mx-[10rem] w-[25rem]  justify-center">
                          <div className="absolute pt-1  p-2  rounded bg-gray-300 backdrop-filter opacity-90 font-normal text-sm  flex flex-col">
                            <div className="w-[15px] h-[15px]  rotate-45 self-center -mt-[10px] bg-gray-300"></div>
                            {user.userName}
                          </div>
                        </div>
                      </div>
                      <Icons.UserCircle />
                    </MenuButton>
                    <MenuItems className=" absolute  right-0 top-[40px] w-40 rounded-md overflow-hidden shadow z-50">
                      {(user?.role === "SuperAdmin" ||
                        user?.role === "User" ||
                        user?.role === "Admin") &&
                        avatarDropdown?.map((item, index) => (
                          <MenuItem
                            as={"div"}
                            key={`${"" + index}`}
                            className="bg-slate-50 cursor-pointer text-sm p-2 hover:bg-slate-400"
                          >
                            <NavLink to={item.to}>
                              <div className=" flex items-center gap-3 hover:bg">
                                <FiUserPlus size={22} />
                                <span>
                                  {t(item.translationKey, item.label)}
                                </span>
                              </div>
                            </NavLink>
                          </MenuItem>
                        ))}
                      <MenuItem
                        as={"div"}
                        key={"logout"}
                        onClick={handleLogout}
                        className="bg-slate-50 cursor-pointer p-2 text-sm hover:bg-slate-400"
                      >
                        <div className=" flex items-center gap-3 hover:bg">
                          <BiLogInCircle size={22} />
                          <span>Logout</span>
                        </div>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                )}
              </div>

              <div className="relative ml-3">
                <Menu>
                  <MenuButton className="flex items-center gap-3 ml-3">
                    <div className=" text-white">{i18n.language}</div>
                    <img src={images.arrowdown} alt="arrowdown" />
                  </MenuButton>
                  <MenuItems className="absolute  right-0 top-[40px] w-40 rounded-md overflow-hidden shadow z-50">
                    {languages?.map((lang: any, index: number) => (
                      <MenuItem
                        as={"div"}
                        key={`${"" + index}`}
                        className="bg-slate-50 cursor-pointer p-2 hover:bg-slate-400"
                        onClick={() => switchLang(lang?.value)}
                      >
                        {lang?.name}
                      </MenuItem>
                    ))}
                  </MenuItems>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:hidden flex items-center ">
          <div className="flex absolute top-6 left-6 ">
            <button
              onClick={
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                (e) => dispatch(openSideBar())
              }
            >
              <svg
                width="28px"
                height="28px"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="#e5e7eb"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    fill="#e5e7eb"
                    fillRule="evenodd"
                    d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z"
                  />{" "}
                </g>
              </svg>
            </button>
            <Link to={"/"}>
              {" "}
              {/* <img src={images.onlineresultlogo} alt="onlineResultsLogo" /> */}
              <img
                src={
                  REACT_APP_ENVIRONMENT === "production"
                    ? images.onlineresultlogo
                    : images.offlineresultlogo
                }
                alt="offlineResultsLogo"
              />
            </Link>
          </div>
        </div>
      </nav>

      <Snackbar />
      {/* <div className={`h-full ${drawerOpen ? "hidden" : "block"}`}> */}
      <Outlet />
      {/* </div> */}
    </>
  );
}

export default Navbar;
