// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Vacancies_vacanciesHeader__Zj2ar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0px;
}

.Vacancies_vacancyTypeFilter__bdOOy {
  width: 200px;
  text-align: left;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Vacancies/Vacancies.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".vacanciesHeader {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  margin: 30px 0px;\n}\n\n.vacancyTypeFilter {\n  width: 200px;\n  text-align: left;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vacanciesHeader": `Vacancies_vacanciesHeader__Zj2ar`,
	"vacancyTypeFilter": `Vacancies_vacancyTypeFilter__bdOOy`
};
export default ___CSS_LOADER_EXPORT___;
