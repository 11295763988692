import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment } from "react";

import { Icons } from "../../assets/icons";
import { Button } from "../Button/Button";
import Spinner from "../Spinner/Spinner";

import styles from "./WarningModalDesign.module.css";

type Props = {
  handleClick?: any;
  onClose: any;
  heading: string;
  title: string;
  isLoading?: boolean;
  isOpen: boolean;
  modalType: "Delete" | "info";
};

export default function ConfirmationModal(props: Readonly<Props>) {
  const {
    handleClick = () => {
      // Place Holder Comment
    },
    onClose = () => {
      // Place Holder Comment
    },
    heading = undefined,
    title = undefined,
    isLoading = false,
    isOpen = false,
    modalType = "Delete", //Type of the modal either delete or info
  } = props;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                {isLoading ? (
                  <div className={styles.spinner}>
                    <Spinner size={"50px"} />
                  </div>
                ) : (
                  <>
                    <button
                      className="flex justify-end absolute right-5 top-4"
                      onClick={onClose}
                    >
                      <Icons.ModalCloseIcon />
                    </button>

                    <DialogTitle
                      as="h3"
                      className=" font-medium text-lg leading-6 text-gray-900 flex items-center gap-4"
                    >
                      {modalType === "Delete" ? (
                        <Icons.WarningIcon />
                      ) : (
                        <Icons.InfoIcon />
                      )}
                      {heading}
                    </DialogTitle>

                    <div className="mt-2 ml-[60px]">
                      <p className="text-sm font-normal text-gray-500">
                        {title}
                      </p>
                    </div>

                    <div className="flex justify-end gap-4 mt-4">
                      <Button
                        color="tertiary"
                        variant="outline"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                      {modalType === "Delete" ? (
                        <Button color="secondary" onClick={handleClick}>
                          Delete
                        </Button>
                      ) : (
                        <Button color="primary" onClick={handleClick}>
                          Continue
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
