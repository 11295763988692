import React from "react";
import Modal from "react-modal";

import styles from "./BudgetSpModal.module.css";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleCancelCall?: any;
  handleSubmitCall?: any;
  spWarning?: any;
  setSpWarning?: any;
  actualBudget?: any;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxheight: "80vh",
  },
};

const BudgetSpModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    handleCancelCall,
    handleSubmitCall,
    spWarning,
    setSpWarning,
    actualBudget,
  } = props;

  const handleCancel = () => {
    onClose();
    handleCancelCall();
  };

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      className={styles.afterOpen}
      ariaHideApp={false}
    >
      <section className={styles.modalContent}>
        <div className="mt-6 flex flex-col items-center gap-6">
          <h2 className="text-3xl font-bold  ">More Information Required</h2>
          <p className="text-xl">Actual Spend = {actualBudget} </p>
          <p className="text-xl">
            You entered the value greater then fc budget
          </p>
          <input
            type="text"
            value={spWarning?.info}
            onChange={(e) =>
              setSpWarning({ ...spWarning, info: e.target.value })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 "
            placeholder="Enter Comments"
            required
          />
        </div>
        <div className="flex justify-end mt-20">
          <button
            type="button"
            className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={() => handleSubmitCall()}
          >
            Submit
          </button>
        </div>
      </section>
    </Modal>
  );
};

export default BudgetSpModal;
