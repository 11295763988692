import { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch } from "react-redux";

import "./App.css";
import "./i18next";

import { setUser } from "./redux/user";
import AppRouter from "./router/router";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const role = localStorage.getItem("role");
    const permissionsList = localStorage.getItem("permissionsList");
    const userName = localStorage.getItem("userName");
    dispatch(setUser({ token, role, userName, permissionsList }));
  }, []);

  const REACT_APP_ENVIRONMENT: string =
    process.env.REACT_APP_ENV || "development";

  return (
    <div className="min-h-screen bg-white font-sans antialiased overflow-x-hidden overflow-y-auto">
      <HelmetProvider>
        <Helmet>
          <title>
            {REACT_APP_ENVIRONMENT === "production"
              ? "Online Results"
              : "Offline Results"}
          </title>
        </Helmet>
      </HelmetProvider>
      <AppRouter />
    </div>
  );
}

export default App;
