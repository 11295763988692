import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setCurrentPage } from "../../redux/pagination";

function usePagination(totRecords: any, recordsPerPage: any) {
  const currentPage = useSelector((state: any) => state.pagination.currentPage);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = useMemo(
    () => totRecords?.slice(firstIndex, lastIndex),
    [totRecords, firstIndex, lastIndex],
  );

  const totPages = Math.ceil(totRecords?.length / recordsPerPage);

  const numbers = [];
  for (let i = 0; i <= totPages; i++) {
    numbers.push(i);
  }

  const dispatch = useDispatch();
  const prePage = () => {
    if (currentPage !== 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };
  const nextPage = () => {
    if (currentPage !== totPages) {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };
  const pageChange = (id: number) => {
    dispatch(setCurrentPage(id));
  };

  return {
    records,
    totPages,
    numbers,
    currentPage,
    prePage,
    nextPage,
    pageChange,
    lastIndex,
    firstIndex,
  };
}

export default usePagination;
