import { configureStore } from "@reduxjs/toolkit";

import client from "./client";
import clientCountInfo from "./clientCountInfo";
import contact from "./contact";
import drawer from "./drawer";
import feeds from "./feeds";
import pagination from "./pagination";
import recentlogin from "./recentlogin";
import snackbarReducer from "./snackbar";
import userReducer from "./user";
import vacancie from "./vacancieupdate";

export default configureStore({
  reducer: {
    snackbar: snackbarReducer,
    user: userReducer,
    vacancie: vacancie,
    pagination: pagination,
    recentlogin: recentlogin,
    drawer: drawer,
    client: client,
    contact: contact,
    feeds,
    clientCountInfo: clientCountInfo,
  },
});
