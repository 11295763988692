import CurrencyInput from "react-currency-input-field";

import styles from "./CurrencyInput.module.css";

type CurrencyInputPropsType = {
  value?: any;
  onChange?: any;
  onFocus?: any;
  onBlur?: any;
  currency?: string;
  propStyles?: any;
  propIconStyles?: any;
  disabled?: boolean;
  name?: string;
  title?: string;
};

function CurrrencyInput(props: Readonly<CurrencyInputPropsType>) {
  const {
    value,
    onChange,
    currency = "€",
    propStyles,
    propIconStyles,
    disabled = false,
    name,
    title,
    onFocus,
    onBlur,
  } = props;

  const currentValue = value || "";

  return (
    <div
      className={`${styles.currencyInput}`}
      style={{
        ...propStyles,
      }}
    >
      {propIconStyles ? (
        <span
          style={{
            ...propIconStyles,
          }}
        >
          {currency}
        </span>
      ) : (
        currency
      )}

      <CurrencyInput
        className={styles.inputField}
        name={name}
        disabled={disabled}
        value={currentValue}
        decimalsLimit={3}
        allowDecimals={true}
        allowNegativeValue={false}
        decimalSeparator={","}
        groupSeparator={"."}
        onValueChange={(value: any, name: any) => {
          const finalValue = value ?? "";
          if (currentValue !== finalValue) {
            const e: any = {
              target: { value: finalValue, name },
            };
            onChange(e);
          }
        }}
        title={title}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
}

export default CurrrencyInput;
