import React from "react";

type PaginationProps = {
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  totalPages?: number;
  onChange: (newDetails: any) => void;
  sendParentSelectedRow?: any; // Make this property optional
  sendParentRowClicked?: any;
};

const Pagination = (props: Readonly<PaginationProps>) => {
  const {
    pageNumber = 1,
    pageSize = 25,
    totalRecords,
    onChange = () => {
      // Place holder comment
    },
    sendParentSelectedRow,
    sendParentRowClicked,
  } = props;

  const totalPages = Math.ceil(totalRecords / pageSize);
  const currentPageGroup = Math.ceil(pageNumber / 10);
  const startPage = (currentPageGroup - 1) * 10 + 1;
  const endPage = Math.min(currentPageGroup * 10, totalPages);
  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }
  const goToPage = (page: any) => {
    onChange({
      pageSize,
      pageNumber: page,
    });

    if (sendParentSelectedRow) {
      sendParentSelectedRow(null);
    }
    if (sendParentRowClicked) {
      sendParentRowClicked(false);
    }
  };

  return (
    <div>
      <nav
        className="flex items-center justify-between pt-4 flex-col sm:flex-row"
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-500  dark:text-gray-400 flex items-center gap-4">
          <span>Showing</span>
          <span
            className={`font-semibold text-gray-900 ${
              totalPages !== 0 && "hidden"
            }`}
          >
            <span>0</span>
            <span> - </span>
            <span>0</span>
          </span>
          <span
            className={`font-semibold text-gray-900 ${
              totalPages === 0 && " hidden "
            }`}
          >
            <span> {(pageNumber - 1) * pageSize + 1}</span>
            <span> -</span>

            <span>
              {pageNumber !== totalPages && pageNumber * pageSize}
              {totalPages === pageNumber && totalRecords}
            </span>
          </span>
          <span> of</span>
          <span className="font-semibold text-gray-900 ">{totalRecords}</span>
        </span>
        <ul className="inline-flex -space-x-px text-sm h-8">
          <li className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border-2 border-gray-300">
            <button
              disabled={pageNumber <= 1}
              onClick={() => goToPage(pageNumber - 1)}
            >
              {"<"}
            </button>
          </li>
          {pages.map((page, index) => (
            <button
              key={`${"" + index}`}
              className={`flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 ${
                pageNumber === page
                  ? "bg-red-200 border-2 border-solid border-[#CD335B]"
                  : "bg-white border-2 border-solid border-gray-300"
                // eslint-disable-next-line indent
              }`}
              onClick={() => goToPage(page)}
            >
              {page}
            </button>
          ))}
          <button
            className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border-2 border-gray-300"
            disabled={pageNumber >= totalPages}
            onClick={() => goToPage(pageNumber + 1)}
          >
            {">"}
          </button>
        </ul>
      </nav>
    </div>
  );
};
export default Pagination;
