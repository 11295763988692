// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Campaigns_addBtnWrap__lA3Qc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  column-gap: 6px;
  margin: 10px 0;
  cursor: pointer;
}

.Campaigns_campaignDate__12xD4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

.Campaigns_dateInput__u\\+\\+JL {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  outline: none;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Campaigns/Campaigns.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd","sourcesContent":[".addBtnWrap {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: max-content;\n  column-gap: 6px;\n  margin: 10px 0;\n  cursor: pointer;\n}\n\n.campaignDate {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px;\n}\n\n.dateInput {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  align-items: center;\n  outline: none;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addBtnWrap": `Campaigns_addBtnWrap__lA3Qc`,
	"campaignDate": `Campaigns_campaignDate__12xD4`,
	"dateInput": `Campaigns_dateInput__u++JL`
};
export default ___CSS_LOADER_EXPORT___;
