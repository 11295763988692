// Creating separate routeNames object so that if any name needs to be changed in future, we don't have replace the name in the entire app
export const routeNames = {
  DASHBOARD: "/",
  BUDGET_PLAN: "/budget-planning",
  CREATE_CLIENT: "/create-client",
  CREATE_CHANNEL: "/create-channel",
  JOBBOARDS: "/job-boards",
  CREATE_JOBBOARDS: "/job-boards/create",
  XML_MATCHING: "/xml-matching",
  INSIGHTS: "/client/insights",
  CREATE_USER: "/create-user",
  LOGIN: "/login",
  RESET_PASSWORD: "/password-reset",
  FORGOT_PASSWORD: "/forgot-password",
  CLIENT: "/client",
  CLIENTDASHBOARD: "/client/dashboard",
  VACANCY_DATA__MAPPING: "/field-mapping",
  EDIT_CLIENT: "edit-client",
};
