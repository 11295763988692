import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ClientCard from "../../components/ClientCard/ClientCard";
import ClientCardSkeleton from "../../components/ClientCard/ClientCardSkeleton";
import useDebounce from "../../components/useDebounce/useDebounce";
import { setCurrentPage } from "../../redux/pagination";
import { openSnackbar } from "../../redux/snackbar";
import { routeNames } from "../../router/routeNames";
import { getPaginatedClients } from "../../services/clients";

import styles from "./dashboard.module.css";

function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clients, setClients] = useState<any>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const disptach = useDispatch();

  const currentPage = useSelector(
    (state: any) => state?.pagination?.currentPage,
  );

  const pageHandler = (page: number) => {
    disptach(setCurrentPage(page + 1));
  };

  const debounce = useDebounce(searchInput, 500);

  useEffect(() => {
    if (debounce) {
      setCurrentPage(1);
    }
  }, [debounce]);
  const { data, isLoading, isError, error } = useQuery(
    ["allClients", currentPage, debounce],
    () => getPaginatedClients(currentPage, 20, debounce),
    {
      refetchOnWindowFocus: false,
    },
  );

  const isRecentLogin = useSelector(
    (state: any) => state.recentlogin.isRecentLogin,
  );

  useEffect(() => {
    if (isRecentLogin) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (data) {
      filterClients();
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      dispatch(
        openSnackbar({
          message: "Something went wrong",
          type: "error",
        }),
      );
    }
  }, [error]);

  const filterClients = () => {
    const filteredClients = data?.data?.data;
    setClients(filteredClients);
  };

  return (
    <div className=" bg-[#F5F5F5] h-full mt-20">
      <div
        className=" bg-[#1F1F1F] "
        style={{
          minHeight: "20vh",
        }}
      >
        <div
          className="pt-5 flex flex-col sm:flex-row
        justify-between sm:items-center  w-10/12 max-w-7xl mx-auto px-4"
        >
          <h2 className="text-white text-[30px] font-bold tracking-wide">
            Clients
          </h2>
          <div
            className="flex
            flex-col sm:flex-row
            sm:items-center gap-3
            mb-4 sm:mb-0
            relative
         "
          >
            <div
              className="flex items-center bg-white border-[#D4D4D4] rounded shadow px-4 py-2 gap-2"
              style={{
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <svg
                width={21}
                height={20}
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.78516 4C6.57602 4 4.78516 5.79086 4.78516 8C4.78516 10.2091 6.57602 12 8.78516 12C10.9943 12 12.7852 10.2091 12.7852 8C12.7852 5.79086 10.9943 4 8.78516 4ZM2.78516 8C2.78516 4.68629 5.47145 2 8.78516 2C12.0989 2 14.7852 4.68629 14.7852 8C14.7852 9.29583 14.3744 10.4957 13.6759 11.4765L18.4923 16.2929C18.8828 16.6834 18.8828 17.3166 18.4923 17.7071C18.1017 18.0976 17.4686 18.0976 17.078 17.7071L12.2617 12.8907C11.2809 13.5892 10.081 14 8.78516 14C5.47145 14 2.78516 11.3137 2.78516 8Z"
                  fill="#A3A3A3"
                />
              </svg>

              <input
                placeholder={`${t("searchClients", "Search Clients")}`}
                className="text-[#737373] text-sm font-medium outline-none placeholder:text-[#737373] placeholder:text-sm placeholder:tracking-wider"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                  dispatch(setCurrentPage(1));
                }}
              />
            </div>

            <button
              className="flex items-center bg-[#CD335B] px-4 py-2 rounded text-white text-sm gap-1 font-medium group"
              onClick={() => navigate(routeNames.CREATE_CLIENT)}
            >
              <svg
                width={21}
                height={20}
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.7852 3C11.3374 3 11.7852 3.44772 11.7852 4V9H16.7852C17.3374 9 17.7852 9.44772 17.7852 10C17.7852 10.5523 17.3374 11 16.7852 11H11.7852V16C11.7852 16.5523 11.3374 17 10.7852 17C10.2329 17 9.78516 16.5523 9.78516 16V11H4.78516C4.23287 11 3.78516 10.5523 3.78516 10C3.78516 9.44771 4.23287 9 4.78516 9L9.78516 9V4C9.78516 3.44772 10.2329 3 10.7852 3Z"
                  fill="white"
                />
              </svg>
              Add Client
            </button>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-wrap justify-center gap-7 w-10/12 max-w-7xl mx-auto ${styles.centered}`}
      >
        {isLoading ? (
          <ClientCardSkeleton noOFcard={20} />
        ) : (
          <>
            {!isRecentLogin && clients?.length ? (
              clients?.map((client: any, index: any) => (
                <ClientCard key={`${"" + index}`} client={client} />
              ))
            ) : (
              <div className="font-bold text-white text-[24px]">
                No Client Data Found.
              </div>
            )}
          </>
        )}
        {!isLoading && clients?.length ? (
          <div className=" flex items-center gap-3 text-sm w-full justify-between">
            <div>
              <span>Showing </span>
              <span>
                {currentPage == 1 ? 1 : (currentPage - 1) * 20 + 1} -{" "}
                {currentPage * 20 > data?.data?.totalRecords
                  ? data?.data?.totalRecords
                  : currentPage * 20}{" "}
                of {data?.data?.totalRecords}
              </span>
            </div>

            <ReactPaginate
              forcePage={currentPage - 1 < 0 ? 0 : currentPage - 1}
              breakLabel="..."
              nextLabel={<IoIosArrowForward className="w-5 h-5 " />}
              onPageChange={(e) => pageHandler(e.selected)}
              pageRangeDisplayed={3}
              pageCount={Math.ceil(data?.data?.totalRecords / 20)}
              previousLabel={<IoIosArrowBack className="w-5 h-5" />}
              renderOnZeroPageCount={null}
              containerClassName="flex"
              pageLinkClassName="w-full h-full flex  justify-center items-center"
              nextLinkClassName="w-full h-full flex  justify-center items-center"
              previousLinkClassName="w-full h-full flex  justify-center items-center"
              pageClassName="border  border-[#D4D4D4] bg-white w-12 h-10 flex items-center justify-center"
              breakClassName="border border-[#D4D4D4] bg-white w-12 h-10 flex items-center justify-center"
              activeClassName="font-bold bg-[#cd335b14] text-[#CD335B] border-1 border-[CD335B]"
              previousClassName="border  border-[#D4D4D4] rounnded bg-white w-12 h-10 flex items-center justify-center rounded-tl-lg rounded-bl-lg"
              nextClassName="border  border-[#D4D4D4] bg-white w-12 h-10 flex items-center justify-center
              rounded-tr-lg rounded-br-lg"
              disabledClassName="text-gray-500 cursor-not-allowed"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
