// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateCampaign_noDisplay__jFv4m {
  display: none;
}
.CreateCampaign_inputRow__QDWss {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 16px;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
  margin-top: 50px;
}
.CreateCampaign_inputRowBudget__stZyK {
  display: flex;
  gap: 100px;
}
.CreateCampaign_completeSection__jua90 {
  color: green;
}
.CreateCampaign_stepBody__b\\+k3W {
  width: 100%;
  padding: 0 0 0 calc(30px + 12px + 12px + 10px);
  position: relative;
  top: 0;
  left: -53px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CreateCampaign/CreateCampaign.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;EACX,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,UAAU;AACZ;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;EACX,8CAA8C;EAC9C,kBAAkB;EAClB,MAAM;EACN,WAAW;AACb","sourcesContent":[".noDisplay {\n  display: none;\n}\n.inputRow {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  column-gap: 16px;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 50px;\n  margin-top: 50px;\n}\n.inputRowBudget {\n  display: flex;\n  gap: 100px;\n}\n.completeSection {\n  color: green;\n}\n.stepBody {\n  width: 100%;\n  padding: 0 0 0 calc(30px + 12px + 12px + 10px);\n  position: relative;\n  top: 0;\n  left: -53px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noDisplay": `CreateCampaign_noDisplay__jFv4m`,
	"inputRow": `CreateCampaign_inputRow__QDWss`,
	"inputRowBudget": `CreateCampaign_inputRowBudget__stZyK`,
	"completeSection": `CreateCampaign_completeSection__jua90`,
	"stepBody": `CreateCampaign_stepBody__b+k3W`
};
export default ___CSS_LOADER_EXPORT___;
