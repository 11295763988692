import { AxiosError } from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { Button } from "../../components/Button/Button";
import {
  Checkbox,
  NumberInput,
  SelectInput,
  TextInput,
  UrlInput,
} from "../../components/FormFields/FormFields";
import { setClient } from "../../redux/client";
import { routeNames } from "../../router/routeNames";
import { addClientV2, getClientsV2 } from "../../services/clients";
import { displayErrorSnackbar } from "../../services/common";
import { ClientType } from "../../types/client/ClientTypes";

const ClientInfo = () => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<any>({});
  const [allParents, setAllParents] = useState<Array<ClientType>>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleInputChange = (key: string, value: any) => {
    removeValidationError(key);

    dispatch(setClient({ name: key, value: value }));
  };

  const removeValidationError = (key: string) => {
    setErrors((errors: Record<string, string[]>) => {
      delete errors[key];

      return {
        ...errors,
      };
    });
  };

  const clientData: ClientType = useSelector((state: any) => state.client);
  const {
    data: clients,
    isLoading: isLoadingParents,
    refetch,
  } = useQuery(
    ["getClients", pageNumber],
    () => getClientsV2(pageNumber, 100),
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setAllParents([...allParents, ...response.data.data]);
      },
      onError() {
        displayErrorSnackbar("Error loading parents");
      },
    },
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    AddClientMutate();
  };

  const { mutate: AddClientMutate, isLoading: addClientLoader } = useMutation(
    () => {
      return addClientV2(clientData);
    },
    {
      onSuccess(data) {
        navigate(`/${routeNames.EDIT_CLIENT}/${data.data.id}`);
      },
      onError(e: AxiosError) {
        setErrors(e.response?.data);
      },
    },
  );

  return (
    <form className="mt-10 grid grid-cols-1 gap-5" onSubmit={handleSubmit}>
      <div className=" grid sm:grid-cols-2 gap-8">
        <TextInput
          label="Name"
          name="name"
          value={clientData.name}
          handleChange={(e) => handleInputChange("name", e.target.value)}
          errors={errors["name"]}
        />
        <SelectInput
          label="For"
          name="for"
          value={
            clientData.for
              ? {
                  value: clientData.for,
                  label: clientData.for,
                }
              : undefined
          }
          options={[
            { value: "Prepaid", label: "Prepaid" },
            { value: "Postpaid", label: "Postpaid" },
          ]}
          handleChange={(selectedOption: any) =>
            handleInputChange("for", selectedOption?.value)
          }
        />
      </div>
      <div className="grid sm:grid-cols-2 gap-8">
        <TextInput
          label={t("Logo URL")}
          name="imagePath"
          value={clientData.imagePath}
          handleChange={(e) => handleInputChange("imagePath", e.target.value)}
        />

        <div className=" grid grid-cols-1 gap-2">
          <label className=" text-[#42526E] text-sm font-semibold ">
            {t("Parent")}
          </label>
          <Select
            isClearable={true}
            isLoading={isLoadingParents}
            isDisabled={isLoadingParents}
            name="parentID"
            placeholder={"Select parent client"}
            value={
              clientData.parentID && {
                label: (() => {
                  const client = clients?.data.data.filter(
                    ({ id }: any) => id === clientData.parentID,
                  )[0];

                  return client?.name ?? client?.id;
                })(),
                value: clientData.parentID,
              }
            }
            onChange={(e) => handleInputChange("parentID", e ? e.value : null)}
            options={
              clients &&
              clients.data.data.map((client: any) => ({
                value: client?.id,
                label: client?.name ?? client?.id,
              }))
            }
            onMenuScrollToBottom={() => {
              if (clients?.data.totalRecords > allParents.length) {
                setPageNumber((prev) => prev + 1);
                refetch();
              }
            }}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: "#E5EAF0",
                borderRadius: "5px",
                outline: "none",
                fontSize: "16px",
                width: "100%",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#CDCDCD",
                primary: "#CDCDCD",
              },
            })}
          />
        </div>
      </div>
      <div className="grid sm:grid-cols-2 gap-8">
        <UrlInput
          label="RecruitNow URL"
          sublabel="(https://example.recruitnowcockpit.nl)"
          name="recruitNowUrl"
          value={clientData.recruitNowUrl}
          handleChange={(e) =>
            handleInputChange("recruitNowUrl", e.target.value)
          }
          errors={errors["recruitNowUrl"]}
        />
        <TextInput
          label="RecruitNow API Key"
          name="recruitNowApiKey"
          value={clientData.recruitNowApiKey}
          handleChange={(e) =>
            handleInputChange("recruitNowApiKey", e.target.value)
          }
          errors={errors["recruitNowApiKey"]}
        />
      </div>
      <div className="grid sm:grid-cols-2 gap-8">
        <Checkbox
          checked={clientData.marginIncluded}
          handleChange={(e) =>
            handleInputChange("marginIncluded", e.target.checked)
          }
          label="Include Margin"
          name="marginIncluded"
        />
        <Checkbox
          checked={clientData.invoiceOnlineResult}
          handleChange={(e) =>
            handleInputChange("invoiceOnlineResult", e.target.checked)
          }
          label="Invoice OnlineResults"
          name="invoiceOnlineResult"
        />
      </div>
      <div className="grid sm:grid-cols-2 gap-8">
        <Checkbox
          checked={clientData.webhookEnabled}
          handleChange={(e) =>
            handleInputChange("webhookEnabled", e.target.checked)
          }
          label="RecruitNow Webhooks Enabled"
          name="webhookEnabled"
        />
        <Checkbox
          handleChange={(e) =>
            handleInputChange("overspendAllowed", e.target.checked)
          }
          label="Overspend Allowed"
          checked={clientData.overspendAllowed}
          name="overspendAllowed"
        />
      </div>
      {clientData.overspendAllowed && (
        <div className="grid sm:grid-cols-2 gap-8">
          <NumberInput
            handleChange={(e) =>
              handleInputChange("percentage", +e.target.value)
            }
            label="Percentage"
            name="percentage"
            value={clientData.percentage}
          />
        </div>
      )}

      <Button
        color="primaryLight"
        fullWidth
        size="large"
        type="submit"
        loading={addClientLoader}
      >
        Save & Continue
      </Button>
    </form>
  );
};

export default ClientInfo;
