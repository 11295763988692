import { createSlice } from "@reduxjs/toolkit";

import { ContactType } from "../types/client/ClientTypes";

interface ContactChangePayload {
  index: number;
  name: keyof ContactType;
  value: string | boolean;
}

const initialContactState: ContactType[] = [];

export const contactSlice = createSlice({
  name: "contact",
  initialState: initialContactState,
  reducers: {
    setContactPersons: (state, action) => {
      const oldDrafts = state.filter((item) => item.isEdited);
      const updatedData = [...action.payload, ...oldDrafts];
      return updatedData;
    },
    addNewContact: (state, action) => {
      state.push(action.payload);
    },
    removeContact: (state, action) => {
      state.splice(action.payload.index, 1);
    },
    handleContactChange: (
      state: ContactType[],
      action: { payload: ContactChangePayload },
    ) => {
      const { index, name, value } = action.payload;
      if (index >= 0 && index < state.length) {
        state[index] = {
          ...state[index],
          isEdited: true,
          [name]: value as ContactType[keyof ContactType],
        };
      }
    },
  },
});

export const {
  setContactPersons,
  addNewContact,
  removeContact,
  handleContactChange,
} = contactSlice.actions;

export default contactSlice.reducer;
