import React, { useState } from "react";
import Modal from "react-modal";
import { useQuery } from "react-query";

import { Icons } from "../../../assets/icons";
import { getVacancyById } from "../../../services/vacancies";
import Spinner from "../../Spinner/Spinner";

import styles from "./VacancyDetailsModal.module.css";

function VacancyDetailsModal(props: any) {
  const { selectedVacancy, onClose } = props;
  const [vacancyData, setVacancyData] = useState<any>({});

  const { isLoading } = useQuery(
    ["vacancy", selectedVacancy],
    () => getVacancyById(selectedVacancy),
    {
      onSuccess(data) {
        let vacancy = data?.data;
        vacancy = {
          ...vacancy,
          actual: vacancy?.actual?.split("T")?.[0],
          salary: vacancy?.salary?.replace("\r\n\t\t\t\t\t\t\t", " "),
          description: vacancy?.description?.replace("\r\n\t\t\t\t\t\t\t", " "),
        };

        setVacancyData(vacancy);
      },
    },
  );

  const customStyles = {
    overlay: {
      zIndex: 999,
    },
    content: {
      top: "50%",
      left: "calc(50% + 100px)",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxHeight: "80vh",
    },
  };

  return (
    <Modal
      style={customStyles}
      isOpen={props?.isOpen}
      className={styles.afterOpen}
      ariaHideApp={false}
    >
      {isLoading ? (
        <div className={styles.loadingContiner}>
          <h3>Loading vacancies details...</h3>
          <Spinner />
        </div>
      ) : (
        <section className={styles.modalContent}>
          <div className={styles.header}>
            <div className={styles.headerContentWrap}>
              <button className={styles.closeBtn} onClick={onClose}>
                <Icons.CloseIcon />
              </button>
              <div className={styles.titleWrap}>
                <h2>{vacancyData?.title}</h2>
                <div>
                  {vacancyData?.link ? (
                    <a
                      href={vacancyData.link}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <div className={styles.linkBtn}>
                        Link
                        <svg
                          viewBox="0 0 512 512"
                          width={"12px"}
                          height={"12px"}
                          fill={"white"}
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z" />
                        </svg>
                      </div>
                    </a>
                  ) : (
                    "No Link"
                  )}
                </div>
                <div>
                  {vacancyData?.linkApply ? (
                    <a
                      href={vacancyData.linkApply}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <div className={styles.linkBtn}>
                        Apply Link
                        <svg
                          viewBox="0 0 512 512"
                          width={"12px"}
                          height={"12px"}
                          fill={"white"}
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z" />
                        </svg>
                      </div>
                    </a>
                  ) : (
                    "No Apply Link"
                  )}
                </div>
              </div>
              <div className={styles.headerMeta}>
                <span>
                  {vacancyData?.vacancyLocation?.[0]?.city}
                  {vacancyData?.vacancyLocation?.[0]?.district &&
                    `, ${vacancyData?.vacancyLocation?.[0]?.district}`}
                  {vacancyData?.vacancyLocation?.[0]?.country &&
                    `, ${vacancyData?.vacancyLocation?.[0]?.country}`}
                </span>
                <span>Publish Date: {vacancyData?.actual || "--"}</span>
              </div>
            </div>
          </div>

          {vacancyData && (
            <section className={styles.vacancyDetails}>
              <div className={styles.displayRow}>
                <div className={styles.displayItem}>
                  <label>Position</label>
                  <span>{vacancyData?.position || "--"}</span>
                </div>
                <div className={styles.displayItem}>
                  <label>Field</label>
                  <span>{vacancyData?.field || "--"}</span>
                </div>
              </div>
              <div className={styles.displayRow}>
                <div className={styles.displayItem}>
                  <label>Company</label>
                  <span>{vacancyData?.company || "--"}</span>
                </div>
                <div className={styles.displayItem}>
                  <label>Department</label>
                  <span>{vacancyData?.department || "--"}</span>
                </div>
              </div>
              <div className={styles.displayRow}>
                <div className={styles.displayItem}>
                  <label>Contract Type</label>
                  <span>{vacancyData?.contractType || "--"}</span>
                </div>
                <div className={styles.displayItem}>
                  <label>Contact Hours</label>
                  <span>{vacancyData?.contractHours || "--"}</span>
                </div>
              </div>
              <div className={styles.displayRow}>
                <div className={styles.displayItem}>
                  <label>Salary</label>
                  <span>{vacancyData?.salary || "--"}</span>
                </div>
                <div className={styles.displayItem}>
                  <label>Experience</label>
                  <span>{vacancyData?.experience || "--"}</span>
                </div>
              </div>

              <div className={styles.desecriptionWrap}>
                <label>Description</label>
                <div
                  className={styles.desrciption}
                  dangerouslySetInnerHTML={{ __html: vacancyData?.description }}
                />
              </div>
            </section>
          )}
        </section>
      )}
    </Modal>
  );
}

export default VacancyDetailsModal;
