import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Campaigns from "../components/Campaigns/Campaigns";
import ClientJobBoards from "../components/ClientJobBoards/ClientJobBoards";
import ClientContacts from "../components/EditClient/ClientContacts";
import ClientFeed from "../components/EditClient/ClientFeed";
import ClientInfo from "../components/EditClient/ClientInfo";
import EditClient from "../components/EditClient/EditClient";
import JobBoardCampaigns from "../components/JobBoardCampaigns/JobBoardCampaigns";
import CreateJobBoard from "../components/JobBoards/CreateJobBoard/CreateJobBoard";
import JobBoardsTable from "../components/JobBoards/JobBoardsTable/JobBoardsTable";
import Vacancies from "../components/Vacancies/Vacancies";
import Navbar from "../layouts/Navbar/Navbar";
import SideBarLayout from "../layouts/SideBarLayout";
import BudgetPlan from "../pages/BudgetPlan/BudgetPlan";
import Client from "../pages/Client/client";
import ClientDashboard from "../pages/ClientDashboard/ClientDashboard";
import CreateClient from "../pages/CreateClient/CreateClient";
import CreateUser from "../pages/CreateUser/createUser";
import Dashboard from "../pages/Dashboard/dashboard";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import JobBoards from "../pages/JobBoards/JobBoards";
import Login from "../pages/Login/login";
import Resetpassword from "../pages/Resetpassword";
import VacancyMapping from "../pages/VacancyMapping/VacancyMapping";

import PrivateRoute from "./PrivateRoute";
import { routeNames } from "./routeNames";

const routes = [
  { path: routeNames.DASHBOARD, element: <Dashboard /> },
  {
    path: `${routeNames.BUDGET_PLAN}/:id`,
    element: (
      <SideBarLayout>
        <BudgetPlan />
      </SideBarLayout>
    ),
  },

  {
    element: <JobBoards />,
    children: [
      {
        path: `${routeNames.JOBBOARDS}/`,
        element: <JobBoardsTable />,
      },
      { path: `${routeNames.JOBBOARDS}/create`, element: <CreateJobBoard /> },

      {
        path: `${routeNames.JOBBOARDS}/:id/jobboards-campaigns`,
        element: <JobBoardCampaigns />,
      },
    ],
  },
  {
    element: (
      <SideBarLayout>
        <Client />
      </SideBarLayout>
    ),
    children: [
      { path: `${routeNames.CLIENT}/:id`, element: <Campaigns /> },
      // { path: `${routeNames.CLIENT}/:id/campaigns`, element: <Campaigns /> },
      { path: `${routeNames.CLIENT}/:id/vacancies`, element: <Vacancies /> },
      {
        path: `${routeNames.CLIENT}/:id/client-jobboards`,
        element: <ClientJobBoards />,
      },
    ],
  },
  { path: routeNames.CREATE_USER, element: <CreateUser /> },
  { path: routeNames.CREATE_CLIENT, element: <CreateClient /> },
  {
    element: (
      <SideBarLayout>
        <EditClient />
      </SideBarLayout>
    ),
    children: [
      { path: `${routeNames.EDIT_CLIENT}/:id`, element: <ClientInfo /> },
      {
        path: `${routeNames.EDIT_CLIENT}/:id/contacts`,
        element: <ClientContacts />,
      },
      { path: `${routeNames.EDIT_CLIENT}/:id/feed`, element: <ClientFeed /> },
    ],
  },
  { path: `${routeNames.EDIT_CLIENT}/:id/*`, element: <EditClient /> },

  { path: routeNames.VACANCY_DATA__MAPPING, element: <VacancyMapping /> },

  {
    path: `${routeNames.CLIENTDASHBOARD}/:id`,
    element: (
      <SideBarLayout>
        <ClientDashboard />
      </SideBarLayout>
    ),
  },
];

const protectedRoutes = [
  {
    element: <PrivateRoute />,
    children: routes,
  },
  { path: routeNames.LOGIN, element: <Login /> },
  { path: routeNames.RESET_PASSWORD, element: <Resetpassword /> },
  { path: routeNames.FORGOT_PASSWORD, element: <ForgotPassword /> },
];

const router = createBrowserRouter([
  {
    element: <Navbar />,
    children: protectedRoutes,
  },
]);

function AppRouter() {
  return <RouterProvider router={router} />;
}

export default AppRouter;
