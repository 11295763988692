import { useSelector } from "react-redux";

const Charts = () => {
  const clientCountInfo = useSelector((state: any) => state.clientCountInfo);
  return (
    <div className="flex gap-6 xl:gap-12 flex-col lg:flex-row my-10">
      <div className="flex flex-wrap gap-6">
        <div className="border border-dotted  w-40 flex flex-col justify-center items-center py-3">
          <h2 className="font-bold lg:text-2xl text-white">Vacancies</h2>
          <p className="text-white">{clientCountInfo?.vacanciesCount}</p>
        </div>
        <div className="border border-dotted  w-40 flex flex-col justify-center items-center py-3">
          <h2 className="font-bold lg:text-2xl text-white ">In Campaign</h2>
          <p className=" text-white">{clientCountInfo.inCampaignCount}</p>
        </div>
        <div className="border border-dotted  w-40 flex flex-col justify-center items-center py-3">
          <h2 className="font-bold lg:text-2xl text-white">Open</h2>
          <p className=" text-white">{clientCountInfo.openCount}</p>
        </div>
      </div>
    </div>
  );
};

export default Charts;
