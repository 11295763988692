import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { useQuery } from "react-query";

import Close from "../../assets/img/Close.png";
import Search from "../../assets/img/Search.png";
import { displayErrorSnackbar } from "../../services/common";
import { getMappingVacancyData } from "../../services/mappings";
import Spinner from "../Spinner/Spinner";

import styles from "./MappingVacanciesModal.module.css";

export default function MappingVacanciesModal(props: any) {
  const { mappingId, onClose } = props;

  const { data, error, isLoading } = useQuery(
    ["mappingVacancy", mappingId],
    () => getMappingVacancyData(mappingId),
  );

  useEffect(() => {
    if (error) {
      displayErrorSnackbar("Error loading related vacancies");
    }
  }, [error]);

  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-2xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between items-center my-4">
                  <DialogTitle
                    as="h3"
                    className="text-xl font-semibold leading-6 text-black font-plus-jakarta"
                  >
                    Related Vacancies
                  </DialogTitle>
                  <div className="flex justify-end items-center gap-4 ">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Search"
                        className="w-[289px] h-7 border border-solid border-gray-300 rounded-md pl-9 shadow-sm"
                      />
                      <img
                        src={Search}
                        alt="Search Icon"
                        className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4"
                      />
                    </div>

                    <button onClick={onClose}>
                      <img
                        src={Close}
                        alt="Close Icon"
                        className="w-[20px] h-[20px]"
                      />
                    </button>
                  </div>
                </div>

                {isLoading && <Spinner size={"50px"} />}

                {!isLoading && data?.data?.data?.length === 0 ? (
                  <p className="flex justify-center mt-7">
                    No vacancies available.
                  </p>
                ) : (
                  <section className=" flex flex-col justify-start items-start border-2 border-solid  border-gray-nutral-200 rounded-lg overflow-hidden">
                    <div className="header flex justify-start gap-5 py-3 px-4 w-full bg-[#FAFAFA] border-b-4  ">
                      <div className="srNumber text-left w-12 font-bold text-xs">
                        SR.
                      </div>
                      <div className="column text-left w-1/4 md:w-[calc(25%-13px)] font-bold text-xs">
                        Vacanices
                      </div>
                    </div>

                    <div className="flex flex-col gap-[2px] w-full ">
                      {data?.data?.data?.map((data: any, index: number) => (
                        <div
                          key={`${"" + index}`}
                          className={`${styles.row} ${
                            index % 2 === 0 ? styles.greyRow : styles.whiteRow
                          }
                                `}
                        >
                          <div className="srNumber text-left w-12 font-medium text-xs">
                            {index + 1}.
                          </div>
                          <div className="column  w-1/2 font-medium text-xs">
                            {data?.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
