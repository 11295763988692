import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isRecentLogin: false,
};

export const recentLoginSlice: any = createSlice({
  name: "recentLogin",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    recentLogin: (state: any, action: any) => {
      state.isRecentLogin = true;
    },
  },
});

export const { recentLogin } = recentLoginSlice.actions;

export default recentLoginSlice.reducer;
