// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dropdown_dropdown__QEFz6 {
  width: max-content;
  border-radius: 12px;
}

.Dropdown_dropdownBtn__r\\+n06 {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid grey;
  outline: none;
  min-width: 100%;
  cursor: pointer;
}

.Dropdown_dropdownBtn__r\\+n06:active {
  border: 1px solid black;
}

.Dropdown_dropdownContent__hzE-A {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1000;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 12px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
  margin-bottom: 12px;
  border: 1px solid gray;
  max-height: 250px;
}

.Dropdown_dropdownItem__gBKTu {
  text-align: left;
  list-style: none;
  padding: 6px 12px;
}

.Dropdown_dropdownItem__gBKTu:hover {
  background-color: lightgray;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dropdown/Dropdown.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,OAAO;EACP,QAAQ;EACR,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,aAAa;EACb;;0CAEwC;EACxC,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".dropdown {\n  width: max-content;\n  border-radius: 12px;\n}\n\n.dropdownBtn {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  column-gap: 4px;\n  padding: 12px;\n  border-radius: 12px;\n  border: 1px solid grey;\n  outline: none;\n  min-width: 100%;\n  cursor: pointer;\n}\n\n.dropdownBtn:active {\n  border: 1px solid black;\n}\n\n.dropdownContent {\n  position: absolute;\n  top: calc(100% + 4px);\n  left: 0;\n  right: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  z-index: 1000;\n  box-shadow:\n    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,\n    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;\n  border-radius: 12px;\n  overflow-x: hidden;\n  overflow-y: auto;\n  background-color: white;\n  margin-bottom: 12px;\n  border: 1px solid gray;\n  max-height: 250px;\n}\n\n.dropdownItem {\n  text-align: left;\n  list-style: none;\n  padding: 6px 12px;\n}\n\n.dropdownItem:hover {\n  background-color: lightgray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `Dropdown_dropdown__QEFz6`,
	"dropdownBtn": `Dropdown_dropdownBtn__r+n06`,
	"dropdownContent": `Dropdown_dropdownContent__hzE-A`,
	"dropdownItem": `Dropdown_dropdownItem__gBKTu`
};
export default ___CSS_LOADER_EXPORT___;
