import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { getVacanciesCountbySegment } from "../../services/segments";
import Spinner from "../Spinner/Spinner";

const SegmentButton = (props: any) => {
  const {
    setCompleteConditions,
    setCurrSegmentId,
    currSegmentId,
    item,
    segment,
    loadingSegmentData,
    feedDays,
    feedId,
    segmentUpdated,
    setVacanciesCountonBuildSegment,
  } = props;

  const [vacanciesPerSegment, setVacanciesPerSegment] = useState<number>(0);

  const { isLoading, refetch } = useQuery(
    [
      "segmentVacanciesCount",
      feedId,
      item.value,
      feedDays === null || feedDays === "" ? "" : feedDays,
    ],
    () =>
      getVacanciesCountbySegment(
        feedId,
        item.value,
        feedDays === null || feedDays === "" ? "" : feedDays,
      ),
    {
      onSuccess(data) {
        setVacanciesPerSegment(data);
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [segmentUpdated]);

  return (
    <button
      className={`bg-gray-200  px-2 py-1 rounded flex gap-2 ${
        item.value === currSegmentId &&
        "bg-gray-600  shadow-sm hover:shadow-lg hover:text-gray-200"
      }`}
      key={segment.value}
      onClick={() => {
        if (!loadingSegmentData) {
          setCompleteConditions([]);
          setCurrSegmentId(item.value);
          setVacanciesCountonBuildSegment(0);
        }
      }}
    >
      <span className={`${item.value === currSegmentId && "text-gray-100"}`}>
        {item.label}
      </span>
      <span className={`${item.value === currSegmentId && "text-gray-100"}`}>
        {isLoading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          "(" + vacanciesPerSegment + ")"
        )}
      </span>
    </button>
  );
};

export default SegmentButton;
