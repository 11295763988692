// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Paginator_paginator__rIDQQ {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
  margin: 10px 0;
}

.Paginator_perPage__DH3zx {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  color: gray;
}

.Paginator_pageBtn__eBJGJ {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 6px;
  border: 1px solid grey;
  min-width: 32px;
}

.Paginator_pageNumbers__NOOUQ {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Paginator/Paginator.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".paginator {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  column-gap: 12px;\n  margin: 10px 0;\n}\n\n.perPage {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  column-gap: 8px;\n  color: gray;\n}\n\n.pageBtn {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 8px;\n  padding: 6px;\n  border: 1px solid grey;\n  min-width: 32px;\n}\n\n.pageNumbers {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  column-gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginator": `Paginator_paginator__rIDQQ`,
	"perPage": `Paginator_perPage__DH3zx`,
	"pageBtn": `Paginator_pageBtn__eBJGJ`,
	"pageNumbers": `Paginator_pageNumbers__NOOUQ`
};
export default ___CSS_LOADER_EXPORT___;
