// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CurrencyDisplay_displayInput__nKaAx {
  background: none;
  border: none;
  font-size: 16px;
  max-width: 72px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CurrencyDisplay/CurrencyDisplay.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB","sourcesContent":[".displayInput {\n  background: none;\n  border: none;\n  font-size: 16px;\n  max-width: 72px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"displayInput": `CurrencyDisplay_displayInput__nKaAx`
};
export default ___CSS_LOADER_EXPORT___;
