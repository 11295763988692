import React from "react";
import CurrencyInput from "react-currency-input-field";

import styles from "./CurrencyDisplay.module.css";

type CurrencyDisplayType = {
  value?: string | number;
  currency?: string;
  propStyles?: any;
};

function CurrencyDisplay(props: Readonly<CurrencyDisplayType>) {
  const { value, currency = "€", propStyles } = props;

  return (
    <CurrencyInput
      className={styles.displayInput}
      style={{
        ...propStyles,
      }}
      prefix={currency}
      value={value}
      disabled={true}
      decimalsLimit={3}
      allowDecimals={true}
      allowNegativeValue={false}
      decimalSeparator={","}
      groupSeparator={"."}
    />
  );
}

export default CurrencyDisplay;
