import { useTranslation } from "react-i18next";

import CurrrencyInput from "../CurrencyInput/CurrrencyInput";

type GeneralAgreementsProps = {
  handleBudgetChange: (budget: any) => void;
  handleUpdateClientGenerlAgreement: () => void;
  isTyped: boolean;
  budget: any;
};

const GeneralAgreements = (props: GeneralAgreementsProps) => {
  const { t } = useTranslation();
  const {
    handleBudgetChange,
    handleUpdateClientGenerlAgreement,
    isTyped,
    budget,
  } = props;
  return (
    <div className=" ml-8 mt-2 ">
      <label className="sm:text-lg  text-white  font-semibold ">
        {t("General Agreements")}
      </label>
      <div className=" relative flex mt-5 pr-6 pt-4 gap-3 lg:gap-16">
        <div className="flex flex-col  group">
          <label className=" text-white  font-light text-sm">Budget</label>

          <div className=" -ml-3">
            <CurrrencyInput
              value={budget?.budgetAgreement || ""}
              name={"budgetAgreement"}
              onChange={handleBudgetChange}
              propStyles={{
                width: "150px",
                color: "white",
                fontSize: "22px",
                fontWeight: "600",
                gap: "5px",
              }}
              propIconStyles={{
                color: "gray",
                fontSize: "16px",
              }}
              onBlur={isTyped && handleUpdateClientGenerlAgreement}
            />
          </div>
        </div>
        <div className=" hidden md:flex items-center">
          <div
            className=" bg-[#413f3f] w-[1px]"
            style={{
              height: "80%",
            }}
          ></div>
        </div>
        <div className="flex flex-col">
          <label className=" text-white  font-light text-sm">
            PL This Month
          </label>
          <div></div>
          <div className=" -ml-3">
            <CurrrencyInput
              // value={totalPlannedBudget}
              value={budget?.plThisMonth || ""}
              name={"plThisMonth"}
              disabled={true}
              onChange={handleBudgetChange}
              propStyles={{
                width: "150px",
                color: "white",
                fontSize: "22px",
                fontWeight: "600",
                gap: "5px",
              }}
              propIconStyles={{
                color: "gray",
                fontSize: "16px",
              }}
            />
          </div>
        </div>
        <div className=" hidden md:flex items-center">
          <div
            className=" bg-[#413f3f] w-[1px]"
            style={{
              height: "80%",
            }}
          ></div>
        </div>
        <div className="flex flex-col">
          <label className=" text-white  font-light text-sm">Rest Budget</label>
          <div className=" -ml-3">
            <CurrrencyInput
              value={budget?.restBudget}
              disabled={true}
              propStyles={{
                width: "150px",
                color: "white",
                fontSize: "22px",
                fontWeight: "600",
                gap: "5px",
              }}
              propIconStyles={{
                color: "gray",
                fontSize: "16px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralAgreements;
