// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_authCardContainer__NQ5z3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.login_inputContainer__w9ILQ {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.login_inputContainer__w9ILQ:focus-within {
  border: 2px solid black;
}

.login_inputIcon__9XUjV {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
}

.login_input__h7cJc {
  border: none;
  background-color: none;
  outline: none;
  padding: 12px;
  font-size: 16px;
  border-left: 1px solid gray;
}

.login_loginBtn__DBo-l {
  width: 200px;
  padding: 8px 12px;
}
.login_passwordIcon__Nb7oA {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/login.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,aAAa;EACb,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".authCardContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  height: 70vh;\n}\n\n.inputContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  overflow: hidden;\n}\n\n.inputContainer:focus-within {\n  border: 2px solid black;\n}\n\n.inputIcon {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 50px;\n}\n\n.input {\n  border: none;\n  background-color: none;\n  outline: none;\n  padding: 12px;\n  font-size: 16px;\n  border-left: 1px solid gray;\n}\n\n.loginBtn {\n  width: 200px;\n  padding: 8px 12px;\n}\n.passwordIcon {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authCardContainer": `login_authCardContainer__NQ5z3`,
	"inputContainer": `login_inputContainer__w9ILQ`,
	"inputIcon": `login_inputIcon__9XUjV`,
	"input": `login_input__h7cJc`,
	"loginBtn": `login_loginBtn__DBo-l`,
	"passwordIcon": `login_passwordIcon__Nb7oA`
};
export default ___CSS_LOADER_EXPORT___;
