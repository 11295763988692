import CurrrencyInput from "../CurrencyInput/CurrrencyInput";
import ToolTipNew from "../ToolTipNew/ToolTipNew";

type BudgetDistributionCardProps = {
  icon: any;
  error?: boolean;
  toolTipMessage?: string;
  handleChange: (data: string) => void;
  isTyped: boolean;
  saveChanges: () => void;
  title: string;
  name: string;
  value: any;
  rowNumber: number;
  disableInput?: boolean;
};

const BudgetDistributionCard = (props: BudgetDistributionCardProps) => {
  const {
    icon,
    error,
    toolTipMessage = "",
    handleChange,
    isTyped,
    saveChanges,
    title,
    name,
    value,
    rowNumber,
    disableInput = false,
  } = props;

  if (rowNumber === 1) {
    return (
      <div className="flex  items-center bg-[#2A2A2A] w-full sm:w-52  h-20 relative">
        <div className="w-1/3 h-full  flex justify-center items-center">
          {icon}
        </div>
        <div className=" h-full  flex flex-col justify-center group ">
          {error && <ToolTipNew message={toolTipMessage} />}
          <CurrrencyInput
            value={value || ""}
            name={name}
            onChange={handleChange}
            propStyles={{
              border: error ? "1px solid red" : "",
              color: "white",
              fontSize: "14px",
            }}
            onBlur={isTyped && saveChanges}
          />
          <p className="text-white text-xs font-normal ml-2 -mt-0">{title}</p>
        </div>
      </div>
    );
  }
  if (rowNumber === 2 || rowNumber === 3) {
    return (
      <div className="flex  items-center bg-[#2A2A2A] w-full sm:w-52  h-20 relative">
        <div className="w-1/3 h-full  flex justify-center items-center">
          {icon}
        </div>
        <div className=" h-full  flex flex-col justify-center group ">
          <CurrrencyInput
            value={value || ""}
            name={name}
            onChange={handleChange}
            propStyles={{
              color: "white",
              fontSize: "14px",
              gap: "2px",
            }}
            onBlur={isTyped && saveChanges}
            disabled={disableInput}
          />
          <p className="text-white text-xs font-normal ml-2 -mt-0">{title}</p>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default BudgetDistributionCard;
