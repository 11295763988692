// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createUser_authCardContainer__vvhDu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.createUser_inputContainer__X-X8K {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.createUser_dropdownContainer__YH7O8 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.createUser_inputContainer__X-X8K:focus-within {
  border: 2px solid black;
}

.createUser_inputIcon__Kn1YY {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
}

.createUser_input__g2ecg {
  border: none;
  background-color: none;
  outline: none;
  padding: 12px;
  font-size: 16px;
  border-left: 1px solid gray;
}

.createUser_switchType__U0yMx {
  text-decoration: underline;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CreateUser/createUser.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,aAAa;EACb,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,0BAA0B;EAC1B,eAAe;AACjB","sourcesContent":[".authCardContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  height: 70vh;\n}\n\n.inputContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  overflow: hidden;\n}\n\n.dropdownContainer {\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.inputContainer:focus-within {\n  border: 2px solid black;\n}\n\n.inputIcon {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 50px;\n}\n\n.input {\n  border: none;\n  background-color: none;\n  outline: none;\n  padding: 12px;\n  font-size: 16px;\n  border-left: 1px solid gray;\n}\n\n.switchType {\n  text-decoration: underline;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authCardContainer": `createUser_authCardContainer__vvhDu`,
	"inputContainer": `createUser_inputContainer__X-X8K`,
	"dropdownContainer": `createUser_dropdownContainer__YH7O8`,
	"inputIcon": `createUser_inputIcon__Kn1YY`,
	"input": `createUser_input__g2ecg`,
	"switchType": `createUser_switchType__U0yMx`
};
export default ___CSS_LOADER_EXPORT___;
