import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Icons } from "../../assets/icons";
import { Button } from "../../components/Button/Button";
import { AuthInput } from "../../components/FormFields/FormFields";
import useQueryParams from "../../components/useQueryParams";
import { openSnackbar } from "../../redux/snackbar";
import { resetPassword } from "../../services/auth";
import { displayErrorSnackbar } from "../../services/common";

import styles from "./resetpassword.module.css";

type Auth = {
  email: string;
  password: string;
  confirmPassword: string;
};
const Resetpassword = () => {
  const [searchParams] = useSearchParams();
  const [form, setForm] = useState<Auth>({
    email: searchParams.get("email") ?? "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState<any>({});
  const [isInvalidToken, setIsInvalidToken] = useState<boolean>(false);

  const queryParams = useQueryParams();

  const navigate = useNavigate();

  const [token, setToken] = useState<string | null>(queryParams.get("token"));
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { mutate, isLoading, data } = useMutation(
    (data: any) => resetPassword(data),
    {
      onError: (e: any) => {
        if (e?.response?.data?.token) {
          displayErrorSnackbar(e?.response?.data?.token);
        } else {
          setErrors(e.response?.data);
        }
      },
    },
  );

  useEffect(() => {
    const token: any = queryParams.get("token");
    setToken(token.replace(/ /g, "+"));
  }, []);

  useEffect(() => {
    if (token === null || token === undefined) {
      setIsInvalidToken(true);
    }
  }, [token]);

  useEffect(() => {
    if (!isLoading && data?.status === 200) {
      handleDisplayMessage("Password reset successfully!", "success");
      navigate("/login");
    }
  }, [isLoading, data]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    removeValidationError(e.target.name);
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const removeValidationError = (key: string) => {
    setErrors((errors: Record<string, string[]>) => {
      delete errors[key];

      return {
        ...errors,
      };
    });
  };

  const handleDisplayMessage = (message: string | null, type: string) => {
    dispatch(
      openSnackbar({
        message,
        type,
      }),
    );
  };

  const handleRestPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate({
      email: form.email,
      password: form.password,
      confirmPassword: form.confirmPassword,
      token,
    });
  };

  return (
    <div className={styles.authCardContainer}>
      {isInvalidToken && (
        <div className="w-full h-full flex flex-col justify-center items-center font-bold text-[1.2rem] text-red-700">
          Invalid Token
        </div>
      )}
      {!isInvalidToken && (
        <div className=" flex flex-col">
          <h2 className="text-3xl font-bold tracking-wide flex justify-center">{`${t(
            "Reset Password",
            "Reset Password",
          )}`}</h2>

          <form
            className=" flex flex-col gap-y-4 mt-5 max-w-md"
            onSubmit={handleRestPassword}
          >
            <AuthInput
              icon={<Icons.UserIcon />}
              name="email"
              handleChange={handleOnChange}
              placeholder="Email"
              type="email"
              value={form.email}
              disabled={true}
              errors={errors["email"]}
            />

            <AuthInput
              icon={<Icons.LockIcon />}
              name="password"
              handleChange={handleOnChange}
              placeholder="Password"
              type={"password"}
              value={form.password}
              errors={errors["password"]}
            />

            <AuthInput
              icon={<Icons.LockIcon />}
              name="confirmPassword"
              handleChange={handleOnChange}
              placeholder="Confirm Password"
              type={"password"}
              value={form.confirmPassword}
              errors={errors["confirmPassword"]}
            />
            <Button
              type="submit"
              color="primary"
              fullWidth
              size="large"
              disabled={isLoading}
              loading={isLoading}
            >
              {t("Reset Password", "Reset Password")}
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Resetpassword;
