const dutch = {
  translation: {
    jobBoards: "Vacaturebanken",
    mappings: "toewijzingen",
    categories: "Categorieën",
    rapport: "Rapport",
    settings: "Instellingen",
    notifications: "Meldingen",

    // Dashboard
    searchClients: "Klanten zoeken",

    // Client card
    vacancies: "Vacatures",
    inCampaign: "In Campagne",
    open: "Open",

    // Auth
    login: "Inloggen",
    email: "E-mail",
    password: "Wachtwoord",
    verifyCode: "Code Verifiëren",
    verificationCode: "Verificatiecode",
    verify: "Verifiëren",
    backToLogin: "Terug naar inloggen?",

    back: "Terug",

    // User Roles
    selectUserRole: "Selecteer Gebruikersrol",
    user: "Gebruiker",
    admin: "Beheerder",
    superAdmin: "Superbeheerder",
    clientLead: "Klantbeheerder",
    channelLead: "Kanaalbeheerder",
    channelSpecialist: "Kanaalspecialist",

    // Create Client
    createClient: "Klant Aanmaken",
    customerBrand: "Klant Merk",
    name: "Naam",
    active: "Actief",
    inactive: "Inactief",
    invoiceOnlineResult: "Factuur OnlineResultaten",
    yes: "Ja",
    no: "Nee",
    prepaid: "Prepaid",
    postpaid: "Postpaid",
    for: "Voor",
    branch: "Vestiging",
    clientContactPersons: "Contact persoon(s) aan klantzijde",
    primary: "Primair",
    phone: "Telefoon",
    onlineResultsManager: "Online Resultaten Manager",
    save: "Opslaan",
    feedName: "Feed Naam",
    feedUrl: "Feed URL",
    addFeed: "Feed Toevoegen",

    // Client
    performance: "Prestatie",
    channels: "Kanalen",
    campaigns: "Campagnes",
    feeds: "Feeds",
    deleteClient: "Klant Verwijderen",

    // Campaigns
    createNewCampaign: "Nieuwe Campagne Aanmaken",
    status: "Status",
    // Campaigns table
    campaign: "Campagne",
    type: "Type",
    createDate: "CreateDate",
    budget: "Budget",
    startDate: "Startdatum",
    endDate: "Einddatum",
    vacanciesIncluded: "Inbegrepen Vacatures",

    // Create Campaign
    showAllCampaigns: "Alle campagnes weergeven",
    buildCampaign: "Nieuwe campagne maken",
    buildCampaignPrecedingMonths:
      "Bouw een nieuwe campagne in voorgaande maanden",
    tracking: "Tracking",
    vacanciesFeeds: "Vacatures Feeds",
    vacanciesInSelection: "Vacatures In Selectie",
    feed: "Feed",
    selectFeed: "Selecteer Feed",
    segment: "Segment",
    theseAreSaveSegments: "Dit zijn opgeslagen segmenten",
    existing: "Bestaand",
    selectChannel: "Selecteer kanaal",

    // Create Segment
    buildNewSegment: "Nieuw segment bouwen",
    buildASegment: "Build a segment",
    segmentName: "Segmentnaam",
    segmentWillFollow: "Segment volgt",
    followingConditions: "van de volgende voorwaarden",
    selectVariable: "Selecteer variabele",
    is: "Is",
    isNot: "Is niet",
    addNewVariable: "Nieuwe variabele toevoegen",
    cancel: "Annuleren",

    // Edit Client
    editClient: "Klant bewerken",

    //Feed
    Feeds: "Voert",
    "Add Feed": "Feed Toevoegen",
    "Show all feeds": "Toon alle feeds",

    //Create Feed
    "Add new Feeds": "Nieuwe feeds toevoegen",

    //Vacancies table
    "Job Title": "Functietitel",
    Area: "Gebied",
    Location: "Plaats",
    "Publication Date": "Publicatie datum",
    "Functinal Group": "Functionele groep",

    //Feeds table
    Type: "Type",
    Vacancies: "Vacatures",

    //Client jobBoards
    "Client JobBoards": "Cliënt Vacaturebanken",

    //Client jobBoars table
    Name: "Naam",

    //Vacancy Mapping
    "Data Mapping": "Gegevens in kaart brengen",

    //Mapping Vacancy Modal
    "Related Vacancies": "Gerelateerde vacatures",

    //Vacancy Mapping Table
    "Field Name": "Veldnaam",
    Category: "Categorie",
    "Field Mapping": "Veld in kaart brengen",
    "Position Name": "Positie naam",
    "Position Mapping": "Positie in kaart brengen",

    //Categories
    "Job Categories": "Categorieën Banen",
    "Add Category": "Categorie Toevoegen",

    //Categories Table
    Categories: "Categorieën",
    "Vacancies in category": "Vacatures in categorie",

    //Budget plan
    "General Agreements": "Maandbudget",
    "Budget Distribution": "Budgetverdeling",
    FC: " Budget per maand",
    PL: "Forecast",
    SP: "Uitgaven",
    Discount: "Korting",
    Rest: "Restbudget",
    "Are you sure you want to leave without saving changes?":
      "Weet u zeker dat u wilt afsluiten zonder de wijzigingen op te slaan?",
  },
};

export default dutch;
