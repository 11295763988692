import React from "react";
import { BiHome } from "react-icons/bi";
import { Link } from "react-router-dom";

type BreadcrumbNavigationItem = {
  icon?: any;
  text: string;
  url: string;
};
type BreadcrumbProps = {
  navigation: BreadcrumbNavigationItem[];
  currentPage: BreadcrumbNavigationItem;
  styleClass?: any;
};
const Breadcrumb = (props: Readonly<BreadcrumbProps>) => {
  const { navigation, currentPage } = props;
  return (
    <div className="flex gap-2 font-semibold p-4 text-[#777]  pl-0">
      <Link
        to={"/"}
        className="flex  justify-center items-center gap-2 hover:text-[#b4b4b4]"
      >
        <BiHome height={28} width={28} />
        <div className="hover:text-[#b4b4b4]">Home</div>
      </Link>

      {navigation.map((nav, index) => {
        return (
          <div
            key={`${"" + index}`}
            className="flex gap-2 hover:text-[#b4b4b4]"
          >
            <div>/</div>
            <Link to={nav.url}>{nav.text}</Link>
          </div>
        );
      })}

      <div>/</div>
      <Link to={currentPage.url} className="opacity-70 cursor-pointer ">
        {currentPage.text}
      </Link>
    </div>
  );
};

export default Breadcrumb;
