import { createSlice } from "@reduxjs/toolkit";

import { ClientType } from "../types/client/ClientTypes";

const initialState: ClientType = {};

export const clientSlice: any = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClient: (state: any, action: any) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
});

export const { setClient } = clientSlice.actions;

export default clientSlice.reducer;
