// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SegmentVacanciesModal_afterOpen__QXTp2 {
  background-color: white;
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  overflow: auto;
  border-radius: 24px;
  outline: none;
  min-width: 50%;
  border-color: black;
  max-height: 80vh;
  overflow-x: hidden;
  padding: 0px 20px;
}
.SegmentVacanciesModal_afterOpen__QXTp2::-webkit-scrollbar {
  display: none;
}

.SegmentVacanciesModal_modalContent__gmCml {
  width: 100%;
  text-align: center;
}
.SegmentVacanciesModal_modalContent__gmCml > h3 {
  margin: 20px auto;
}

.SegmentVacanciesModal_closeBtn__CWV6A {
  position: absolute;
  right: 12px;
  top: 12px;
}
.SegmentVacanciesModal_table__i9v-R {
  padding: 0px 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SegmentVacanciesModal/SegmentVacanciesModal.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,oCAAoC;EACpC,cAAc;EACd,mBAAmB;EACnB,aAAa;EACb,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".afterOpen {\n  background-color: white;\n  position: absolute;\n  border: 1px solid rgb(204, 204, 204);\n  overflow: auto;\n  border-radius: 24px;\n  outline: none;\n  min-width: 50%;\n  border-color: black;\n  max-height: 80vh;\n  overflow-x: hidden;\n  padding: 0px 20px;\n}\n.afterOpen::-webkit-scrollbar {\n  display: none;\n}\n\n.modalContent {\n  width: 100%;\n  text-align: center;\n}\n.modalContent > h3 {\n  margin: 20px auto;\n}\n\n.closeBtn {\n  position: absolute;\n  right: 12px;\n  top: 12px;\n}\n.table {\n  padding: 0px 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"afterOpen": `SegmentVacanciesModal_afterOpen__QXTp2`,
	"modalContent": `SegmentVacanciesModal_modalContent__gmCml`,
	"closeBtn": `SegmentVacanciesModal_closeBtn__CWV6A`,
	"table": `SegmentVacanciesModal_table__i9v-R`
};
export default ___CSS_LOADER_EXPORT___;
