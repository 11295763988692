import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useMutation, useQuery } from "react-query";

import { getCampaignAvailableJobBoards } from "../../services/budget";
import { addCampaignJobBoards } from "../../services/campaign";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../services/common";
import Spinner from "../Spinner/Spinner";

import styles from "./BudgetJobBoardsModal.module.css";

type CloneMonthsModalProps = {
  onClose?: any;
  onJobBoardAdded?: any;
  isOpen: boolean;
  clientId: number | string;
  campaignId: number;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxheight: "80vh",
  },
};

function BudgetJobBoardsModal(props: Readonly<CloneMonthsModalProps>) {
  const { t } = useTranslation();
  const { onClose, onJobBoardAdded, isOpen, clientId, campaignId } = props;

  const [selectedBoard, setSelectedBoard] = useState<any>({});
  const [currentPeriod, setCurrentPeriod] = useState<{
    month: number;
    year: number;
  }>({ month: 0, year: 0 });

  const { data, isLoading } = useQuery(
    ["availableCampaignJobBoards", currentPeriod, clientId, campaignId],
    () =>
      getCampaignAvailableJobBoards(
        currentPeriod?.year,
        currentPeriod?.month,
        clientId,
        campaignId,
      ),
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError(err) {
        displayErrorSnackbar("Error getting JobBoards");
      },
    },
  );

  const { mutate, isLoading: addingJobBoard } = useMutation(
    (data: any) => addCampaignJobBoards(data),
    {
      onSuccess() {
        displaySuccessSnackbar("JobBoard added successfully");
        onJobBoardAdded();
      },
    },
  );

  useEffect(() => {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    setCurrentPeriod({ month, year });
  }, []);

  const handleAddJobBoard = () => {
    const date = new Date().getDate();
    if (selectedBoard?.id) {
      const data = {
        jobboardId: selectedBoard?.jobboardId,
        campaignID: campaignId,
        jobboardBudgetAmount: {
          budgetPlanningJobboardId: selectedBoard?.id,
          amount: 0,
          plannedAmountPerDay: 0,
          year: currentPeriod?.year,
          month: currentPeriod?.month,
          date,
        },
      };
      mutate(data);
    }
  };

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      className={styles.afterOpen}
      ariaHideApp={false}
    >
      <section className={styles.modalContent}>
        <button className={styles.closeBtn} onClick={() => onClose()}>
          <svg
            width={"20px"}
            height={"20px"}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z" />
            <path d="M16.707,7.293a1,1,0,0,0-1.414,0L12,10.586,8.707,7.293A1,1,0,1,0,7.293,8.707L10.586,12,7.293,15.293a1,1,0,1,0,1.414,1.414L12,13.414l3.293,3.293a1,1,0,0,0,1.414-1.414L13.414,12l3.293-3.293A1,1,0,0,0,16.707,7.293Z" />
          </svg>
        </button>
        <h3>{t("Add new budget jobboard")}</h3>
        {isLoading ? (
          <div className={styles.spinnerWrap}>
            <Spinner size={"50px"} />
          </div>
        ) : (
          data?.data && (
            <div className={`${styles.contentContainer}`}>
              {data?.data?.length ? (
                data?.data?.map((data: any, index: number) => {
                  return (
                    <button
                      key={`${"" + index}`}
                      onClick={() => setSelectedBoard(data)}
                      className={`${styles.row} ${
                        selectedBoard?.id === data?.id ? styles.selected : ""
                      }`}
                    >
                      <span>{data?.jobboardName}</span>
                    </button>
                  );
                })
              ) : (
                <>No jobBoards available</>
              )}
            </div>
          )
        )}

        <div className={styles.footer}>
          {selectedBoard?.id && (
            <button
              disabled={!selectedBoard?.id}
              className={`button primaryButton ${styles.addBtn}`}
              onClick={handleAddJobBoard}
            >
              {addingJobBoard ? (
                <Spinner />
              ) : (
                `Add ${selectedBoard?.jobboardName}`
              )}
            </button>
          )}
        </div>
      </section>
    </Modal>
  );
}

export default BudgetJobBoardsModal;
