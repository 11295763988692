import { createSlice } from "@reduxjs/toolkit";

import { FeedType } from "../types/client/ClientTypes";

interface FeedChangePayload {
  index: number;
  name: keyof FeedType;
  value: string | boolean;
}

const initialState: FeedType[] = [
  {
    feedFormat: "DefaultRecruitNow",
    feedName: "",
    urlType: "Main",
    feedUrl: "",
    status: true,
    id: "",
  },
];

export const feedsSlice = createSlice({
  name: "feeds",
  initialState: initialState,
  reducers: {
    setFeeds: (state, action) => {
      const oldDrafts = state.filter((item) => item.isEdited);
      const updatedData = [...action.payload, ...oldDrafts];
      return updatedData;
    },
    addNewFeed: (state, action) => {
      state.push(action.payload);
    },
    removeFeed: (state, action) => {
      state.splice(action.payload.index, 1);
    },
    handleFeedChange: (
      state: FeedType[],
      action: { payload: FeedChangePayload },
    ) => {
      const { index, name, value } = action.payload;
      if (index >= 0 && index < state.length) {
        state[index] = {
          isEdited: true,
          ...state[index],
          [name]: value as FeedType[keyof FeedType],
        };
      }
    },
  },
});

export const { setFeeds, addNewFeed, removeFeed, handleFeedChange } =
  feedsSlice.actions;

export default feedsSlice.reducer;
