import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import Select from "react-select";

import { Icons } from "../../assets/icons";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../services/common";
import {
  getCampaignVacancies,
  getClientVacancies,
  syncRecruitNowPublications,
  VACANCIES_COLUMN,
} from "../../services/vacancies";
import Breadcrumb from "../Breadcrumb";
import { Button } from "../Button/Button";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Pagination from "../Pagination/Pagination";
import Spinner from "../Spinner/Spinner";
import TableNew from "../Table/Table";

import styles from "./Vacancies.module.css";
import VacancyDetailsModal from "./VacancyDetailsModal/VacancyDetailsModal";

function Vacancies() {
  const { t } = useTranslation();
  const params = useParams();
  const queryClient = useQueryClient();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const campaignId = searchParams.get("campaignId");
  const { id } = useParams();
  if (id === undefined) throw Error("Invalid contact");
  const clientId = +id;
  const {
    mutate: syncRecruitNowPublicationsMutate,
    data: vacienciesData,
    isLoading: syncVacanciesLoader,
  } = useMutation(() => syncRecruitNowPublications(clientId), {
    onError: (error: any) => {
      displayErrorSnackbar(error.response?.data.errors);
    },
  });

  useEffect(() => {
    if (!syncVacanciesLoader && vacienciesData) {
      displaySuccessSnackbar("Success");
    }
  }, [vacienciesData]);

  const [vacanciesType, setVacanciesType] = useState<any>({
    value: "all",
    label: "All",
  });
  const [pageDetails, setPageDetails] = useState<any>({
    pageNumber: 1,
    pageSize: 25,
  });
  const [isOpen, setIsOpen] = useState<null | "recruitNow">();

  const [selectedVacancy, setSelectedVacancy] = useState<number | null>(null);

  const { data: VacanciesData, isLoading: loadingVacacnies } = useQuery(
    [
      "vacancies",
      params.id,
      campaignId,
      pageDetails?.pageNumber,
      pageDetails?.pageSize,
      vacanciesType?.value,
    ],
    () => {
      if (campaignId) {
        return getCampaignVacancies(
          campaignId,
          pageDetails?.pageNumber,
          pageDetails?.pageSize,
        );
      } else {
        return getClientVacancies(
          params.id,
          pageDetails?.pageNumber,
          pageDetails?.pageSize,
          vacanciesType?.value,
        );
      }
    },
  );

  useEffect(() => {
    if (VacanciesData?.data?.pageNumber < VacanciesData?.data?.totalPages) {
      if (campaignId) {
        queryClient.prefetchQuery(
          [
            "vacancies",
            params.id,
            campaignId,
            pageDetails?.pageNumber + 1,
            pageDetails?.pageSize,
          ],
          () =>
            getCampaignVacancies(
              campaignId,
              pageDetails?.pageNumber + 1,
              pageDetails?.pageSize,
            ),
        );
      } else {
        queryClient.prefetchQuery(
          [
            "vacancies",
            params.id,
            pageDetails?.pageNumber + 1,
            pageDetails?.pageSize,
            vacanciesType?.value,
          ],
          () =>
            getClientVacancies(
              params.id,
              pageDetails?.pageNumber + 1,
              pageDetails?.pageSize,
              vacanciesType?.value,
            ),
        );
      }
    }
  }, [pageDetails?.pageNumber]);

  return (
    <section className="w-full">
      <div className="px-16 pb-4 pt-6 w-full ">
        <Breadcrumb
          currentPage={{
            text: "Vacancies",
            url: `/client/${params.id}/vacancies`,
          }}
          navigation={[
            { text: "Client Dashboard", url: `/client/dashboard/${params.id}` },
          ]}
        />
        <div className={styles.vacanciesHeader}>
          <h3 className=" text-2xl font-bold text-black">{t("vacancies")}</h3>

          <div className=" flex gap-x-3">
            <Button
              onClick={() => setIsOpen("recruitNow")}
              disabled={syncVacanciesLoader}
              color="tertiary"
              prefixIcon={<Icons.CloudIcon />}
              loading={syncVacanciesLoader}
            >
              Sync RecruitNow Publications
            </Button>

            {!campaignId && (
              <div className={styles.vacancyTypeFilter}>
                <Select
                  styles={{
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "5px",
                      minWidth: "100px",
                      padding: "2.5px",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#CDCDCD",
                      primary: "#CDCDCD",
                    },
                  })}
                  value={vacanciesType}
                  options={[
                    { value: "all", label: "All" },
                    { value: "incampaign", label: "In Campaign" },
                    { value: "open", label: "Open" },
                  ]}
                  onChange={(value: any) => {
                    setVacanciesType(value);
                    setPageDetails({
                      pageNumber: 1,
                      pageSize: 25,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>

        {loadingVacacnies ? (
          <>
            <h3>Loading vacancies...</h3>
            <Spinner size={"50px"} />
          </>
        ) : (
          <TableNew
            columns={VACANCIES_COLUMN}
            data={VacanciesData?.data?.data}
            onRowClick={(item: any) => {
              setSelectedVacancy(item?.id);
            }}
            RowisClickable={true}
            order={true}
          />
        )}

        {VacanciesData?.data && (
          <Pagination
            pageNumber={VacanciesData?.data?.pageNumber}
            pageSize={VacanciesData?.data?.pageSize}
            totalPages={VacanciesData?.data?.totalPages}
            totalRecords={VacanciesData?.data?.totalRecords}
            onChange={(newDetails: any) => {
              setPageDetails(newDetails);
            }}
          />
        )}

        {selectedVacancy && (
          <VacancyDetailsModal
            selectedVacancy={selectedVacancy}
            isOpen={!!selectedVacancy}
            onClose={() => {
              setSelectedVacancy(null);
            }}
          />
        )}
      </div>
      <ConfirmationModal
        isOpen={isOpen === "recruitNow"}
        heading={"Confirm Sync RecruitNow Publications"}
        title="Are you sure you want to continue?"
        onClose={() => setIsOpen(null)}
        handleClick={() => {
          syncRecruitNowPublicationsMutate();
          setIsOpen(null);
        }}
        modalType="info"
      />
    </section>
  );
}

export default Vacancies;
