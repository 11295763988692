import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  isOpen: false,
  type: "success",
};

export const snackbarSlice: any = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (state: any, action: any) => {
      const { message, type } = action.payload;
      state.message = message;
      state.isOpen = true;
      state.type = type || "success";
    },
    closeSnackbar: (state: any) => {
      state.message = "";
      state.isOpen = false;
      state.type = "success";
    },
  },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
