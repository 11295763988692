// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MappingVacanciesModal_afterOpen__wHn9q {
  background-color: white;
  position: absolute;
  inset: 290px;
  border: 1px solid rgb(204, 204, 204);
  overflow: auto;
  border-radius: 24px;
  outline: none;
  width: 35%;
  border-color: black;
  max-height: 80vh;
  overflow-x: hidden;
}

.MappingVacanciesModal_afterOpen__wHn9q::-webkit-scrollbar {
  display: none;
}

.MappingVacanciesModal_modalContent__ZzTm5 {
  width: 100%;
  text-align: center;
}

.MappingVacanciesModal_modalContent__ZzTm5 > h3 {
  margin: 20px auto;
}

.MappingVacanciesModal_closeBtn__yjyXv {
  position: absolute;
  right: 12px;
  top: 12px;
}

.MappingVacanciesModal_contentContainer__pf9lJ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4px;
  padding: 0 0 20px;
}

.MappingVacanciesModal_row__Rub1r {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 8px;
  color: #505050;
  padding: 15px;
  min-width: 70%;
}

.MappingVacanciesModal_greyRow__Ovl2i {
  background-color: #f2f2f2;
}
`, "",{"version":3,"sources":["webpack://./src/components/MappingVacanciesModal/MappingVacanciesModal.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,oCAAoC;EACpC,cAAc;EACd,mBAAmB;EACnB,aAAa;EACb,UAAU;EACV,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,eAAe;EACf,cAAc;EACd,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".afterOpen {\n  background-color: white;\n  position: absolute;\n  inset: 290px;\n  border: 1px solid rgb(204, 204, 204);\n  overflow: auto;\n  border-radius: 24px;\n  outline: none;\n  width: 35%;\n  border-color: black;\n  max-height: 80vh;\n  overflow-x: hidden;\n}\n\n.afterOpen::-webkit-scrollbar {\n  display: none;\n}\n\n.modalContent {\n  width: 100%;\n  text-align: center;\n}\n\n.modalContent > h3 {\n  margin: 20px auto;\n}\n\n.closeBtn {\n  position: absolute;\n  right: 12px;\n  top: 12px;\n}\n\n.contentContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  row-gap: 4px;\n  padding: 0 0 20px;\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  column-gap: 8px;\n  color: #505050;\n  padding: 15px;\n  min-width: 70%;\n}\n\n.greyRow {\n  background-color: #f2f2f2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"afterOpen": `MappingVacanciesModal_afterOpen__wHn9q`,
	"modalContent": `MappingVacanciesModal_modalContent__ZzTm5`,
	"closeBtn": `MappingVacanciesModal_closeBtn__yjyXv`,
	"contentContainer": `MappingVacanciesModal_contentContainer__pf9lJ`,
	"row": `MappingVacanciesModal_row__Rub1r`,
	"greyRow": `MappingVacanciesModal_greyRow__Ovl2i`
};
export default ___CSS_LOADER_EXPORT___;
