import { Button } from "../FormFields/FormFields";

type Props = {
  isOpen: boolean;
  title: string;
  handleCancel: () => void;
  handleSubmit: () => void;
};

const Modal = (props: Props) => {
  const { isOpen, title, handleCancel, handleSubmit } = props;

  if (isOpen) {
    return (
      <>
        <div className="fixed inset-0 bg-gray-300 bg-opacity-30 backdrop-blur-sm z-20"></div>
        <dialog
          id="my_modal_1"
          className="modal z-30 fixed top-1/2 transform -translate-y-1/2 rounded-md md:w-2/3 lg:w-1/2"
          open
        >
          <div className="space-y-10 mx-5 my-8">
            <h2 className=" text-4xl font-medium text-center border-b pb-2">
              {title}
            </h2>
            <p className=" text-xl">Are you sure you want to continue?</p>
            <div className=" flex gap-x-4 justify-end">
              <Button
                type="button"
                className=" bg-red-600 text-white px-4 py-2 rounded"
                onSubmit={handleCancel}
                label="Cancel"
              />
              <Button
                type="button"
                className=" bg-blue-600 text-white px-3 py-2 rounded"
                onSubmit={handleSubmit}
                label="Continue"
              />
            </div>
          </div>
        </dialog>
      </>
    );
  }
  return null;
};

export default Modal;
