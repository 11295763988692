import { NavLink, Outlet, useParams } from "react-router-dom";

import Breadcrumb from "../Breadcrumb";

import styles from "./editClient.module.css";

const EditClient = () => {
  const params = useParams();
  const contactItems = [
    {
      label: "Customer Brand",
      link: `/edit-client/${params.id}`,
    },
    {
      label: "Contacts",
      link: `/edit-client/${params.id}/contacts`,
    },
    {
      label: "Feed",
      link: `/edit-client/${params.id}/feed`,
    },
  ];

  return (
    <section className="w-full">
      <div className="px-16 pb-4 pt-6 w-full">
        <Breadcrumb
          currentPage={{
            text: `Edit Client / ${params.id}`,
            url: `/edit-client/${params.id}`,
          }}
          navigation={[
            {
              text: "Client Dashboard",
              url: `/client/dashboard/${params.id}`,
            },
          ]}
        />
        <div className={styles.editClientHeader}>
          <h3 className=" text-2xl font-bold text-black">Edit client</h3>
        </div>
        <div
          className={` ${styles.editContainer}  flex items-start mx-auto  gap-16`}
        >
          <div className=" flex flex-col w-1/2">
            <ul
              className={` border border-[#009da729] rounded bg-white p-4 flex flex-col gap-7`}
            >
              {contactItems.map((item: { label: string; link: string }) => {
                return (
                  <NavLink
                    to={item.link}
                    key={item.label}
                    end={item.link === `/edit-client/${params.id}`}
                    className={({ isActive }) =>
                      "text-base font-medium px-3 py-2 rounded-md " +
                      (isActive
                        ? "bg-[#CD335B1F] bg-opacity-10 text-[#CD335B]"
                        : "text-[#171717]")
                    }
                  >
                    {item.label}
                  </NavLink>
                );
              })}
            </ul>
          </div>

          {<Outlet />}
        </div>
      </div>
    </section>
  );
};

export default EditClient;
